import React, {useEffect, useState} from "react"
import { connect } from 'react-redux';
import Styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircleLoader } from 'react-spinners';
import { Redirect } from 'react-router';
import MainLogo from '../../assets/Oak+Fort-logo-thin-black.svg';
import Controller from '../../controller';


const controller = new Controller();



const Container = Styled.div`
  width: 100%;
  min-height: 90vh;
  //border: 1px solid red;
  /*
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  */
  padding-left: 10%;
  padding-top: 10%;
  position: relative;


  #login-form
  {
    //border: 1px solid blue;
    height: 500px;
    width: 80vw;
    /*
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-item: center;
    */

    text-align: center;
    
  }

  #main-logo
  {
    margin-bottom: 45px;
  }

  #login-btn-container
  {
    //border: 1px solid green;
    text-align: center;
    margin-top: 30px;
  }

  #slide-images 
  {
    display: none;
  }

  @media screen and (min-width: 600px)
  {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding-left: 8.5%;


    #login-form
    {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    #login-btn-container
    {
      //border: 1px solid green;
      text-align: center;
      margin-top: 30px;
      height: 100px;
      padding-top: 3px;
      margin-left: 30px;
    }

    #main-logo
    {
      position: absolute;
      width: 400px;
      height: 300px;
      top: 150px;
      left: 150px;
    }

   
  }


  @media screen and (min-width: 1400px)
  {
    #slide-images 
    {
      display: initial;
      width: 500px;
      position: absolute;
      right: 100px;
      border-radius: 25px;
    }
    
  }

  @media screen and (min-width: 1600px)
  {
    #slide-images 
    {
      display: initial;
      width: 500px;
      position: absolute;
      right: 225px;
      border-radius: 25px;
    }
    
  }
`;


const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
   margin: theme.spacing(1),
   height: '55px',
   width: '120px',
   backgroundColor: "rgb(52, 58, 64, 1)"
 },
}));


let slideImages = [
  'https://blog.oakandfort.com/wp-content/uploads/2019/10/Ballet-BC-Feature-min.jpg',
  'https://blog.oakandfort.com/wp-content/uploads/2019/10/Home-Fall-19-Feature-min.jpg',
  'https://blog.oakandfort.com/wp-content/uploads/2019/09/Pre-Fall-Feature-min.jpg',
  'https://blog.oakandfort.com/wp-content/uploads/2019/06/Solstice-Feature-min.jpg'
]



const mapStateToProps = (state) => {
  return {
    isAuth: state.main.is_auth,
  };
};


const Authenticate = ({ isAuth }) => {




  let classes = useStyles();
  let display = <CircleLoader />;
  let [state, setState] = useState({
    index: 0
  })


  useEffect(() => {
    let interval = setInterval(() => {
      let index = Math.floor(Math.random() * (slideImages.length));
      setState({index})
     },5000);
     return () => clearInterval(interval);
 },[]);





  if(localStorage.getItem('auth'))
  {
    return <Redirect to='/ecommerce' />
  }
  else
  {
    display = <Container>
               
                <form id="login-form"  onSubmit={(event) => { event.preventDefault(); controller.onClick({event, btn:"login"})}}>
                    <img id="main-logo" src={MainLogo} />
                    <TextField
                      id="email-input"
                      label="Email"
                      className={classes.textField}
                      type="email"
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="password-input"
                      label="Password"
                      className={classes.textField}
                      type="password"
                      autoComplete="current-password"
                      margin="normal"
                      variant="outlined"
                    />
                  <div id="login-btn-container">
                    <Button variant="contained" color="primary" className={classes.button} type="submit">
                      Login
                    </Button>
                  </div>

                  <img id="slide-images" src={slideImages[state.index]} />
                </form>
              </Container>
  }


  return display
}

export default connect(mapStateToProps, null)(Authenticate)
