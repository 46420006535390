export const IS_AUTH = "IS_AUTH";
export const SET_ECOMM_CA_BESTSELLERS = "SET_CA_BESTSELLERS";
export const SET_ECOMM_USA_BESTSELLERS = "SET_USA_BESTSELLERS";
export const SET_RETAIL_CA_BESTSELLER = "SET_RETAIL_CA_BESTSELLER";
export const SET_RETAIL_USA_BESTSELLER = "SET_RETAIL_USA_BESTSELLER";
export const SET_ECOMM_CA_HOMEBEAUTY = "SET_ECOMM_CA_HOMEBEAUTY";
export const SET_ECOMM_USA_HOMEBEAUTY = "SET_ECOMM_USA_HOMEBEAUTY";
export const SET_RETAIL_CA_HOMEBEAUTY = "SET_RETAIL_CA_HOMEBEAUTY";
export const SET_RETAIL_USA_HOMEBEAUTY = "SET_RETAIL_USA_HOMEBEAUTY";
export const SET_ECOMM_CA_SALESREPORT = "SET_ECOMM_CA_SALESREPORT";
export const SET_ECOMM_USA_SALESREPORT = "SET_ECOMM_USA_SALESREPORT";
export const SET_RETAIL_CA_SALESREPORT = "SET_RETAIL_CA_SALESREPORT";
export const SET_RETAIL_USA_SALESREPORT = "SET_RETAIL_USA_SALESREPORT";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const CUSTOM_SEARCH = "CUSTOM_SEARCH";
export const CUSTOM_SEARCH_RESPONSE = "CUSTOM_SEARCH_RESPONSE";
export const CUSTOM_SEARCH_MESSAGE = "CUSTOM_SEARCH_MESSAGE";
export const CUSTOM_TABLE = "CUSTOM_TABLE";
export const USER_INBOX = "USER_INBOX";
export const SET_CAROUSEL_INDEX = "SET_CAROUSEL_INDEX";
export const EMAIL_SENT = "EMAIL_SENT";
export const LOGIN_PROMPT = "LOGIN_PROMPT";
export const OPEN_TIMER = "OPEN_TIMER";
export const SEARCH_TAGS = "SEARCH_TAGS";
export const TIMERS = "TIMERS";
export const CUST_BEST_SELLER_COUNT = "CUST_BEST_SELLER_COUNT";
export const CUST_HOME_BEAUTY_COUNT = "CUST_HOME_BEAUTY_COUNT";
export const SET_ROW_BG_COLORS = "SET_ROW_BG_COLORS";
export const SET_EMAIL_MESSAGE = "SET_EMAIL_MESSAGE";
export const SET_CUSTOM_BUILD_ECOMM = "SET_CUSTOM_BUILD_ECOMM";
export const SET_CUSTOM_BUILD_RETAIL = "SET_CUSTOM_BUILD_RETAIL";
export const SCHED_COLOR_MAP_RETAIL = "SCHED_COLOR_MAP_RETAIL";
export const SCHED_COLOR_MAP_ECOMM = "SCHED_COLOR_MAP_ECOMM";