//import { lzw } from 'json-url';

function EmailFormatter ({data, emailMessage, positions, carouselIndex, bgColors, currCountBestSeller, currCountHomeBeauty})
{
    console.log("data in formatter: ", data);
    console.log("positions in formatter: ", positions);
    console.log("carouselIndex: ", carouselIndex)

    let body = `
    <!DOCTYPE html> 
     <html>
      <head>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      </head>
      <body>
        <h3>${emailMessage}</h3>
        <div>
        ${
            data['best_sellers'] ?
            positions['best_sellers']
            .map(current => {
                
                let key = Object.keys(current)[0];

                let retVal = `
                <div>
                    <h2>${key}</h2>
                    <div style="width: 100%; display: flex; margin-bottom: 10%; padding-left: 1%;">
                    ${
                        data['best_sellers'][key].slice(0,5).map(item => {
                            return `<div style="margin-left: 1px; margin-right: 1px; width:30vw">
                                    <img src=${encodeURI(decodeURI(item['images'][carouselIndex[item['parent']] ? carouselIndex[item['parent']] : 0]))} style="width: 100%; height: auto;" />
                                    <h3 style="font-size: .8rem;">${item['parent']}</h3>
                                    <h3 style="font-size: .8rem;">Quantity Sold: ${item['qtySold']}</h3>
                                    <h3 style="font-size: .8rem;">Revenue: ${item['link'] === "ca" ? 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueCad']) : 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueUsd'])}</h3>
                                    </div>`
                        }).join("")
                    }
                    </div>

                    <div style="width: 100%; display: flex; margin-bottom: 10%; padding-left: 1%;">
                    ${
                        data['best_sellers'][key].slice(5,10).map(item => {
                            return `<div style="margin-left: 1px; margin-right: 1px; width:30vw">
                                    <img src=${encodeURI(decodeURI(item['images'][carouselIndex[item['parent']] ? carouselIndex[item['parent']] : 0]))} style="width: 100%; height: auto;" />
                                    <h3 style="font-size: .8rem;">${item['parent']}</h3>
                                    <h3 style="font-size: .8rem;">Quantity Sold: ${item['qtySold']}</h3>
                                    <h3 style="font-size: .8rem;">Revenue: ${item['link'] === "ca" ? 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueCad']) : 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueUsd'])}</h3>
                                    </div>`
                        }).join("")
                    }
                    </div>
                </div>
                `
                return retVal;
            })
            : ''
        }

        ${
            data['home_and_beauty'] ?
            positions['home_and_beauty']
            .map(current => {

               let key = Object.keys(current)[0];
               
               let retVal = `
                 <div>
                   <h2>${key}</h2>
                   <div style="width: 100%; display: flex; margin-bottom: 10%; padding-left: 1%;">
                   ${
                       data['home_and_beauty'][key].slice(0,5).map(item => {
                           return `<div style="margin-left: 1px; margin-right: 1px; width:30vw">
                                    <img src=${encodeURI(decodeURI(item['images'][0]))} style="width: 100%; height: auto;" />
                                    <h3 style="font-size: .8rem;">${item['parent']}</h3>
                                    <h3 style="font-size: .8rem;">Quantity Sold: ${item['qtySold']}</h3>
                                    <h3 style="font-size: .8rem;">Revenue: ${item['link'] === "ca" ? 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueCad']) : 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueUsd'])}</h3>
                                   </div>`
                       }).join("")
                   }
                   </div>
   
                   <div style="width: 100%; display: flex; margin-bottom: 10%; padding-left: 1%;">
                   ${
                       data['home_and_beauty'][key].slice(5,10).map(item => {
                           return `<div style="margin-left: 1px; margin-right: 1px; width:30vw">
                                    <img src=${encodeURI(decodeURI(item['images'][0]))} style="width: 100%; height: auto;" />
                                    <h3 style="font-size: .8rem;">${item['parent']}</h3>
                                    <h3 style="font-size: .8rem;">Quantity Sold: ${item['qtySold']}</h3>
                                    <h3 style="font-size: .8rem;">Revenue: ${item['link'] === "ca"  ? 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueCad']) : 
                                                                             new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['revenueUsd'])}</h3>
                                   </div>`
                       }).join("")
                   }
                   </div>
                 </div>
               `
               return retVal;
            })
            : ''
        }


        ${
            data['sales_reports'] ?
            positions['sales_reports']
            .map(current => {

               let key = Object.keys(current)[0];
               
               let retVal = `
                 <div>
                   <h2>${key}</h2>
                   <table style="border: 1px solid black; margin-bottom: 25px;">
                   <tr style="background-color: rgb(125, 132, 138); color: white">
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Store</td>

                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Goal CAD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Actual CAD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Diff $ CAD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Diff % CAD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">LY CAD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">LY Diff % CAD</td>

                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Goal USD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Actual USD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Diff $ USD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">Diff % USD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">LY USD</td>
                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; font-weight: bold;">LY Diff % USD</td>
                   </tr>
                   <tr>
                    <td/>
                   </tr>
                   <tr>
                    <td/>
                   </tr>
                   ${
                       data['sales_reports'][key].map(item => {
                           return `<tr style='background-color:${bgColors[item['tag']]}' >
                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; font-weight: bold;">${item['locationName']}</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center;">${
                                        (item['link'] === 'ca') ?
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['goal']) :
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['sec_goal'])
                                    }</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'ca') ?
                                        //"blue" :
                                        'black'
                                    };">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['cad'])}</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'ca') ?
                                        (item['differenceCad'].toString().includes("-")) ?
                                        "red" :
                                        "green" //:
                                        //'black'
                                    };">${ 
                                        item['differenceCad'].toString().includes("-") ? 
                                        "-"+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['differenceCad'].toString().replace("-", "")) : 
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['differenceCad'])
                                    }</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'ca') ?
                                        (item['percentCad'].toString().includes("-")) ?
                                        "red" :
                                        "green" //:
                                        //'black'
                                    };">${item['percentCad']}%</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'ca') ?
                                        //"blue" //:
                                        'black'
                                    };">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['lyCad'])}</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        (item['lyCad'].toString() !== '0') ?
                                        //(item['link'] === 'ca') ?
                                        (item['tyLyPercentDiffCad'].toString().includes("-")) ?
                                        "red" :
                                        "green" :
                                        //'black' :
                                        //(item['link'] === 'ca') ?
                                        //"green" :
                                        "black"
                                    };">${item['lyCad'] == 0 ? 100 : item['tyLyPercentDiffCad']}%</td>




                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center;">${
                                        (item['link'] === 'ca') ?
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['sec_goal']) :
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['goal']) 
                                    }</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'usa') ?
                                        //"blue" :
                                        'black'
                                    };">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['usd'])}</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'usa') ?
                                        (item['differenceUsd'].toString().includes("-")) ?
                                        "red" :
                                        "green" //:
                                        //'black'
                                    };">${
                                        
                                        item['differenceUsd'].toString().includes("-") ? 
                                        "-"+new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['differenceUsd'].toString().replace("-", "")) : 
                                        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['differenceUsd'])
                                        
                                    }</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'usa') ?
                                        (item['percentUsd'].toString().includes("-")) ?
                                        "red" :
                                        "green" //:
                                        //'black'
                                    };">${(item['cad'] !== item['usd']) ? `${item['percentUsd']}%` : ''}</td>

                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        //(item['link'] === 'usa') ?
                                        //"blue" :
                                        'black'
                                    };">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item['lyUsd'])}</td>
                                    
                                    <td style="border: 1px solid black; width: 120px; padding: 2.5px; text-align: center; color: ${
                                        (item['lyUsd'].toString() !== '0') ?
                                        //(item['link'] === 'usa') ?
                                        (item['tyLyPercentDiffUsd'].toString().includes("-")) ?
                                        "red" :
                                        "green" :
                                        //'black' :
                                        //(item['link'] === 'usa') ?
                                        //"green" :
                                        "black"
                                    };">${item['lyUsd'] == 0 ? '100%' : `${item['tyLyPercentDiffUsd']}%`}</td>
                                  </tr>`
                       }).join("")
                   }
                   </table>
                 </div>
               `
               return retVal;
            }).join("")
            : ''
        }
        
        </div>
      </body>
     </html>
    `





    let body2 = `
    <!DOCTYPE html> 
     <html>
      <head>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      </head>
      <body>
        <h3>${emailMessage}</h3>
        <div>
        ${
         data['best_sellers'] ?
         Object.keys(data['best_sellers'])
         .map(key => {
            
            let retVal = `
              <div>
                <h2>${key}</h2>
                ${
                    data['best_sellers'][key].slice(0,9).map(item =>
                        {
                            let i = `
                            <div style="width: 80%; margin-bottom: 10%; padding-left: 10%;">
                                <div style="display: flex;">
                                    ${
                                        item['images'].slice(0,3).map(url => 
                                            
                                            `
                                            <div style="margin-left: 1px; margin-right: 1px; width:30vw">
                                                <img src=${encodeURI(decodeURI(url))} style="width: 100%; height: auto;" />
                                            </div>
                                            `
                                            ).join("")
                                    }
                                </div>
                                <h3>${item['parent']}</h3>
                                <h3>Qunatity Sold: ${item['qtySold']}</h3>
                                <h3>Revenue: $${item['link'] ? item['revenueCad'] : item['revenueUsd']}</h3>
                            </div>
                            `;

                            return i;
                        }).join("")
                }
              </div>
            `
            return retVal;
         })
         : ''
        }
        
        </div>
      </body>
     </html>
    `

    
   
    return body
}


export default EmailFormatter;