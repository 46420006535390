import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const colCa = [
  { id: 'Quarter', label: 'Quarter', minWidth: 120 },
  { id: 'Cad', label: 'CAD', minWidth: 120 },
  { id: 'lyCad', label: 'CAD LY', minWidth: 120 },
  { id: 'lytyDiff', label: 'LY TY DIFF', minWidth: 120 },
];

const colUsa = [
  { id: 'Quarter', label: 'Quarter', minWidth: 120 },
  { id: 'Cad', label: 'CAD', minWidth: 120 },
  { id: 'Usd', label: 'USD', minWidth: 120 },
  { id: 'lyUsd', label: 'USD LY', minWidth: 120 },
  { id: 'lytyDiff', label: 'LY TY DIFF', minWidth: 120 },
];

function createData(...parameters) {
  
  let response;

  if(parameters.length > 4)
  {
    response = { Quarter: parameters[0], 
                 Cad: parameters[1],
                 Usd: parameters[2],
                 lyUsd: parameters[3],
                 lytyDiff: parameters[4]
               };
  }
  else
  {
    response = { Quarter: parameters[0],  
                 Cad: parameters[1],
                 lyCad: parameters[2],
                 lytyDiff: parameters[3]
               }
  }

         

  return response;
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    eight: 'auto',
    overflow: 'auto',
  },
});

export default function StickyHeadTable({ quarterlyReport, source }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);

  let total = 0;
  let usa_total = 0;
  let ly_total = 0;



  let rows = [], columns; 
  
  switch (source)
  {
    case 'ca' :
      columns = colCa;
      //let total = 0, usa_total = 0, ly_total = 0;
  
      Object.keys(quarterlyReport['ty'])
            .map(k => {
              if (quarterlyReport['ty'][k] !== 0)
              {
                total += parseInt(quarterlyReport['ty'][k]['cad']);
                usa_total += parseInt(quarterlyReport['ty'][k]['usd']);
                ly_total += parseInt(quarterlyReport['ly'][k]['cad']);
                rows.push(createData(quarterlyReport['ty'][k]['store_name'], 
                                     `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quarterlyReport['ty'][k]['cad'])}`,
                                     `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quarterlyReport['ly'][k]['cad'])}`,
                                     `${Math.round(((parseInt(quarterlyReport['ty'][k]['cad']) - parseInt(quarterlyReport['ly'][k]['cad']))/parseInt(quarterlyReport['ly'][k]['cad'])) * 100)}%`
                                     ))
              }
              else
              {
                rows.push(createData(quarterlyReport['ty'][k]['store_name'], 
                                     `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}`,
                                     `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}`,
                                     `-`
                                     ))
              }
            })

 

      rows.push(createData('','','','','','','',''));

      
      rows.push(createData('YTD',
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}`, 
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ly_total)}`, 
                           ));
      
     
      
    break;

    case 'usa':
      columns = colUsa;

      

      Object.keys(quarterlyReport['ty'])
            .map(k => {
              if (quarterlyReport['ty'][k] !== 0)
              {
                total += parseInt(quarterlyReport['ty'][k]['cad'])
                usa_total += parseInt(quarterlyReport['ty'][k]['usd'])
                ly_total += parseInt(quarterlyReport['ly'][k]['usd'])

                rows.push(createData(quarterlyReport['ty'][k]['store_name'], 
                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quarterlyReport['ty'][k]['cad'])}`,
                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quarterlyReport['ty'][k]['usd'])}`,
                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(quarterlyReport['ly'][k]['usd'])}`,
                          `${Math.round(((parseInt(quarterlyReport['ty'][k]['usd']) - parseInt(quarterlyReport['ly'][k]['usd']))/parseInt(quarterlyReport['ly'][k]['usd'])) * 100)}%`
                          ))
              }
              else
              {
                rows.push(createData(quarterlyReport['ty'][k]['store_name'], 
                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}`,
                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}`,
                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}`,
                          `-`
                          ))
              }
            })



      rows.push(createData('','','','','','','',''));

      rows.push(createData('YTD',
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}`, 
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usa_total)}`,
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ly_total)}`,
                           '',
                           ''
                           ));

      
      
  }
  
    
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}