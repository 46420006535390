import React, {useEffect, useState} from "react"
import { connect } from 'react-redux';
import Styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { CircleLoader } from 'react-spinners';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { InputGroup, 
         InputGroupAddon, 
         InputGroupText, 
         Input, 
         Dropdown, 
         DropdownToggle, 
         DropdownMenu, 
         DropdownItem,
         Tooltip } from 'reactstrap';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Controller from '../../controller';



const controller = new Controller();



const Container = Styled.div`
        width: 300px;
        height: 120vh;
        position: fixed;
        display: flex;
        top: 55px;
        left: ${props => props.openTimer ? '0' : '-400px'};
        z-index: 900;
        padding: 15px 1.5px 1.5px 12px;
        background-color: rgb(251, 250, 248);
        box-shadow: rgb(61, 51, 161) 0px 25px 50px -12px;
        transition: all .5s;



        #timer-controls-cont
        {
            position: absolute;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            width: 95%;
            top: 80px;
            height: 700px
            overflow: auto;
        }
`;


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    padding: theme.spacing(3),
  },
}));






const mapStateToProps = (state) => {
  return {
    openTimer: state.main.open_timer,
    searchTags: state.main.search_tags,
    timers: state.main.timers
  };
};


const Timer = ({ openTimer, 
                 searchTags,
                 timers }) => {
  
    let classes = useStyles();
    let [state, setState] = useState({ timerList: [],
                                       recipientList: {},
                                       setDsh: {},
                                       dropdownOpen: false, 
                                       timerAnchorEl: null,
                                       tagsAnchorEl: null,
                                       recipientsAnchorEl: null,
                                       setDshAnchorEl: null,
                                       weekday: '',
                                       month: '', 
                                       saved: true}); 



    let handleTimerOpen = {},
    handleTimerClose = {},
    handleTagsOpen = {},
    handleTagsClose = {},
    handleRecipientsOpen = {},
    handleRecipientsClose = {},
    handleOverrideOpen = {},
    handleOverrideClose = {},
    handleSetDshOpen = {},
    handleSetDshClose = {},
    

    timerProp = {},
    tagsProps = {},
    recipientsProps = {},
    setDshProps = {},
    overrideDates = {},
    tags = {};


    useEffect(() => {

        let timerList = [];
        let valuesForSubmit = {};

        for(let t in timers)
        {
          timerList.push(t);

          
          valuesForSubmit[t] = {};
          valuesForSubmit[t]['weekday'] = timers[t]['weekday']; 
          valuesForSubmit[t]['month'] = timers[t]['month']; 
          valuesForSubmit[t]['date'] = timers[t]['date']; 
          valuesForSubmit[t]['hour'] = timers[t]['hour']; 
          valuesForSubmit[t]['minutes'] = timers[t]['minutes']; 
          valuesForSubmit[t]['recipients'] = timers[t]['recipients']; 
          valuesForSubmit[t]['override'] = timers[t]['override'];
          valuesForSubmit[t]['includes'] = timers[t]['includes'];
          valuesForSubmit[t]['emailSubject'] = timers[t]['emailSubject'];
          valuesForSubmit[t]['emailMessage'] = timers[t]['emailMessage']; 
          valuesForSubmit[t]['setDsh'] = timers[t]['setDsh'];
          valuesForSubmit[t]['enabled'] = timers[t]['enabled'];

        }

        
        setState({...state, timerList, valuesForSubmit });
      

    },[timers]);



    state.timerList.map((t, i) => 
    {


      handleTimerOpen[`${t}${i}`] = event => setState({...state, [`timerAnchorEl${i}`]: event.currentTarget})
      handleTimerClose[`${t}${i}`] = event => setState({...state, [`timerAnchorEl${i}`]: null});
      handleTagsOpen[`${t}${i}`] = event => setState({...state, [`tagsAnchorEl${i}`]: event.currentTarget})
      handleTagsClose[`${t}${i}`] = event => setState({...state, [`tagsAnchorEl${i}`]: null})
      handleRecipientsOpen[`${t}${i}`] = event => setState({...state, [`recipientsAnchorEl${i}`]: event.currentTarget})
      handleRecipientsClose[`${t}${i}`] = event => setState({...state, [`recipientsAnchorEl${i}`]: null})
      handleOverrideOpen[`${t}${i}`] = event => setState({...state, [`overrideDatesAnchorEl${i}`]: event.currentTarget})
      handleOverrideClose[`${t}${i}`] = event => setState({...state, [`overrideDatesAnchorEl${i}`]: null})
      handleSetDshOpen[`${t}${i}`] = event => setState({...state, [`setDshAnchorEl${i}`]: event.currentTarget})
      handleSetDshClose[`${t}${i}`] = event => setState({...state, [`setDshAnchorEl${i}`]: null})

      timerProp[t] = {};
      timerProp[t]['open'] = state[`timerAnchorEl${i}`] ? state[`timerAnchorEl${i}`] : false;
      timerProp[t]['id'] = timerProp[t]['open'] ? 'simple-popover' : undefined;

      tagsProps[t] = {};
      tagsProps[t]['open'] = state[`tagsAnchorEl${i}`] ? state[`tagsAnchorEl${i}`] : false;
      tagsProps[t]['id'] = tagsProps[t]['open'] ? 'simple-popover' : undefined;

      recipientsProps[t] = {};
      recipientsProps[t]['open'] = state[`recipientsAnchorEl${i}`] ? state[`recipientsAnchorEl${i}`] : false;
      recipientsProps[t]['id'] = recipientsProps[t]['open'] ? 'simple-popover' : undefined;

      overrideDates[t] = {};
      overrideDates[t]['open'] = state[`overrideDatesAnchorEl${i}`] ? state[`overrideDatesAnchorEl${i}`] : false;
      overrideDates[t]['id'] = overrideDates[t]['open'] ? 'simple-popover' : undefined;

      setDshProps[t] = {};
      setDshProps[t]['open'] = state[`setDshAnchorEl${i}`] ? state[`setDshAnchorEl${i}`] : false;
      setDshProps[t]['id'] = setDshProps[t]['open'] ? 'simple-popover' : undefined;

      tags[t] = state[t] ? state[t] : false;


    })
                               






    function addTimer ()
    {
      let timerName = document.querySelector("#add-timer-input").value;

      if(state.timerList.filter(d => d === timerName).length > 0)
      {
        return alert("Timer Label Exists!")
      }

      if(!timerName)
      {
        return alert("Add a label first!")
      }

      setState({
        ...state,
        //timerList: [...state.timerList, timerName],
        [`timerAnchorEl${state.timerList.length}`]: null
      })
      controller.onClick({btn: 'addTimer', parameters: { timerName }})
      document.querySelector("#add-timer-input").value = '';
    }



    function addRecipient ( timerName )
    {
      let email = document.querySelector("#add-recipient-input").value;


      if(Array.isArray(state['valuesForSubmit'][timerName]['recipients']) && state['valuesForSubmit'][timerName]['recipients'].filter(d => d === email).length > 0)
      {
        return alert("Recipient Exists!")
      }

      if(!email)
      {
        return alert("Add a label first!")
      }


      let retrieve = state['valuesForSubmit'];


      if(!Array.isArray(retrieve[timerName]['recipients']))
      {
        retrieve[timerName]['recipients'] = [];
      }

      retrieve[timerName]['recipients'].push(email);


      setState({...state, valuesForSubmit: retrieve, saved: false});


      //controller.onClick({btn: 'addTimer', parameters: { timerName }})
      document.querySelector("#add-recipient-input").value = '';

    }


    function removeRecipient (timerName, email)
    {

      let retrieve = state['valuesForSubmit'];
      retrieve[timerName]['recipients'] = retrieve[timerName]['recipients'].filter(e => e !== email);
      setState({...state, valuesForSubmit: retrieve, saved: false});

    }
    


    const handleChange = event => 
    {
      let n = event.target.name.split("-");
      let name = n[0];
      let field = n[1]
      let val = event.target.value;
      let retrieve = state['valuesForSubmit'];

      retrieve[name][field] = val;

      setState({...state, valuesForSubmit: retrieve, saved: false});
    }


    const handleOverride = event => 
    {
      let n = event.target.name.split("-");
      let name = n[0];
      let field = n[1]
      let category = n[2];
      let val = event.target.value;
      let retrieve = state['valuesForSubmit'];

      console.log("handleOverride value: ", val);


      /*
      if( val !== 'empty' || 
          val !== '1-day-before' || 
          val !== '5-days-before' || 
          val !== '1-week-before' || 
          val !== '2-weeks-before' || 
          val !== '1-month-before' )
          {
            val = moment(val).format('MM/DD/YYYY')
          }

          */

      retrieve[name][field][category] = val;

      setState({...state, valuesForSubmit: retrieve, saved: false});
    }


    const handleEnable = event => 
    {
      let name = event.target.name;
      let val = event.target.value;
      let retrieve = state['valuesForSubmit'];
      
      retrieve[name]['enabled'] = !Boolean(retrieve[name]['enabled']);

      setState({...state, valuesForSubmit: retrieve, saved: false})
    }


    const handleCheckbox = name => event => {
      let val = event.target.value;
      let checked = event.target.checked;



      let retrieve = state['valuesForSubmit'];


      if(retrieve[name]['includes'] === 'empty')
      {
        retrieve[name]['includes'] = [];
      }


      if(checked)
      {
        retrieve[name]['includes'].push(val)
      }
      else
      {
        retrieve[name]['includes'] = retrieve[name]['includes'].filter(t => t !== val)
      }
      
      setState({...state, valuesForSubmit: retrieve, saved: false})
    };


    const handleCheckboxDsh = name => event => {
      let val = event.target.value;
      let checked = event.target.checked;



      let retrieve = state['valuesForSubmit'];

      console.log("name: ", name)
      console.log("val and checked: ", val + " -- " + checked)

      retrieve[name]['setDsh'][val]['active'] = !retrieve[name]['setDsh'][val]['active']
      setState({...state, valuesForSubmit: retrieve, saved: false})
      
    };

  
    
    //console.log("valuesForSubmit: ", state['valuesForSubmit']);


    let display;

    display = <Container openTimer={openTimer}>

                <ChevronLeftIcon onClick={() => controller.onClick({btn: "setTimerPage"})} style={{width: '50px', height: '50px', position: 'absolute', right: '10', cursor: 'pointer'}} />
                <div id="timer-controls-cont">
                      <form id="add-timer" onSubmit={(e) => { e.preventDefault(); addTimer();}}>
                            <TextField
                                id="add-timer-input"
                                className={classes.textField}
                                label="Schedule Label"
                                margin="normal"
                                style={{width: '75%'}}
                            />
                            <AddIcon onClick={addTimer}  style={{alignSelf: 'flex-end', width: '35px', height: '35px', marginTop: '30px', cursor: 'pointer'}} />
                      </form>
                      <div style={{width: '100%', height: 'auto', padding: '15px 5px 25px 5px', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'space-around'}}>
                      
                        {
                          state.timerList.length > 0 ?
                          state.timerList.map((t, i) => {

                            return  <div style={{margin: '10px'}}>
                                      <Button style={{width: '240px', backgroundColor: 'rgb(81, 97, 122)'}} aria-describedby={timerProp[t]['id']} variant="contained" color="primary" onClick={handleTimerOpen[`${t}${i}`]}>
                                        { t }
                                      </Button>
                                      <Popover
                                        classes={{
                                          paper: classes.paper,
                                        }}
                                        id={timerProp[t]['id']}
                                        open={timerProp[t]['open']}
                                        anchorEl={state[`timerAnchorEl${i}`]}
                                        onClose={handleTimerClose[`${t}${i}`]}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }}
                                      >
                                        <Typography>{ t.toUpperCase() }</Typography>
                                        <FormHelperText style={{color: 'red'}}>{ !state['saved'] ? 'changes not saved' : ''}</FormHelperText>

                                        <Select
                                          style={{width: '200px', marginTop: '12px'}}
                                          labelId="demo-simple-select-required-label"
                                          id="timer-weekday"
                                          name={`${t}-weekday`}
                                          value={state['valuesForSubmit'][t]['weekday']}
                                          onChange={handleChange}
                                          className={classes.selectEmpty}
                                        >
                                          <MenuItem value={'*'}>&nbsp;</MenuItem>
                                          <MenuItem value={1}>Monday</MenuItem>
                                          <MenuItem value={2}>Tuesday</MenuItem>
                                          <MenuItem value={3}>Wednesday</MenuItem>
                                          <MenuItem value={4}>Thursday</MenuItem>
                                          <MenuItem value={5}>Friday</MenuItem>
                                          <MenuItem value={6}>Saturday</MenuItem>
                                          <MenuItem value={0}>Sunday</MenuItem>
                                        </Select>
                                        <FormHelperText>Weekday</FormHelperText>

                                        
                                        <Select
                                          style={{width: '200px'}}
                                          labelId="demo-simple-select-required-label"
                                          id="timer-month"
                                          name={`${t}-month`}
                                          value={state['valuesForSubmit'][t]['month']}
                                          onChange={handleChange}
                                          className={classes.selectEmpty}
                                        >
                                          <MenuItem value={'*'}>&nbsp;</MenuItem>
                                          <MenuItem value={1}>January</MenuItem>
                                          <MenuItem value={2}>February</MenuItem>
                                          <MenuItem value={3}>March</MenuItem>
                                          <MenuItem value={4}>April</MenuItem>
                                          <MenuItem value={5}>May</MenuItem>
                                          <MenuItem value={6}>June</MenuItem>
                                          <MenuItem value={7}>July</MenuItem>
                                          <MenuItem value={8}>August</MenuItem>
                                          <MenuItem value={9}>September</MenuItem>
                                          <MenuItem value={10}>October</MenuItem>
                                          <MenuItem value={11}>November</MenuItem>
                                          <MenuItem value={12}>December</MenuItem>
                                        </Select>
                                        <FormHelperText>Month</FormHelperText>
                                        <TextField name={`${t}-date`} value={state['valuesForSubmit'][t]['date'] === '*' ? '' : state['valuesForSubmit'][t]['date']} onChange={handleChange} id="standard-basic" label="1-31" />
                                        <FormHelperText>Date</FormHelperText>
                                        <TextField name={`${t}-hour`} value={state['valuesForSubmit'][t]['hour'] === '*' ? '' : state['valuesForSubmit'][t]['hour']} onChange={handleChange} id="standard-basic" label="24-hour format" placeholder="hh" />
                                        <FormHelperText>Hour</FormHelperText>
                                        <TextField name={`${t}-minutes`} value={state['valuesForSubmit'][t]['minutes'] === '*' ? '' : state['valuesForSubmit'][t]['minutes']} onChange={handleChange} id="standard-basic" label="0-59" placeholder="mm" />
                                        <FormHelperText>Minutes</FormHelperText>

                                        
                                        
                                        <div style={{display: 'flex', flexFlow: 'column nowrap', width: '100%', alignItems: 'center', marginBottom: '15px'}}>
                                          <Button style={{width: '180px', marginTop: '15px', backgroundColor: 'rgb(81, 97, 122)'}} aria-describedby={tagsProps[t]['id']} variant="contained" color="primary" onClick={handleTagsOpen[`${t}${i}`]}>
                                            Include Tags
                                          </Button>
                                          <Popover
                                            classes={{
                                              paper: classes.paper,
                                            }}
                                            id={tagsProps[t]['id']}
                                            open={tagsProps[t]['open']}
                                            anchorEl={state[`tagsAnchorEl${i}`]}
                                            onClose={handleTagsClose[`${t}${i}`]}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                          >
                                            <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '245px', height: '260px', overflowY: 'auto'}}>

                                              {
                                                searchTags && searchTags.length > 0 ?
                                                searchTags.map( s => <FormControlLabel
                                                                        control={
                                                                          <Checkbox
                                                                            checked={state['valuesForSubmit'][t]['includes'] ? Boolean(state['valuesForSubmit'][t]['includes'].indexOf(s) >= 0) : false}
                                                                            onChange={handleCheckbox(t)}
                                                                            value={s}
                                                                            color="primary"
                                                                          />
                                                                        }
                                                                        label={s}
                                                                      /> )
                                                : ''
                                              }

                                            </div>
                                          </Popover>

                                          <Button style={{width: '180px', marginTop: '15px', backgroundColor: 'rgb(81, 97, 122)'}} aria-describedby={recipientsProps[t]['id']} variant="contained" color="primary" onClick={handleRecipientsOpen[`${t}${i}`]}>
                                            Recipients
                                          </Button>
                                          <Popover
                                            classes={{
                                              paper: classes.paper,
                                            }}
                                            id={recipientsProps[t]['id']}
                                            open={recipientsProps[t]['open']}
                                            anchorEl={state[`recipientsAnchorEl${i}`]}
                                            onClose={handleRecipientsClose[`${t}${i}`]}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                          >
                                            <div style={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-between', width: '245px', height: '260px', overflowY: 'auto'}}>


                                              <form id="add-timer" onSubmit={(e) => { e.preventDefault(); addRecipient(t);}}>
                                                    <TextField
                                                        id="add-recipient-input"
                                                        className={classes.textField}
                                                        label="Recipient Email"
                                                        margin="normal"
                                                        style={{width: '75%'}}
                                                    />
                                                    <AddIcon onClick={() => addRecipient(t)}  style={{alignSelf: 'flex-end', width: '35px', height: '35px', marginTop: '30px', cursor: 'pointer'}} />
                                              </form>
                                              <div style={{width: '100%', padding: '3.5px', height: '240px', display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start', justifyContent: 'flex-start',  overflowY: 'auto'}}>
                                              
                                                {
                                                  state['valuesForSubmit'][t]['recipients'] ?
                                                  Array.isArray(state['valuesForSubmit'][t]['recipients']) && state['valuesForSubmit'][t]['recipients'].map(e => <div style={{display: 'flex', alignItems: 'center'}}>
                                                                                                                                                                    <CloseIcon style={{cursor: 'pointer'}} onClick={() => removeRecipient(t, e)}/>
                                                                                                                                                                    <p style={{margin: '5px'}}>{e}</p>
                                                                                                                                                                 </div>)
                                                  : ''
                                                }

                                              </div>

                                            </div>
                                          </Popover>
                                          <Button style={{width: '180px', marginTop: '15px', backgroundColor: 'rgb(81, 97, 122)'}} id={t} aria-describedby={overrideDates[t]['open']} variant="contained" color="primary" onClick={handleOverrideOpen[`${t}${i}`]}>
                                            Override Dates
                                          </Button>
                                          <Popover
                                            classes={{
                                              paper: classes.paper,
                                            }}
                                            id={overrideDates[t]['id']}
                                            open={overrideDates[t]['open']}
                                            anchorEl={state[`overrideDatesAnchorEl${i}`]}
                                            onClose={handleOverrideClose[`${t}${i}`]}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                          >
                                            <div style={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-between', width: '245px', height: '260px', overflowY: 'auto', paddingRight: '5px'}}>
                                                <h6><strong>Note:</strong> This overrides the dates in a search and applies it to all tags included in this timer group.</h6>
                                                
                                                <input name={`${t}-override-from`}
                                                       value={state['valuesForSubmit'][t]['override']['from']}
                                                       onChange={handleOverride}
                                                       style={{marginTop: '15px'}} type="date"/>
                                                <FormHelperText>From</FormHelperText>
                                                <input name={`${t}-override-to`}
                                                       value={state['valuesForSubmit'][t]['override']['to']}
                                                       onChange={handleOverride}
                                                       style={{marginTop: '15px'}} type="date"/>
                                                <FormHelperText>To</FormHelperText>
                                                <input name={`${t}-override-lyFrom`}
                                                       value={state['valuesForSubmit'][t]['override']['lyFrom']}
                                                       onChange={handleOverride}
                                                       style={{marginTop: '15px'}} type="date"/>
                                                <FormHelperText>LY From</FormHelperText>
                                                <input name={`${t}-override-lyTo`}
                                                       value={state['valuesForSubmit'][t]['override']['lyTo']}
                                                       onChange={handleOverride} 
                                                       style={{marginTop: '15px'}} type="date"/>
                                                <FormHelperText>LY To</FormHelperText>

                                                <h6 style={{marginTop: '15px'}}><strong>Note:</strong> Fields below supercede fields above. Set fields below to blank to use fields above.</h6>
                                                
                                                <Select
                                                  style={{width: '200px', marginTop: '15px'}}
                                                  labelId="Fixed Range"
                                                  id="override-fixed-range"
                                                  name={`${t}-override-fixedRangeFrom`}
                                                  value={state['valuesForSubmit'][t]['override']['fixedRangeFrom']}
                                                  onChange={handleOverride}
                                                  className={classes.selectEmpty}
                                                >
                                                  <MenuItem value={'empty'}>&nbsp;</MenuItem>
                                                  <MenuItem value={`today`}>Today</MenuItem>
                                                  <MenuItem value={`1-day-before`}>1 day ago</MenuItem>
                                                  <MenuItem value={`3-day-before`}>3 days ago</MenuItem>
                                                  <MenuItem value={`5-days-before`}>5 days ago</MenuItem>
                                                  <MenuItem value={`1-week-before`}>1 week ago</MenuItem>
                                                  <MenuItem value={`2-weeks-before`}>2 week ago</MenuItem>
                                                  <MenuItem value={`1-month-before`}>1 month ago</MenuItem>
                                                </Select>
                                                <FormHelperText>Fixed Range From</FormHelperText>

                                                <Select
                                                  style={{width: '200px', marginTop: '15px'}}
                                                  labelId="Fixed Range"
                                                  id="override-fixed-range"
                                                  name={`${t}-override-fixedRangeTo`}
                                                  value={state['valuesForSubmit'][t]['override']['fixedRangeTo']}
                                                  onChange={handleOverride}
                                                  className={classes.selectEmpty}
                                                >
                                                  <MenuItem value={'empty'}>&nbsp;</MenuItem>
                                                  <MenuItem value={`today`}>Today</MenuItem>
                                                  <MenuItem value={`1-day-before`}>1 day ago</MenuItem>
                                                  <MenuItem value={`3-day-before`}>3 days ago</MenuItem>
                                                  <MenuItem value={`5-days-before`}>5 days ago</MenuItem>
                                                  <MenuItem value={`1-week-before`}>1 week ago</MenuItem>
                                                  <MenuItem value={`2-weeks-before`}>2 week ago</MenuItem>
                                                  <MenuItem value={`1-month-before`}>1 month ago</MenuItem>
                                                </Select>
                                                <FormHelperText>Fixed Range To</FormHelperText>

                                                <Select
                                                  style={{width: '200px', marginTop: '15px'}}
                                                  labelId="Ly Fixed Range"
                                                  id="override-fixed-range"
                                                  name={`${t}-override-lyFixedRangeFrom`}
                                                  value={state['valuesForSubmit'][t]['override']['lyFixedRangeFrom']}
                                                  onChange={handleOverride}
                                                  className={classes.selectEmpty}
                                                >
                                                  <MenuItem value={'empty'}>&nbsp;</MenuItem>
                                                  <MenuItem value={`today`}>Today</MenuItem>
                                                  <MenuItem value={`1-day-before`}>1 day ago</MenuItem>
                                                  <MenuItem value={`3-day-before`}>3 days ago</MenuItem>
                                                  <MenuItem value={`5-days-before`}>5 days ago</MenuItem>
                                                  <MenuItem value={`1-week-before`}>1 week ago</MenuItem>
                                                  <MenuItem value={`2-weeks-before`}>2 week ago</MenuItem>
                                                  <MenuItem value={`1-month-before`}>1 month ago</MenuItem>
                                                </Select>
                                                <FormHelperText>LY Fixed Range From</FormHelperText>

                                                <Select
                                                  style={{width: '200px', marginTop: '15px'}}
                                                  labelId="Ly Fixed Range"
                                                  id="override-fixed-range"
                                                  name={`${t}-override-lyFixedRangeTo`}
                                                  value={state['valuesForSubmit'][t]['override']['lyFixedRangeTo']}
                                                  onChange={handleOverride}
                                                  className={classes.selectEmpty}
                                                >
                                                  <MenuItem value={'empty'}>&nbsp;</MenuItem>
                                                  <MenuItem value={`today`}>Today</MenuItem>
                                                  <MenuItem value={`1-day-before`}>1 day ago</MenuItem>
                                                  <MenuItem value={`3-day-before`}>3 days ago</MenuItem>
                                                  <MenuItem value={`5-days-before`}>5 days ago</MenuItem>
                                                  <MenuItem value={`1-week-before`}>1 week ago</MenuItem>
                                                  <MenuItem value={`2-weeks-before`}>2 week ago</MenuItem>
                                                  <MenuItem value={`1-month-before`}>1 month ago</MenuItem>
                                                </Select>
                                                <FormHelperText>LY Fixed Range To</FormHelperText>


                                            </div>
                                          </Popover>

                                          <Button style={{width: '180px', marginTop: '15px', backgroundColor: 'rgb(81, 97, 122)'}} aria-describedby={setDshProps[t]['id']} variant="contained" color="primary" onClick={handleSetDshOpen[`${t}${i}`]}>
                                            Set Dashboard
                                          </Button>
                                          <Popover
                                            classes={{
                                              paper: classes.paper,
                                            }}
                                            id={setDshProps[t]['id']}
                                            open={setDshProps[t]['open']}
                                            anchorEl={state[`setDshAnchorEl${i}`]}
                                            onClose={handleSetDshClose[`${t}${i}`]}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                          >
                                            <div style={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', width: '245px', height: '100px', overflowY: 'auto'}}>

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={Boolean(state['valuesForSubmit'][t]['setDsh']['ecomm']['active']) ? Boolean(state['valuesForSubmit'][t]['setDsh']['ecomm']['active']) : false}
                                                    onChange={handleCheckboxDsh(t)}
                                                    value={'ecomm'}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Ecomm"}
                                              /> 

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={Boolean(state['valuesForSubmit'][t]['setDsh']['retail']['active']) ? Boolean(state['valuesForSubmit'][t]['setDsh']['retail']['active']) : false}
                                                    onChange={handleCheckboxDsh(t)}
                                                    value={'retail'}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Retail"}
                                              /> 
                                              
                                            </div>
                                          </Popover>
                                        </div>


                                        <TextField style={{marginTop: '5px'}} name={`${t}-emailSubject`} value={state['valuesForSubmit'][t]['emailSubject'] === 'empty' ? '' : state['valuesForSubmit'][t]['emailSubject']} onChange={handleChange} id="standard-basic" />
                                        <FormHelperText>Email Subject</FormHelperText>

                                        <TextField style={{marginTop: '5px'}} name={`${t}-emailMessage`} value={state['valuesForSubmit'][t]['emailMessage'] === 'empty' ? '' : state['valuesForSubmit'][t]['emailMessage']} onChange={handleChange} id="standard-basic" />
                                        <FormHelperText>Email Message</FormHelperText>


                                        <FormControlLabel
                                          style={{marginTop: '18px'}}
                                          control={
                                            <Switch
                                              name={t}
                                              onChange={handleEnable}
                                              checked={state['valuesForSubmit'][t]['enabled'] ? Boolean(state['valuesForSubmit'][t]['enabled']) : false}
                                              color="primary"
                                            />
                                          }
                                          label="Enable Timer"
                                        />

                                        <div style={{width: '100%', textAlign: 'right', marginTop: '5px'}}>
                                          <Button onClick={() => { handleTimerClose[`${t}${i}`]();  controller.onClick({ btn: 'removeTimer', parameters: { timerName: t } }); }} color="secondary">Remove</Button>
                                          <Button onClick={() => { setState({...state, saved: true}); controller.onClick({ btn: 'saveTimer', parameters: { timerName: t, data: state['valuesForSubmit'] } }); }} color="primary">Save</Button>
                                        </div>

                                        

                                      </Popover>
                                    </div>
                          }) : ''
                        }
                      
                      </div>
                </div>

              </Container>


  return display
}

export default connect(mapStateToProps, null)(Timer)
