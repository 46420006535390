import React, { useEffect, useState, forwardRef } from "react";
import { connect } from "react-redux";
import Styled from "styled-components";
import { Redirect } from "react-router";
import { GridLoader } from "react-spinners";
import MaterialTable from "material-table";
import { setCustomSearch } from "../../redux/actions/index";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { Alert } from "reactstrap";
import uniqid from "uniqid";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ImageDisplay from "../parts/imageDisplay";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Controller from "../../controller";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../parts/customSearchTable";
import AddIcon from "@material-ui/icons/Add";
import TableBodyRow from "../parts/row";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const controller = new Controller();

const PageContainer = Styled.div`
    position: fixed;
    background-color: rgb(52,58,64,1);
    width: 100%;
    height: 100vh;
    padding-top: 120px;
    padding-bottom: 100px;
    z-index: 900;
    bottom: -150vh;
    transform: translateY(${(props) => (props.customSearch ? "-150vh" : "0")});
    transition transform .5s;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;


    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */

    ::-webkit-scrollbar {
      width: 0px;  
      background: transparent; 
    }
    


    #timer-btn
    {
      position: absolute; 
      left: 40px; 
      top: 180px;
    }


    #close-icon 
    {   
      width: 100%;
      color: white;
      display: flex;
      justify-content: flex-end;
      padding: 55px 30px 20px 10px;
    }

    #custom-search-table
    {
      
      width: 100%
      display: flex;
      justify-content: center;
    }

    #submit-btn
    {
      
      width: 80%;
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }

    #progress-container
    {
      width: 100%;
      position: absolute;
    }

    #build-instructions
    {
      width: 80%;
      margin-bottom: 10px;
      color: white;
    }

    #modal-display
    {
      width: 80%;
      height: 70vh;
      overflow-y: auto;
    }

    .modal-dialog
    {
      max-width: 80% !important;
    }



    @media screen and (min-width: 576px)
    {

      .modal-dialog
      {
        max-width: 80% !important;
      }
    }

    @media screen and (min-width: 600px)
    {
      padding-top: 60px;

      #submit-btn
      {
       
        width: 80%;
        display: flex;
        justify-content: flex-start;
        padding-top: 5px;
      }

      #close-icon 
      {   
        padding: 60px 70px 60px 10px;
      }

      #timer-btn
      {
        left: 10%; 
        top: 180px;
      }
    }

    .styles_modal__gNwvD
    {
      max-width: 100%;
    }
`;

const SingleContainer1 = Styled.div`
    height: 70vh;
    display: flex;
    width: 95%;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 65px;
    padding-top: 2%;
    padding-left: 12%;
    overflow-y: auto;
`;

const mapStateToProps = (state) => {
  return {
    isAuth: state.main.is_auth,
    customSearch: state.main.custom_search,
    customSearchResponse: state.main.custom_search_response,
    customSearchMessage: state.main.custom_search_message,
    customTableList: state.main.custom_table,
    emailSent: state.main.email_sent,
    bgColors: state.main.set_row_bg_colors,
    emailSentMessage: state.main.email_sent_message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCustomSearch: (param) => dispatch(setCustomSearch(param)),
  };
};

const StyledModal = Styled(Modal)`

  #add-email
  {
    display: flex;
    align-items: center;
    justify-content: center;
  }

`;

const StyledEmailList = Styled.div`

  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 25px 15px 15px 15px;
  align-items: center;

  .email-list-grouping
  {
    display: flex;
    width: 65%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    h6
    {
      margin: 0
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const CustomSearch = ({
  customSearch,
  setCustomSearch,
  customSearchResponse,
  customSearchMessage,
  customTableList,
  emailSent,
  emailSentMessage,
  bgColors,
}) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    columns: [
      { title: "Label", field: "label" },
      {
        title: "Type",
        field: "type",
        lookup: {
          bestSellers: "Best Sellers",
          homeAndBeauty: "Home and Beauty",
          salesReport: "Sales Report",
          itemCostSearch: "Item cost Search",
        },
      },
      {
        title: "Store",
        field: "store",
        lookup: {
          "ecomm,ca,274,250,67": "E-commerce Canada",
          "ecomm,usa,251,217": "E-commerce USA",
          //'retail,ca,ALL': 'Retail Canada',
          //'retail,usa,ALL': 'Retail USA',
          "retail,usa,248,89": "Century City",
          "retail,ca,249,43": "Chinook",
          "retail,ca,252,19": "Gastown",
          //'retail,ca,257,27': 'Oakridge',
          "retail,ca,258,35": "Park Royal",
          "retail,ca,266,39": "Southgate",
          //'retail,usa,77,77': 'Wicker Park',
          "retail,usa,125,125": "Roosevelt",
          "retail,ca,260,31": "Richmond",
          "retail,ca,255,23": "Metrotown",
          "retail,usa,73,73": "Soho",
          "retail,usa,265,85": "Soma",
          "retail,ca,282,63": "Yorkdale",
          "retail,ca,256,111": "Noul Granville",
          "retail,ca,349,349": "Markville",
          "retail,usa,348,348": "Valley Fair",
          "retail,ca,259,47": "Queen Street",
          "retail,ca,267,55": "Toronto Eaton Centre",
          "retail,ca,262,59": "Sherway Gardens",
          "retail,ca,263,51": "Shops at Don Mills",
          "retail,usa,253,81": "Hayes Street",
          "retail,usa,385,385": "Prudential Oak Refined",
          "retail,ca,386,386": "Coquitlam Centre",
        },
      },
      {
        title: "From Date",
        field: "fromDate",
        editComponent: (props) => (
          <input
            type="date"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "To Date",
        field: "toDate",
        editComponent: (props) => (
          <input
            type="date"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "Last Year From Date",
        field: "lyFromDate",
        editComponent: (props) => (
          <input
            type="date"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      {
        title: "Last Year To Date",
        field: "lyToDate",
        editComponent: (props) => (
          <input
            type="date"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      { title: "Position", field: "position" },
      { title: "Total Only", field: "total", type: "boolean" },
      { title: "Total Title", field: "totalTitle" },
      { title: "Tag", field: "searchTag" },
      {
        title: "Background Color",
        field: "bgColor",
        editComponent: (props) => (
          <input
            type="color"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        ),
      },
      { title: "Item Name", field: "itemName" },
    ],
    data: [],
    loading: false,
    modal: false,
    emailModal: false,
    display: "",
    emailList: [],
    positions: {},
  });

  useEffect(() => {
    if (emailSent) {
      setState({
        ...state,
        emailModal: false,
        emailList: [],
      });
    }
  }, [emailSent]);

  useEffect(() => {
    if (customSearchResponse) {
      if (!customSearchResponse["message"]) {
        //setState({...state, loading: false})
        toggleModal();
      }
    }
  }, [customSearchResponse]);

  function toggleModal() {
    let imgDisplay = [];
    let tableDisplay = [];
    let positions = {
      best_sellers: [],
      home_and_beauty: [],
      sales_reports: [],
      item_cost_search: [],
    };

    if (
      customSearchResponse["best_sellers"] &&
      Object.keys(customSearchResponse["best_sellers"]).length > 0
    ) {
      let key = 0;
      for (let set in customSearchResponse["best_sellers"]) {
        positions["best_sellers"].push({ [set]: key });
        imgDisplay.push(
          <React.Fragment>
            <h2 style={{ marginTop: "25px", paddingLeft: "12%" }}>{set}</h2>
            <SingleContainer1 key={key++}>
              <ImageDisplay
                customSearch={true}
                section={"best_sellers"}
                set={set}
                bestSellers={customSearchResponse["best_sellers"][set]}
              />
            </SingleContainer1>
          </React.Fragment>
        );
      }
    }

    if (
      customSearchResponse["home_and_beauty"] &&
      Object.keys(customSearchResponse["home_and_beauty"]).length > 0
    ) {
      let key = 0;
      for (let set in customSearchResponse["home_and_beauty"]) {
        positions["home_and_beauty"].push({ [set]: key });
        imgDisplay.push(
          <React.Fragment>
            <h2 style={{ marginTop: "25px", paddingLeft: "12%" }}>{set}</h2>
            <SingleContainer1 key={key++}>
              <ImageDisplay
                customSearch={true}
                section={"home_and_beauty"}
                set={set}
                bestSellers={customSearchResponse["home_and_beauty"][set]}
              />
            </SingleContainer1>
          </React.Fragment>
        );
      }
    }

    if (
      customSearchResponse["sales_reports"] &&
      Object.keys(customSearchResponse["sales_reports"]).length > 0
    ) {
      let key = 0;
      for (let set in customSearchResponse["sales_reports"]) {
        //console.log("set: ", set)
        positions["sales_reports"].push({ [set]: key });
        tableDisplay.push(
          <React.Fragment>
            <h2 style={{ marginTop: "25px", paddingLeft: "30px" }}>{set}</h2>
            <Table
              key={key++}
              reports={customSearchResponse["sales_reports"][set]}
              set={set}
              section={"sales_reports"}
            />
          </React.Fragment>
        );
      }
    }

    if (
      customSearchResponse["item_cost_search"] &&
      Object.keys(customSearchResponse["item_cost_search"]).length > 0
    ) {
      let key = 0;
      for (let set in customSearchResponse["item_cost_search"]) {
        //console.log("set: ", set)
        positions["item_cost_search"].push({ [set]: key });
        tableDisplay.push(
          <React.Fragment>
            <h2 style={{ marginTop: "25px", paddingLeft: "30px" }}>{set}</h2>
            <Table
              key={key++}
              reports={customSearchResponse["item_cost_search"][set]}
              set={set}
              section={"item_cost_search"}
            />
          </React.Fragment>
        );
      }
    }

    setState({
      ...state,
      imgDisplay,
      tableDisplay,
      positions,
      loading: false,
      modal: !state.modal,
    });
  }

  function toggleEmailModal() {
    setState({ ...state, emailModal: !state.emailModal });
  }

  useEffect(() => {
    let list = [...state.data];
    if (customTableList && customTableList.length > 0) {
      for (let i = 0; i < customTableList.length; i++) {
        let data = customTableList[i];

        let check = state.data.filter((stateData) => {
          if (stateData["uniqid"] === data["uniqid"]) {
            return stateData;
          }
        });

        if (check.length == 0) {
          list.push(data);
        }
      }

      setState({ ...state, data: list });
    }
  }, [customTableList]);

  function addEmail() {
    let email = document.querySelector("#add-email-input").value;
    setState({
      ...state,
      emailList: [...state.emailList, email],
    });
    document.querySelector("#add-email-input").value = "";
  }

  function removeEmail(email) {
    setState({
      ...state,
      emailList: state.emailList.filter((e) => e != email),
    });
  }

  let submitData = [];

  let display = <GridLoader />;
  /*
    if(localStorage.getItem('auth'))
    {
*/
  display = (
    <PageContainer customSearch={customSearch}>
      <Button
        onClick={() => controller.onClick({ btn: "setTimerPage" })}
        id="timer-btn"
        outline
        color="warning"
      >
        Scheduler
      </Button>
      <StyledModal isOpen={state.emailModal} toggle={toggleEmailModal}>
        <ModalHeader toggle={toggleEmailModal}>
          Send Report As Email
        </ModalHeader>
        <ModalBody style={{ overflowY: "auto", height: "50vh" }}>
          <TextField
            id="email-subject"
            className={classes.textField}
            label="Subject"
            margin="normal"
            style={{ width: "97%" }}
            variant="outlined"
          />
          <TextField
            id="email-message"
            label="Message"
            placeholder="Email Message"
            multiline
            className={classes.textField}
            style={{ width: "97%" }}
            margin="normal"
            variant="outlined"
          />
          <form
            id="add-email"
            onSubmit={(e) => {
              e.preventDefault();
              addEmail();
            }}
          >
            <TextField
              id="add-email-input"
              className={classes.textField}
              label="Email"
              margin="normal"
              style={{ width: "75%" }}
            />
            <AddIcon
              onClick={addEmail}
              style={{
                alignSelf: "flex-end",
                width: "35px",
                height: "35px",
                marginBottom: "5px",
                cursor: "pointer",
              }}
            />
          </form>
          <StyledEmailList>
            <h4 style={{ width: "100%", textAlign: "left" }}>Recipients: </h4>
            {state.emailList.length > 0 ? (
              state.emailList.map((email, i) => (
                <div key={i} className="email-list-grouping">
                  <h6>{email}</h6>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => removeEmail(email)}
                  />
                </div>
              ))
            ) : (
              <h6>No Recipients</h6>
            )}
          </StyledEmailList>
        </ModalBody>
        <ModalFooter>
          <h6
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {emailSentMessage}
          </h6>
          <Button
            style={{ width: "100px" }}
            color="primary"
            onClick={() =>
              controller.onClick({
                btn: "sendEmail",
                parameters: {
                  data: customSearchResponse,
                  emailList: state.emailList,
                  positions: state.positions,
                  bgColors,
                },
              })
            }
          >
            Send
          </Button>{" "}
          <Button
            style={{ width: "100px" }}
            color="secondary"
            onClick={toggleEmailModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </StyledModal>

      <StyledModal size="xl" isOpen={state.modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Custom Build</ModalHeader>
        <ModalBody style={{ overflowY: "auto", height: "80vh" }}>
          {state.imgDisplay}
          {state.tableDisplay}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleEmailModal}>
            Send Report As Email
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </StyledModal>

      <div id="progress-container">
        {state.loading ? (
          <Alert
            style={{ textAlign: "center", borderRadius: "0" }}
            color="info"
          >
            {customSearchMessage}
          </Alert>
        ) : (
          ""
        )}
      </div>
      <div id="close-icon">
        <CloseIcon
          onClick={() => controller.onClick({ btn: "setCustomSearchPage" })}
          style={{ height: "50px", width: "50px", cursor: "pointer" }}
        />
      </div>
      <div id="custom-search-table">
        <MaterialTable
          style={{ width: "80%" }}
          icons={tableIcons}
          title="Custom Table Build"
          columns={state.columns}
          data={state.data}
          options={{
            selection: true,
            actionsColumnIndex: -1,
          }}
          components={{
            Row: TableBodyRow,
          }}
          onSelectionChange={(rows) => {
            submitData = rows;
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();

                  setState((prevState) => {
                    const data = [...prevState.data];

                    newData["uniqid"] = uniqid();

                    if (newData["fromDate"]) {
                      newData["fromDate"] = moment(newData["fromDate"]).format(
                        "MM/DD/YYYY"
                      );
                    }

                    if (newData["toDate"]) {
                      newData["toDate"] = moment(newData["toDate"]).format(
                        "MM/DD/YYYY"
                      );
                    }

                    if (newData["lyFromDate"]) {
                      newData["lyFromDate"] = moment(
                        newData["lyFromDate"]
                      ).format("MM/DD/YYYY");
                    }

                    if (newData["lyToDate"]) {
                      newData["lyToDate"] = moment(newData["lyToDate"]).format(
                        "MM/DD/YYYY"
                      );
                    }

                    data.push(newData);

                    return { ...prevState, data };
                  });

                  controller.onClick({
                    btn: "customTable",
                    parameters: { data: newData },
                  });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();

                  if (oldData) {
                    setState((prevState) => {
                      const data = [...prevState.data];

                      let index;
                      data.filter((d, i) => {
                        if (d.uniqid === newData.uniqid) {
                          index = i;
                        }
                      });

                      if (newData["fromDate"]) {
                        newData["fromDate"] = moment(
                          newData["fromDate"]
                        ).format("MM/DD/YYYY");
                      }

                      if (newData["toDate"]) {
                        newData["toDate"] = moment(newData["toDate"]).format(
                          "MM/DD/YYYY"
                        );
                      }

                      if (newData["lyFromDate"]) {
                        newData["lyFromDate"] = moment(
                          newData["lyFromDate"]
                        ).format("MM/DD/YYYY");
                      }

                      if (newData["lyToDate"]) {
                        newData["lyToDate"] = moment(
                          newData["lyToDate"]
                        ).format("MM/DD/YYYY");
                      }

                      data[index] = newData;
                      return { ...prevState, data };
                    });
                  }
                  controller.onClick({
                    btn: "customTable",
                    parameters: { data: newData },
                  });
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  setState((prevState) => {
                    const data = [...prevState.data];

                    let index;
                    data.filter((d, i) => {
                      if (d.uniqid === oldData.uniqid) {
                        index = i;
                      }
                    });

                    data.splice(index, 1);
                    return { ...prevState, data };
                  });
                  controller.onClick({
                    btn: "removeCustomTable",
                    parameters: { id: oldData["uniqid"] },
                  });
                }, 600);
              }),
          }}
        />
      </div>
      <div id="submit-btn">
        <AwesomeButton
          onPress={() => {
            console.log("submitData: ", submitData);

            if (submitData.length > 0) {
              setState({ ...state, loading: true });
              controller.onClick({
                btn: "submitCustomSearch",
                parameters: { data: submitData },
              });
            }
          }}
          ripple
          type="primary"
        >
          Search and Build Table
        </AwesomeButton>
      </div>
    </PageContainer>
  );
  /*
    }
    else
    {
        return <Redirect to='/' />
    }
*/

  return display;
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSearch);
