import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import Styled from "styled-components"
import { Redirect } from 'react-router'
import { GridLoader, SyncLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import SyncIcon from '@material-ui/icons/Sync';
import ImageDisplay from '../parts/imageDisplay';
import TableDaily from '../parts/ecommTableDaily';
import TableProvince from '../parts/ecommTableProvince';
import TableStorePickup from '../parts/ecommTableStorePickup';
import TablCountry from '../parts/ecommTableCountry';
import TableQuarterly from '../parts/ecommTableQuarterly';
import Controller from '../../controller';
import uniqid from 'uniqid';
import Moment from 'moment';
import { TabContent, 
         TabPane, 
         Nav, 
         NavItem, 
         NavLink, 
         Row, 
         Col,
         Tooltip } from 'reactstrap';
import classnames from 'classnames';

const controller = new Controller();


const PageContainer = Styled.div`
    //border: 1px solid blue;
    display: flex;
    flex-flow: column nowrap;
    align-items: ${props => props.align};
    width: 100%;
    height: 100%;
    background-color: ${props => props.bgColor};
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: ${props => props.padding};
    padding-right: ${props => props.padding};



    .inner-page 
    {
        border-radius: 12px;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    }

    .stats-title
    {
        background-color: rgb(52,58,64,1);
        color: white;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px;
        font-size: 1.2rem;
    }

    .stats-value
    {
        font-size: 1.2rem;
    }


    .sync-icon-container-homebeauty, .sync-icon-container
    {
        //border: 1px solid red;
        width: 90%;
        display: flex;
        flex-flow: column nowrap
        justify-content: center;
        align-items: flex-end;
        margin-top: 25px;
        margin-bottom: 10px;
    }


    .tab-display 
    {
        width: 100%;
    }


    .tab-btn 
    {
        cursor: pointer;
    }

    #tab-container
    {
        display: hidden;
        margin-left: 5px;
    }


    h3
    {
        font-size: 1.2rem;
        text-align: left;
        width: 100%;
    }

    .sales-report-label
    {
        width: 100%;
        text-align: left;
        margin-top: 50px;
        font-size: 1.2rem;
        padding-left: 5%;
        padding-right: 5%;
    }

    #hidden-toggle 
    {
        position: fixed;
        top: 60px;;
        left: 0px;
        z-index: 900;
        transition transform .5s;
        background-color: rgb(194, 194, 194);
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid black;
    }


    @media screen and (min-width: 600px)
    {

        h3
        {
            font-size: 1.5rem;
        }


        #tab-container
        {
            display: visible;
        }


        #hidden-toggle 
        {
            position: fixed;
            top: -10px;
            left: 0px;
            z-index: 900;
            transform: translateY(${props => props.hiddenToggle});
            transition transform .5s;
            background-color: rgb(194, 194, 194);
            width: 100%;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid black;
        }


        .sales-report-label
        {
            width: 90%;
            font-size: 1.6rem;
            margin-top: 70px;
        }

        .sync-icon-container-homebeauty, .sync-icon-container
        {
            width: 80%;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            h3
            {
                margin: 0 0 0 45px;
            }
        }

       
    }


`;


const SingleContainer1 = Styled.div`
    /*
    -ms-overflow-style: none;
    scrollbar-width: none;
    */
    width: 85%;
    min-height: 300px;
    //border: 1px solid red;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 65px;
    padding-top: 2%;

    



    @media screen and (min-width: 600px)
    {
        flex-flow: row wrap;
        justify-content: ${props => props.flexVal};
        align-items: flex-start;
        padding-left: ${props => props.paddingLeft};
        height: ${props => props.heightVal};
        overflow-y: scroll;

        /*
        -ms-overflow-style: visible !important;
        scrollbar-width: visible !important;

        ::-webkit-scrollbar {
            display: visible !important;
        }
        */
    }



    /*
    ::-webkit-scrollbar {
        display: none;
    }
    */
    
`;

const SingleContainer2 = Styled.div`
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    min-height: 320px;
    //border: 1px solid red;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding: 3%;
    

    #btnMoreContainer
    {
        border: 1px solid blue;
        width: 100%;
        padding-left: 44.5%;
    }



    @media screen and (min-width: 600px)
    {
        justify-content: center;
        min-height: ${props => props.heightVal};
        width: 85%;

        /*
        -ms-overflow-style: visible !important;
        scrollbar-width: visible !important;

        ::-webkit-scrollbar {
            display: visible !important;
        }
        */
    }

    

    
    ::-webkit-scrollbar {
        display: none;
    }
    
    
`;


const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    toggleContainer: {
        margin: theme.spacing(2, 0),
    },
}));



const mapStateToProps = (state) => {
  return {
    isAuth: state.main.is_auth,
    caEcommBestSellers: state.main.ecomm_ca_bestsellers,
    usaEcommBestSellers: state.main.ecomm_usa_bestsellers,
    caEcommSalesReports: state.main.ecomm_ca_salesreports,
    usaEcommSalesReports: state.main.ecomm_usa_salesreports,
    caEcommHomeBeauty: state.main.ecomm_ca_homebeauty,
    usaEcommHomeBeauty: state.main.ecomm_usa_homebeauty,
    customSearch: state.main.custom_search
  };
};





const Ecommerce = ({ isAuth, customSearch,
                     caEcommBestSellers, 
                     usaEcommBestSellers, 
                     caEcommSalesReports,
                     usaEcommSalesReports,
                     caEcommHomeBeauty,
                     usaEcommHomeBeauty }) => {

let classes = useStyles();
let [state, setState] = useState({
    lastIndex: 10,
    activeTab: "ca",
    hiddenToggle: "-10px",
    tooltipOpenca: false,
    tooltipOpenusa: false,
    tooltipOpencaHb: false,
    tooltipOpenusaHb: false,
});



function toggleTooltip () 
{
    setState({ ...state, [`tooltipOpen${state.activeTab}`]: !state[`tooltipOpen${state.activeTab}`] });
}

function toggleTooltipHb () 
{
    setState({ ...state, [`tooltipOpen${state.activeTab}Hb`]: !state[`tooltipOpen${state.activeTab}Hb`] });
}


function toggle (tab) 
{  
     setState({...state, activeTab: tab});
}


function scrollfn () 
{
    if(window.scrollY > 150)
    {
        setState({
            ...state,
            hiddenToggle: "70px"
        })
    }
    else 
    {
        setState({
            ...state,
            hiddenToggle: "-10px"
        })
    }
}

/*
// Workaround: Currenlty using custom search as best sellers
useEffect(() => {
    
    let date_today = new Date();
    date_today = `${date_today.getMonth()+1}/${date_today.getDate()}/${date_today.getFullYear()}`

    if (localStorage.getItem('user') != undefined)
    {
        
        (async () => {
            await controller.onClick({btn: "submitCustomSearch", parameters: { homepage: true, data: [{fromDate: date_today,
                                                                                    itemName: "",
                                                                                    label: "ecommca",
                                                                                    position: "1",
                                                                                    searchTag: "1",
                                                                                    store: "ecomm,ca,274,250,67",
                                                                                    toDate: date_today,
                                                                                    total: false,
                                                                                    totalTitle: "totals",
                                                                                    type: "bestSellers",
                                                                                    uniqid: uniqid()}]}})

            await controller.onClick({btn: "submitCustomSearch", parameters: { homepage: true, data: [{fromDate: date_today,
                                                                                    itemName: "",
                                                                                    label: "ecommusa",
                                                                                    position: "1",
                                                                                    searchTag: "1",
                                                                                    store: "ecomm,usa,251,217",
                                                                                    toDate: date_today,
                                                                                    total: false,
                                                                                    totalTitle: "totals",
                                                                                    type: "bestSellers",
                                                                                    uniqid: uniqid()}]}})
         })()
    }

    
    
},[isAuth])
 */   


useEffect(() => 
{

    window.addEventListener("scroll", scrollfn);

    return () => window.removeEventListener("scroll", scrollfn);
});


useEffect(() => window.scrollTo(0,0),[state.activeTab])


   


    let dateToday = Moment(new Date()).format('MM/DD/YYYY');


    let display = <GridLoader />;



    if(localStorage.getItem('auth'))
    {

        display = <PageContainer customSearch={customSearch} align={"flex-start"} padding={"5%"} hiddenToggle={state.hiddenToggle} bgColor={"white"}>
                    <div id="hidden-toggle">
                        <Nav style={{width:'100%', display: 'flex', justifyContent: 'center'}} tabs>
                            <NavItem style={{width: '50%', textAlign: 'center'}} className="tab-btn">
                                <NavLink
                                className={classnames({ active: state.activeTab === 'ca' })}
                                onClick={() =>  toggle('ca') }
                                >
                                Canada
                                </NavLink>
                            </NavItem>
                            <NavItem style={{width: '50%', textAlign: 'center'}} className="tab-btn">
                                <NavLink
                                className={classnames({ active: state.activeTab === 'usa' })}
                                onClick={() => toggle('usa')}
                                >
                                USA
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <Nav id="tab-container" tabs>
                    <NavItem className="tab-btn">
                        <NavLink
                        className={classnames({ active: state.activeTab === 'ca' })}
                        onClick={() => toggle('ca')}
                        >
                        Canada
                        </NavLink>
                    </NavItem>
                    <NavItem className="tab-btn">
                        <NavLink
                        className={classnames({ active: state.activeTab === 'usa' })}
                        onClick={() => toggle('usa')}
                        >
                        USA
                        </NavLink>
                    </NavItem>
                    </Nav>
                    <TabContent className="tab-display" activeTab={state.activeTab}>
                    <TabPane className="tab-page zoomIn animated" tabId="ca">
                        <Row>
                            <Col>
                                <PageContainer className="inner-page" align={"center"} padding={"0px"} bgColor={"rgb(251, 250, 248)" }>
                                   
                                    <h3 className="sales-report-label" style={{marginBottom: '35px'}}>E-Commerce Canada Latest Update</h3>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Orders</h2>
                                            <h2 className="stats-value">{caEcommSalesReports['stats'] && caEcommSalesReports['stats']['orders'] ? caEcommSalesReports['stats']['orders'] : 0}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Total</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['total_cad'] ? caEcommSalesReports['stats']['total_cad'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">AOV</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['aov_cad'] ? caEcommSalesReports['stats']['aov_cad'] : 0)}</h2>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Goal</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['goal_cad'] ? caEcommSalesReports['stats']['goal_cad'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Deficit</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['remaining_cad'] ? caEcommSalesReports['stats']['remaining_cad'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Difference</h2>
                                            <h2 className="stats-value">{caEcommSalesReports['stats'] && caEcommSalesReports['stats']['difference_cad'] ? caEcommSalesReports['stats']['difference_cad'] : 0}%</h2>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                         <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Amt to PTD Goal</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['cad_to_tp_goal'] ? caEcommSalesReports['stats']['cad_to_tp_goal'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">PTD Deficit</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['cad_ptd_remaining'] ? caEcommSalesReports['stats']['cad_ptd_remaining'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">PTD Difference</h2>
                                            <h2 className="stats-value">{caEcommSalesReports['stats'] && caEcommSalesReports['stats']['cad_ptd_difference'] ? caEcommSalesReports['stats']['cad_ptd_difference'] : 0}%</h2>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Adjusted Goal</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['cad_adjusted_goal'] ? caEcommSalesReports['stats']['cad_adjusted_goal'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Adjusted Deficit</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['cad_adjusted_remaining'] ? caEcommSalesReports['stats']['cad_adjusted_remaining'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Adjusted Difference</h2>
                                            <h2 className="stats-value">{caEcommSalesReports['stats'] && caEcommSalesReports['stats']['adjusted_difference_cad'] ? caEcommSalesReports['stats']['adjusted_difference_cad'] : 0}%</h2>
                                        </div>   
                                    </div>

                                    <h3 className="sales-report-label">E-Commerce Canada Sales Reports</h3>
                                    <SingleContainer2 heightVal={caEcommSalesReports['last_week'] || caEcommSalesReports['this_week'] ? "350px" : "300px"}>
                                        {
                                            caEcommSalesReports['last_week'] || caEcommSalesReports['this_week'] && 
                                            Object.keys(caEcommSalesReports['last_week']).length > 0 && Object.keys(caEcommSalesReports['this_week']).length > 0  ?
                                            <TableDaily source="ca" reportsLastWeek={caEcommSalesReports['last_week'][Object.keys(caEcommSalesReports['last_week'])[0]]}  
                                                reportsThisWeek={caEcommSalesReports['this_week'][Object.keys(caEcommSalesReports['this_week'])[0]]} 
                                                thisPeriod={caEcommSalesReports['this_period'][Object.keys(caEcommSalesReports['this_period'])[0]]} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>
                                    <h3 className="sales-report-label">Quarterly Updates</h3>
                                    <SingleContainer2 heightVal={caEcommSalesReports['quarterly'] && Object.keys(caEcommSalesReports['quarterly']).length > 0 ? "350px" : "300px"}>
                                        {
                                            caEcommSalesReports['quarterly'] && Object.keys(caEcommSalesReports['quarterly']).length > 0  ?
                                            <TableQuarterly source="ca" quarterlyReport={caEcommSalesReports['quarterly']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>


                                    <h3 className="sales-report-label">Sales Per Province</h3>
                                    <SingleContainer2 heightVal={caEcommSalesReports['ca_province'] && caEcommSalesReports['ca_province'].length > 0 ? "350px" : "300px"}>
                                        {
                                            caEcommSalesReports['ca_province'] && caEcommSalesReports['ca_province'].length > 0  ?
                                            <TableProvince source="ca" provinceReport={caEcommSalesReports['ca_province']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>


                                    <h3 className="sales-report-label">Store Pickup Orders</h3>
                                    <SingleContainer2 heightVal={caEcommSalesReports['store_pickup_orders'] && caEcommSalesReports['store_pickup_orders'].length > 0 ? "250px" : "200px"}>
                                        {
                                            caEcommSalesReports['store_pickup_orders'] && caEcommSalesReports['store_pickup_orders'].length > 0  ?
                                            <TableStorePickup source="ca" storePickupOrders={caEcommSalesReports['store_pickup_orders']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>



                                    <h3 className="sales-report-label" style={{marginBottom: '35px'}}>International Sales</h3>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">International Total</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(caEcommSalesReports['stats'] && caEcommSalesReports['stats']['international_total_cad'] ? caEcommSalesReports['stats']['international_total_cad'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Contribution to CA</h2>
                                            <h2 className="stats-value">{caEcommSalesReports['stats'] && caEcommSalesReports['stats']['international_percent_on_ca'] ? caEcommSalesReports['stats']['international_percent_on_ca'] : 0}%</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Contribution Overall (CAD)</h2>
                                            <h2 className="stats-value">{caEcommSalesReports['stats'] && usaEcommSalesReports['stats'] && caEcommSalesReports['stats']['tp_total'] && usaEcommSalesReports['stats']['tp_total'] ? 
                                                                       (100-Math.round(((parseInt(caEcommSalesReports['stats']['tp_total']) + parseInt(usaEcommSalesReports['stats']['tp_total']))-parseInt(caEcommSalesReports['stats']['international_total_cad']))/(parseInt(caEcommSalesReports['stats']['tp_total']) + parseInt(usaEcommSalesReports['stats']['tp_total']))*100))  : 0}%</h2>
                                        </div>   
                                    </div>
                                    

                                    <SingleContainer2 heightVal={caEcommSalesReports['ca_province'] && caEcommSalesReports['ca_province'].length > 0 ? "350px" : "300px"}>
                                        {
                                            caEcommSalesReports['international'] && caEcommSalesReports['international'].length > 0  ?
                                            <TablCountry source="ca" countryReport={caEcommSalesReports['international']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>
                                    
                                    <div style={{width: '100%', border: '1px solid black', marginBottom: "85px"}} />

                                    <div className="sync-icon-container"  >
                                        <h3>E-Commerce Canada Best Sellers</h3>
                                        {/*<h4 style={{width: '300px', color: 'red'}}>Under Maintenance</h4>*/}

                                        {/*<SyncIcon id="syncBtnEcommCa" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateBestSellers', parameters: {category: `ecomm-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpenca} target="syncBtnEcommCa" toggle={toggleTooltip}>
                                            Click to refresh and sync Best Sellers.
                                        </Tooltip>*/}
                                    </div> 
                                    <SingleContainer1 flexVal={typeof caEcommBestSellers !== 'string' && caEcommBestSellers.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof caEcommBestSellers !== 'string' && caEcommBestSellers.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={ typeof caEcommBestSellers !== 'string' && caEcommBestSellers.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof caEcommBestSellers == 'string' && caEcommBestSellers == 'empty' ?
                                            <SyncLoader /> :
                                            caEcommBestSellers && caEcommBestSellers.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={caEcommBestSellers} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
                                    <div className="sync-icon-container-homebeauty"  >
                                        <h3>E-Commerce Canada Home And Beauty Best Sellers</h3>
                                       {/*<h4 style={{width: '300px', color: 'red'}}>Under Maintenance</h4>*/}
                                       {/* <SyncIcon id="syncBtnEcommCaHB" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateHomeBeauty', parameters: {category: `ecomm-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpencaHb} target="syncBtnEcommCaHB" toggle={toggleTooltipHb}>
                                            Click to refresh and sync Home and Beauty.
                                        </Tooltip> */}
                                    </div>
                                   <SingleContainer1 flexVal={typeof caEcommHomeBeauty !== 'string' && caEcommHomeBeauty.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof caEcommHomeBeauty !== 'string' && caEcommHomeBeauty.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={typeof caEcommHomeBeauty !== 'string' && caEcommHomeBeauty.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof caEcommHomeBeauty == 'string' && caEcommHomeBeauty == 'empty' ?
                                            <SyncLoader /> :
                                            caEcommHomeBeauty && caEcommHomeBeauty.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={caEcommHomeBeauty} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
    
                                </PageContainer>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="tab-page zoomIn animated" tabId="usa">
                        
                        <Row>
                            <Col>
                                <PageContainer className="inner-page" align={"center"} padding={"0px"} bgColor={"rgb(251, 250, 248)"}> 
                                {/*<h4 style={{width: '300px', color: 'red'}}>Under Maintenance</h4>*/}
                                <h3 className="sales-report-label" style={{marginBottom: '35px'}}>E-Commerce USA Latest Update</h3>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Orders</h2>
                                            <h2 className="stats-value">{usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['orders'] ? usaEcommSalesReports['stats']['orders'] : 0}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Total</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['total_usd'] ? usaEcommSalesReports['stats']['total_usd'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">AOV</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['aov_usd'] ? usaEcommSalesReports['stats']['aov_usd'] : 0)}</h2>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Goal</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['goal_usd'] ? usaEcommSalesReports['stats']['goal_usd'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Deficit</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['remaining_usd'] ? usaEcommSalesReports['stats']['remaining_usd'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Difference</h2>
                                            <h2 className="stats-value">{usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['difference_usd'] ? usaEcommSalesReports['stats']['difference_usd'] : 0}%</h2>
                                        </div>
                                       
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                         <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Amt to PTD Goal</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['usd_to_tp_goal'] ? usaEcommSalesReports['stats']['usd_to_tp_goal'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">PTD Deficit</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['usd_ptd_remaining'] ? usaEcommSalesReports['stats']['usd_ptd_remaining'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">PTD Difference</h2>
                                            <h2 className="stats-value">{usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['usd_ptd_difference'] ? usaEcommSalesReports['stats']['usd_ptd_difference'] : 0}%</h2>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexFlow: 'row wrap', width: '80%', justifyContent: 'space-around'}}>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Adjusted Goal</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['usd_adjusted_goal'] ? usaEcommSalesReports['stats']['usd_adjusted_goal'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Adjusted Deficit</h2>
                                            <h2 className="stats-value">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['usd_adjusted_remaining'] ? usaEcommSalesReports['stats']['usd_adjusted_remaining'] : 0)}</h2>
                                        </div>
                                        <div style={{display: 'flex', flexFlow:'column nowrap', justifyContent: 'space-between', alignItems: 'center', width: '240px', margin: '9px', border: '1px solid rgb(52,58,64,1)'}}>
                                            <h2 className="stats-title">Adjusted Difference</h2>
                                            <h2 className="stats-value">{usaEcommSalesReports['stats'] && usaEcommSalesReports['stats']['adjusted_difference_usd'] ? usaEcommSalesReports['stats']['adjusted_difference_usd'] : 0}%</h2>
                                        </div> 
                                    </div>

                                    <h3 className="sales-report-label">E-Commerce USA Sales Reports</h3>
                                    <SingleContainer2 heightVal={usaEcommSalesReports['last_week'] || usaEcommSalesReports['this_week'] ? "350px" : "300px"}>
                                        {
                                            usaEcommSalesReports['last_week'] || usaEcommSalesReports['this_week'] &&
                                            Object.keys(usaEcommSalesReports['last_week']).length > 0 && Object.keys(usaEcommSalesReports['this_week']).length > 0  ?
                                            <TableDaily source="usa" reportsLastWeek={usaEcommSalesReports['last_week'][Object.keys(usaEcommSalesReports['last_week'])[0]]}  
                                                reportsThisWeek={usaEcommSalesReports['this_week'][Object.keys(usaEcommSalesReports['this_week'])[0]]} 
                                                thisPeriod={usaEcommSalesReports['this_period'][Object.keys(usaEcommSalesReports['this_period'])[0]]} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>

                                    <h3 className="sales-report-label">Quarterly Updates</h3>
                                    <SingleContainer2 heightVal={usaEcommSalesReports['quarterly'] && Object.keys(usaEcommSalesReports['quarterly']).length > 0 ? "350px" : "300px"}>
                                        {
                                            usaEcommSalesReports['quarterly'] && Object.keys(usaEcommSalesReports['quarterly']).length > 0  ?
                                            <TableQuarterly source="usa" quarterlyReport={usaEcommSalesReports['quarterly']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>

                                    <h3 className="sales-report-label">Sales Per State</h3>
                                    <SingleContainer2 heightVal={usaEcommSalesReports['usa_state'] && usaEcommSalesReports['usa_state'].length > 0 ? "350px" : "300px"}>
                                        {
                                            usaEcommSalesReports['usa_state'] && usaEcommSalesReports['usa_state'].length > 0  ?
                                            <TableProvince source="usa" provinceReport={usaEcommSalesReports['usa_state']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>



                                    <div style={{width: '100%', border: '1px solid black', marginBottom: "85px"}} />
                            
                                    <div className="sync-icon-container" >
                                        <h3>E-Commerce USA Best Sellers</h3>
                                       {/*<h4 style={{width: '300px', color: 'red'}}>Under Maintenance</h4>*/}
                                       { /*
                                        <SyncIcon id="syncBtnEcommUsa" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateBestSellers', parameters: {category: `ecomm-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpenusa} target="syncBtnEcommUsa" toggle={toggleTooltip}>
                                            Click to refresh and sync Best Sellers.
                                        </Tooltip> */}
                                    </div>
                                   <SingleContainer1 flexVal={typeof usaEcommBestSellers !== 'string' && usaEcommBestSellers.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof usaEcommBestSellers !== 'string' && usaEcommBestSellers.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={typeof usaEcommBestSellers !== 'string' && caEcommBestSellers.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof usaEcommBestSellers == 'string' && usaEcommBestSellers == 'empty' ?
                                            <SyncLoader /> :
                                            usaEcommBestSellers && usaEcommBestSellers.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={usaEcommBestSellers} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
                                    <div className="sync-icon-container-homebeauty"  >
                                        <h3>E-Commerce USA Home And Beauty Best Sellers</h3>
                                        {/*<h4 style={{width: '300px', color: 'red'}}>Under Maintenance</h4>*/}
                                        {/*<SyncIcon id="syncBtnEcommUsaHB" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateHomeBeauty', parameters: {category: `ecomm-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpenusaHb} target="syncBtnEcommUsaHB" toggle={toggleTooltipHb}>
                                            Click to refresh and sync Home and Beauty.
                                        </Tooltip> */}
                                    </div>
                                    <SingleContainer1 flexVal={typeof usaEcommHomeBeauty !== 'string' && usaEcommHomeBeauty.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof usaEcommHomeBeauty !== 'string' && usaEcommHomeBeauty.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={typeof usaEcommHomeBeauty !== 'string' && usaEcommHomeBeauty.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof usaEcommHomeBeauty == 'string' && usaEcommHomeBeauty == 'empty' ?
                                            <SyncLoader /> :
                                            usaEcommHomeBeauty && usaEcommHomeBeauty.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={usaEcommHomeBeauty} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1> 
                                    
                                </PageContainer>
                            </Col>
                        </Row>
                    </TabPane>
                    </TabContent>
                </PageContainer>

    }
    else
    {
        return <Redirect to='/' />
    }


  return display;
}

export default connect(mapStateToProps, null)(Ecommerce)





