import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import styled from "styled-components"
import DisplayCard from './displayCard';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Controller from '../../controller';


const controller = new Controller();





const BtnContainer = styled.div`
    //border: 1px solid blue;
    width: 100%;
    //padding-left: 44.5%;
    display: flex;
    justify-content: center;

    ::-webkit-scrollbar {
    display: none;
    }

    @media screen and (min-width: 600px)
    {
        padding-right: 10%;
    }
`;


const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: "rgb(52, 58, 64, 1)"
    },
}));




let numchange = 0;


const ImageDisplay = ({ bestSellers, customSearch, section, set }) => 
{
    



    let classes = useStyles();
    let [state, setState] = useState({
        lastIndex: 10,
    });

    function loadMore () 
    {
        setState({
            ...state,
            lastIndex: state.lastIndex+10
        });

    }

    
    useEffect(() => {
        if(customSearch)
        {
            console.log('image display customSearch: ', customSearch);
            switch(section)
            {
                case "best_sellers" :
                    controller.onClick({btn: 'setBestSellerCount', parameters: {set, totalCount: state.lastIndex}})
                break;

                case "home_and_beauty" :
                    controller.onClick({btn: 'setHomeBeautyCount', parameters: {set, totalCount: state.lastIndex}})
                break
            }
        }
    }, [state.lastIndex])

    

  return <React.Fragment>
            {
                bestSellers.slice(0,state.lastIndex).map( (bestSeller, i) => <DisplayCard customSearch={customSearch} section={section} set={set} key={i} item={bestSeller} sourceCountry={bestSeller['link']} />)
            }
            <BtnContainer>
                {
                state.lastIndex < bestSellers.length ?
                <Button variant="contained" color="primary" className={classes.button} onClick={loadMore}>
                    Load More
                </Button> :
                null
                }
            </BtnContainer>
         </React.Fragment>;
}

export default connect(null, null)(ImageDisplay)
