import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TableRow from '@material-ui/core/TableRow';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const colCa = [
  { id: 'Day', label: 'Day', minWidth: 120 },
  { id: 'Goal', label: 'Goal (CAD)', minWidth: 120 },
  { id: 'Cad', label: 'Sales (CAD)', minWidth: 120 },
  { id: 'Orders', label: 'Transactions', minWidth: 120 },
  { id: 'LW', label: 'LW', minWidth: 120 },
  { id: 'LWGoal', label: 'LW Goal (CAD)', minWidth: 120 },
  { id: 'LWCad', label: 'LW Sales (CAD)', minWidth: 120 },
  { id: 'LWOrders', label: 'LW Transactions', minWidth: 120 },
];

const colUsa = [
  { id: 'Day', label: 'Day', minWidth: 120 },
  { id: 'Goal', label: 'Goal (USD)', minWidth: 120 },
  { id: 'ActualCad', label: 'Sales (CAD)', minWidth: 120 },
  { id: 'Usd', label: 'Sales (USD)', minWidth: 120 },
  { id: 'Orders', label: 'Transactions', minWidth: 120 },
  { id: 'LW', label: 'LW', minWidth: 120 },
  { id: 'LWGoal', label: 'LW Goal (USD)', minWidth: 120 },
  { id: 'LWCad', label: 'LW Sales (CAD)', minWidth: 120 },
  { id: 'LWUsd', label: 'LW Sales (USD)', minWidth: 120 },
  { id: 'LWOrders', label: 'LW Transactions', minWidth: 120 },
];

function createData(...parameters) {
  
  let response;

  if(parameters.length > 8)
  {
    response = { Day: parameters[0], 
                 Goal: parameters[1], 
                 ActualCad: parameters[2],
                 Usd: parameters[3], 
                 Orders: parameters[4], 
                 LW: parameters[5], 
                 LWGoal: parameters[6], 
                 LWCad: parameters[7],
                 LWUsd: parameters[8], 
                 LWOrders: parameters[9] };
  }
  else
  {
    response = { Day: parameters[0], 
                 Goal: parameters[1], 
                 Cad: parameters[2], 
                 Orders: parameters[3], 
                 LW: parameters[4], 
                 LWGoal: parameters[5], 
                 LWCad: parameters[6], 
                 LWOrders: parameters[7] };
  }

         

  return response;
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 800,
    overflow: 'auto',
  },
});

export default function StickyHeadTable({ source, reportsThisWeek, reportsLastWeek, thisPeriod }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);


  

  let rows, columns; 
  
  switch (source)
  {
    case 'ca' :
      columns = colCa;
      rows = reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map((report, i) => createData(reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['trandate'] : '',
                                                           reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['goal_cad'] : '', 
                                                           reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['cad']: '', 
                                                           reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['orders'] : '', 
                                                           report['trandate'], 
                                                           report['goal_cad'], 
                                                           report['cad'], 
                                                           report['orders'])) : [];

      rows.push(createData('','','','','','','',''));
      rows.push(createData('TOTAL',
                           reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['goal_cad'])).reduce((acc,sv) => acc+sv) : '', 
                           reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['cad'])).reduce((acc,sv) => acc+sv) : '',
                           reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['orders'])).reduce((acc,sv) => acc+sv) : '', 
                           'TOTAL', 
                           reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['goal_cad'])).reduce((acc,sv) => acc+sv) : '', 
                           reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['cad'])).reduce((acc,sv) => acc+sv) : '', 
                           reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['orders'])).reduce((acc,sv) => acc+sv) : ''));

      rows.push(createData('','','','','','','',''));
      rows.push(createData('PTD',
                            thisPeriod ? thisPeriod.map(r => parseInt(r['goal_cad'])).reduce((acc,sv) => acc+sv) : '', 
                            thisPeriod ? thisPeriod.map(r => parseInt(r['cad'])).reduce((acc,sv) => acc+sv) : '',
                            thisPeriod ? thisPeriod.map(r => parseInt(r['orders'])).reduce((acc,sv) => acc+sv) : '', 
                           '', 
                           '', 
                           '', 
                           ''));
    break;

    case 'usa':
      columns = colUsa;
      rows = reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map((report, i) => createData(reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['trandate'] : '',
                                                           reportsThisWeek && reportsThisWeek[i] ?  reportsThisWeek[i]['goal_usd'] : '', 
                                                           reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['cad'] : '',
                                                           reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['usd'] : '', 
                                                           reportsThisWeek && reportsThisWeek[i] ? reportsThisWeek[i]['orders'] : '', 
                                                           report['trandate'], 
                                                           report['goal_usd'], 
                                                           report['cad'], 
                                                           report['usd'], 
                                                           report['orders'])): [];

      rows.push(createData('','','','','','','',''));
      rows.push(createData('TOTAL',
                          reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['goal_usd'])).reduce((acc,sv) => acc+sv) : '', 
                          reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['cad'])).reduce((acc,sv) => acc+sv) : '',
                          reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['usd'])).reduce((acc,sv) => acc+sv) : '',
                          reportsThisWeek && reportsThisWeek.length > 0 ? reportsThisWeek.map(r => parseInt(r['orders'])).reduce((acc,sv) => acc+sv) : '', 
                          'TOTAL', 
                          reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['goal_usd'])).reduce((acc,sv) => acc+sv) : '', 
                          reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['cad'])).reduce((acc,sv) => acc+sv) : '',
                          reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['usd'])).reduce((acc,sv) => acc+sv) : '', 
                          reportsLastWeek && reportsLastWeek.length > 0 ? reportsLastWeek.map(r => parseInt(r['orders'])).reduce((acc,sv) => acc+sv) : '' ));

      rows.push(createData('','','','','','','',''));
      rows.push(createData('PTD',
                            thisPeriod ? thisPeriod.map(r => parseInt(r['goal_usd'])).reduce((acc,sv) => acc+sv) : '', 
                            thisPeriod ? thisPeriod.map(r => parseInt(r['cad'])).reduce((acc,sv) => acc+sv) : '',
                            thisPeriod ? thisPeriod.map(r => parseInt(r['usd'])).reduce((acc,sv) => acc+sv) : '',
                            thisPeriod ? thisPeriod.map(r => parseInt(r['orders'])).reduce((acc,sv) => acc+sv) : '', 
                           '', 
                           '', 
                           '', 
                           ''));
    break;
  }
  
    
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(column => {
                    let value = row[column.id];
                    let sign = '';
                    let dollar = '';
                    if( value && column.id === "Cad" || value && column.id === "Usd")
                    {
                      if(parseInt(row[column.id]) > parseInt(row['Goal']))
                      {
                        sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                      }
                      else
                      {
                        sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                      }
                    }

                    if( value && column.id === "LWUsd")
                    {
                      if(parseInt(row[column.id]) > parseInt(row['LWGoal']))
                      {
                        sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                      }
                      else
                      {
                        sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                      }
                    }

                    if( value && column.id === "LWCad" && source == 'ca')
                    {
                      if(parseInt(row[column.id]) > parseInt(row['LWGoal']))
                      {
                        sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                      }
                      else
                      {
                        sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                      }
                    }

                    if( value && column.id === "Cad" || 
                        value && column.id === "ActualCad" || 
                        value && column.id === "Usd" || 
                        value && column.id === "LWCad" ||
                        value && column.id === "LWUsd" || 
                        value && column.id === "Goal" || 
                        value && column.id === "LWGoal" )
                    {
                      value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {sign}{column.format && typeof value === 'number' ? value : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}