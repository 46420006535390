import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const colCa = [
  { id: 'Country', label: 'Country', minWidth: 120 },
  { id: 'Gross', label: 'Gross (CAD)', minWidth: 120 },
  { id: 'Cad', label: 'Net (CAD)', minWidth: 120 },
];

const colUsa = [
  { id: 'Country', label: 'Country', minWidth: 120 },
  { id: 'Gross', label: 'Gross (CAD)', minWidth: 120 },
  { id: 'Cad', label: 'Net (CAD)', minWidth: 120 },
  { id: 'Usd', label: 'Net (USD)', minWidth: 120 },
];

function createData(...parameters) {
  
  let response;

  if(parameters.length > 3)
  {
    response = { Country: parameters[0], 
                 Gross: parameters[1], 
                 Cad: parameters[2],
                 Usd: parameters[3]
               };
  }
  else
  {
    response = { Country: parameters[0], 
                 Gross: parameters[1], 
                 Cad: parameters[2]
               }
  }

         

  return response;
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    eight: 'auto',
    overflow: 'auto',
  },
});

export default function StickyHeadTable({ countryReport, source }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(102);


  let rows, columns; 
  
  switch (source)
  {
    case 'ca' :
      columns = colCa;
      rows = countryReport.map((report, i) => createData(report['country'], 
                                                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(report['gross'])}`, 
                                                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(report['cad'])}`));

      rows.push(createData('','','','','','','',''));

      rows.push(createData('TOTAL',
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(countryReport.map(r => parseFloat(r['gross'])).reduce((acc,sv) => acc+sv))}`, 
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(countryReport.map(r => parseFloat(r['cad'])).reduce((acc,sv) => acc+sv))}`,
                           ));

     
      
    break;

    case 'usa':
      columns = colUsa;
      rows = countryReport.map((report, i) => createData(report['country'], 
                                                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(report['gross'])}`, 
                                                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(report['cad'])}`,
                                                          `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(report['usd'])}`));

      rows.push(createData('','','','','','','',''));

      rows.push(createData('TOTAL',
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(countryReport.map(r => parseFloat(r['gross'])).reduce((acc,sv) => acc+sv))}`, 
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(countryReport.map(r => parseFloat(r['cad'])).reduce((acc,sv) => acc+sv))}`,
                           `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(countryReport.map(r => parseFloat(r['usd'])).reduce((acc,sv) => acc+sv))}`,
                           ));
      
  }
  
    
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader size="small" aria-label="sticky table" >
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow style={{backgroundColor: i > 9 && i < (rows.length-2) ? 'rgb(222, 222, 222)' : 'rgb(255, 255, 255)'}} role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}