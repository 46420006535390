import firebase from 'firebase';
import axios from 'axios';
import moment from 'moment';
import EmailFormatter from './emailFormatter';
import uniqid from 'uniqid';




function Controller()
{
  // server link to the docker image used for the custom search function of the app
  const serverlink = 'https://backend-1.oakandfort.com' //'https://backend-1.oakandfort.com'; //`https://s9h6asrwrj.execute-api.us-west-2.amazonaws.com/dev/reporting-endpoint`; //`https://backend-1.oakandfort.com`; `http://192.168.128.134:3000`;

  // set all the 
  const app = !firebase.apps.length ?
              firebase.initializeApp({
                apiKey: "AIzaSyBx1-XqJ5JbXEenT2Yyc9xXL2F9qPZPuts",
                authDomain: "oak-and-fort-reporting-v2.firebaseapp.com",
                databaseURL: "https://oak-and-fort-reporting-v2.firebaseio.com",
                projectId: "oak-and-fort-reporting-v2",
                storageBucket: "",
                messagingSenderId: "32917047095",
                appId: "1:32917047095:web:4d85cb1791f57cfd19bcb2"
              }) :
              firebase.app()

  // calling the Redux store
  const store = require('./redux/store/index')['default'];

  // call all the actions available in the app
  const { setIsAuth,
          setToken,
          setUser,
          setecommCaBestSellers,
          setecommUsaBestSellers,
          setretailCaBestSellers,
          setretailUsaBestSellers,
          setecommCaSalesReport,
          setecommUsaSalesReport,
          setretailCaSalesReport,
          setretailUsaSalesReport,
          setCustomSearchResponse,
          setCustomSearchMessage,
          setCustomTable,
          setUserInbox,
          setecommCaHomeBeauty,
          setecommUsaHomeBeauty,
          setretailCaHomeBeauty,
          setretailUsaHomeBeauty,
          setCarouselImage,
          setEmailSent,
          setLoginPrompt,
          setOpenTimer,
          setCustomSearch, 
          setSearchTags, 
          setTimers,
          setBestSellerCount,
          setHomeBeautyCount,
          setRowBgColors,
          setEmailMessage,
          setCustomBuildEcomm,
          setCustomBuildRetail,
          setSchedColorMapEcomm,
          setSchedColorMapRetail, } = require('./redux/actions/index');


  // Create the socket
  let _user = {};
  let openSocket = require('socket.io-client');
  let socket, token, user;
  

  // Session Check
  app.auth().onAuthStateChanged(function(user)
  {
                  if (user) {
                      // User is signed in.
                       _user['displayName'] = user.displayName;
                       _user['email'] = user.email;
                       _user['emailVerified'] = user.emailVerified;
                       _user['photoURL'] = user.photoURL;
                       _user['isAnonymous'] = user.isAnonymous;
                       _user['uid'] = user.uid;
                       _user['providerData'] = user.providerData;


                      
                      store.dispatch(setIsAuth(true));
                      store.dispatch(setUser(localStorage.getItem('user')))
                      
                      registerToken(_user['uid']);

                      localStorage.setItem('i',_user['uid'])

                      
                  } else {
                      // User is signed out.
                      // ...
                      store.dispatch(setIsAuth(false));
                      store.dispatch(setUser(null))
                      //console.log("user signed out!")
                  }
  });




  // Onclick Events
  this['onClick'] = ({event, btn, parameters}) =>
  {


    // action object containing all the 
    const actions = {

      // Login action
      login: async () =>
      {

        const email = document.querySelector('#email-input').value;
        const password = document.querySelector('#password-input').value;

        store.dispatch(setLoginPrompt("Logging you in..."))

        if(!email || !password)
        {
          store.dispatch(setLoginPrompt("Please input your credentials!"));

          setTimeout(() => {
            store.dispatch(setLoginPrompt(""));
          }, 5000)

          return;
        }


        let {data: { data: {checkAccount: {uname, passwd}}}} = await axios({
          method: 'post',
          url: 'https://b4zb34fg2k.execute-api.us-west-2.amazonaws.com/dev/internalrevisednofirebase',
          data: { query: `query ($email: String!, $password: String!, $app: String!) {
                            checkAccount ( email: $email, password: $password, app: $app) {
                                uname
                                passwd
                                err
                                }
                            }`,
                  variables: {
                    "email": email,
                    "password": password,
                    "app": "reporting"
                }
              }
        })
        .catch(err => { 

            store.dispatch(setLoginPrompt("Failed!"));

            setTimeout(() => {
              store.dispatch(setLoginPrompt(""));
            }, 5000)

        })
        

        if(!uname || !passwd)
        {
          store.dispatch(setLoginPrompt("Invalid credentials!"));

          setTimeout(() => {
            store.dispatch(setLoginPrompt(""));
          }, 5000)

          return;
        }



        app.auth().signInWithEmailAndPassword(uname, passwd)
                  .then( res => {

                    localStorage.setItem('user',email.split('.')[0]);
                    store.dispatch(setLoginPrompt("Success!"))

                    setTimeout(() => {
                      store.dispatch(setLoginPrompt(""));
                    }, 1000)

                    return window.localStorage.setItem('auth', 'true')
                  })
                  .catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    store.dispatch(setLoginPrompt("Failed!"));

                    setTimeout(() => {
                      store.dispatch(setLoginPrompt(""));
                    }, 5000)

                    alert(errorMessage);
                    // ...
                  });

      },

      // logout action
      logout: () => {
        removeToken(_user['uid']);
      },

      // load more on the images
      seeMore: ({ title }) => {
      
        window.location.replace(`${window.location.origin}/display?sourceDisplay=${title}`);
      },

      //  custom search action
      submitCustomSearch: ({ data, homepage}) => {

  

        store.dispatch(setCustomSearchMessage("Processing... Please wait..."));

        data = data.map(dte => {
          if(dte['position'])
          {
            return dte;
          }
          else
          {
            dte['position'] = 0;
            return dte;
          }
        })

        
        data.sort((a, b) => {
          let f = parseInt(a['position']) ? parseInt(a['position']) : 0;
          let s = parseInt(b['position']) ? parseInt(b['position']) : 0;
          return f>s?1:s>f?-1:0;
        })

        if(data.filter(d => d.total && !d.totalTitle).length > 0)
        {
          return alert("Please indicate a title! Selecting totals require a total title.")
        }

        if(data.filter(d => !d.label).length > 0)
        {
          return alert("Please label your searches!")
        }

        if(data.filter(d => !d.fromDate || !d.toDate).length > 0)
        {
          return alert("Please add a date range on your searches!")
        }

        let colorMap = {}
        for(let d of data)
        {
          if (d.bgColor)
          {
            colorMap[d.searchTag] = d.bgColor
          }
        }

        store.dispatch(setRowBgColors(colorMap))


       

        let result_string = uniqid();

        return firebase.auth()
                .currentUser
                .getIdToken(true) 
                .then(function(token) {
                  
                 

                   return axios({
                      method: 'POST',
                      url: 'https://o8bsfpkd9f.execute-api.ca-central-1.amazonaws.com/dev/custom_search', //`${serverlink}/customSearch`,
                      //url: `${serverlink}`,
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('user')}-${token}`,
                      },
                      data: {
                        route: 'customSearch',
                        result_string: result_string,
                        searches: data
                      }
                    })
                    .then(res => {
            
                      //console.log('res in cust search: ', res)
            
                      return app.database()
                                .ref(`${localStorage.getItem('i')}/search_result`)
                                .child(user)
                                .child(result_string)
                                .on('value', obj => {
                                  let dta = obj.val();

                                 
                                 

                                  if(homepage && dta)
                                  {
                                    for (let bestSellers in dta['best_sellers'])
                                    {
                                 
                                      switch(bestSellers)
                                      {
                                      case 'ecommca' :
                                          store.dispatch(setecommCaBestSellers(dta['best_sellers'][bestSellers]))
                                      break;
                                      
                                      case 'ecommusa' :
                                          store.dispatch(setecommUsaBestSellers(dta['best_sellers'][bestSellers]))
                                      break;

                                      case 'retailca' :
                                          store.dispatch(setretailCaBestSellers(dta['best_sellers'][bestSellers]))
                                      break;

                                      case 'retailusa' :
                                          store.dispatch(setretailUsaBestSellers(dta['best_sellers'][bestSellers]))
                                      break;
                                      }
                                    }
                                  }
                                  else
                                  {
                                    store.dispatch(setCustomSearchResponse(dta));
                                  }

                                 
                                  
                                  //store.dispatch(setecommCaSalesReport(dta))
                                })
                  
                      //store.dispatch(setCustomSearchResponse(res['data']));
            
                    })
                    .catch(err => {
                      console.log("error: ", err)
                      store.dispatch(setCustomSearchMessage(err['message']));
                      
                    })
                }).catch(function(error) {
                  if (error) throw error
                });

         
        

      },

      // builing a custom table search
      customTable: ({ data }) => {
        let _data = {...data};
        
        if(_data['fromDate'])
        {
          _data['fromDate'] = _data['fromDate'].replace(/\//g,"-");
        }
        else
        {
          _data['fromDate'] = '';
        }

        if(_data['toDate'])
        {
          _data['toDate'] = _data['toDate'].replace(/\//g,"-");
        }
        else
        {
          _data['toDate'] = '';
        }

        if(_data['lyFromDate'])
        {
          _data['lyFromDate'] = _data['lyFromDate'].replace(/\//g,"-");
        }
        else
        {
          _data['lyFromDate'] = '';
        }

        if(_data['lyToDate'])
        {
          _data['lyToDate'] = _data['lyToDate'].replace(/\//g,"-");
        }
        else
        {
          _data['lyToDate'] = '';
        }

        let {include, ...newData } = _data;



        return app.database()
                  .ref(`${_user['uid']}/customTable`)
                  .child(localStorage.getItem('user'))
                  .child(data['uniqid'])
                  .set(newData);
      },

      // Removing the table line
      removeCustomTable: ({ id }) => {
        //let id = parameters['id'];

        return app.database()
                  .ref(`${_user['uid']}/customTable`)
                  .child(localStorage.getItem('user'))
                  .child(id)
                  .remove();
      },

      // used for updating the best sellers via action
      updateBestSellers: ({ category }) => {

        switch(category)
        {
          case 'ecomm-ca' :
              store.dispatch(setecommCaBestSellers("empty"))
          break;
          
          case 'ecomm-usa' :
              store.dispatch(setecommUsaBestSellers("empty"))
          break;

          case 'retail-ca' :
              store.dispatch(setretailCaBestSellers("empty"))
          break;

          case 'retail-usa' :
              store.dispatch(setretailUsaBestSellers("empty"))
          break;
        }

        return axios({
                  method: 'POST',
                  url: `${serverlink}/retrieveBestSellers`,
                  //url: `${serverlink}`,
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user}-${token}`
                  },
                  data: {
                    route: 'retrieveBestSellers',
                    category: [category]
                  }
                })
                .then(res => {
                  //store.dispatch(setCustomSearchResponse(res['data']));
                  for (let bestSellers in res['data'])
                  {
                    switch(bestSellers)
                    {
                      case 'ecommca' :
                          store.dispatch(setecommCaBestSellers(res['data'][bestSellers]))
                      break;
                      
                      case 'ecommusa' :
                          store.dispatch(setecommUsaBestSellers(res['data'][bestSellers]))
                      break;

                      case 'retailca' :
                          store.dispatch(setretailCaBestSellers(res['data'][bestSellers]))
                      break;

                      case 'retailusa' :
                          store.dispatch(setretailUsaBestSellers(res['data'][bestSellers]))
                      break;
                    }
                  }

                  
                  
                })
                .catch(err => {
                  console.log("error getting best sellers: ", err);
                })
      },

      // update the home and beauty best sellers via action
      updateHomeBeauty: ({ category }) => {

        switch(category)
        {
          case 'ecomm-ca' :
              store.dispatch(setecommCaHomeBeauty("empty"))
          break;
          
          case 'ecomm-usa' :
              store.dispatch(setecommUsaHomeBeauty("empty"))
          break;

          case 'retail-ca' :
              store.dispatch(setretailCaHomeBeauty("empty"))
          break;

          case 'retail-usa' :
              store.dispatch(setretailUsaHomeBeauty("empty"))
          break;
        }

        return axios({
                  method: 'POST',
                  url: `${serverlink}/retrieveHomeAndBeauty`,
                  //url: `${serverlink}`,
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user}-${token}`
                  },
                  data: {
                    route: 'retrieveHomeAndBeauty',
                    category: [category]
                  }
                })
                .then(res => {
                  //store.dispatch(setCustomSearchResponse(res['data']));
                  for (let bestSellers in res['data'])
                  {
                    switch(bestSellers)
                    {
                     case 'ecommca' :
                        store.dispatch(setecommCaHomeBeauty(res['data'][bestSellers]))
                     break;
                     
                     case 'ecommusa' :
                        store.dispatch(setecommUsaHomeBeauty(res['data'][bestSellers]))
                     break;
      
                     case 'retailca' :
                        store.dispatch(setretailCaHomeBeauty(res['data'][bestSellers]))
                     break;
      
                     case 'retailusa' :
                        store.dispatch(setretailUsaHomeBeauty(res['data'][bestSellers]))
                     break;
                    }
                  }

                  
                })
                .catch(err => {
                  console.log("error getting best sellers: ", err);
                })
      },

      // Send email action
      sendEmail: ({ data, emailList, positions, bgColors }) => {

        let emailSubject = document.querySelector('#email-subject').value;
        let emailMessage = document.querySelector('#email-message').value;
        let carouselIndex = store.getState()['main']['carousel_index'];
        let currCountBestSeller = store.getState()['main']['cust_best_seller_count'];
        let currCountHomeBeauty = store.getState()['main']['cust_best_home_beauty'];
        
      

        if(!emailList || emailList.length <= 0)
        {
          return alert("Please add recipients!")
        }

        if(!emailSubject)
        {
          return alert("Please add Subject!")
        }


        
        store.dispatch(setEmailSent(false))
        store.dispatch(setEmailMessage("Sending... Please wait..."))

        let emailBody = EmailFormatter({ data, emailMessage, positions, carouselIndex, bgColors, currCountBestSeller, currCountHomeBeauty });

        
        return axios({
          method: 'POST',
          url: `${serverlink}/sendEmailReport`,
          //url: `${serverlink}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${user}-${token}`
          },
          data: {
            route: 'sendEmailReport',
            emailList,
            emailBody: emailBody, //`<h2>Hello,</h2> <h4>Reports are ready for review</h4> <a href=${'http://localhost:3001'}> View Reports</a>`,
            emailSubject
          }
        })
        .then(res => 
          {
            if(res.status == 200)
            {
              store.dispatch(setEmailSent(true))
              store.dispatch(setEmailMessage(""))
            }
        })

      },

      // setting the carousel index for the images
      setCarouselIndex: ({ item, index }) => {
        
        let imageIndexList = {...store.getState()['main']['carousel_index']};

        imageIndexList[item] = index;

        store.dispatch(setCarouselImage(imageIndexList));

      },

      // inserting a new image in place of the custom image searched
      insertImage: ({ parentId, item, link, section, set }) => {
        let customSearResponse = store.getState()['main']['custom_search_response'];
        
        let selectedItem = customSearResponse[section][set].filter(i => parentId === i.parentId)[0];
        
        selectedItem.images.unshift(link);
        
      },

      //  event for selected item update Values
      updateValues: ({ parentId, item, sourceCountry, section, set, revenue, qtySold }) => {
        let customSearResponse = store.getState()['main']['custom_search_response'];
        let selectedItem = customSearResponse[section][set].filter(i => parentId === i.parentId)[0];
        

        switch(sourceCountry)
        {
          case "ca" :
            selectedItem.parent = item;
            selectedItem.qtySold = qtySold;
            selectedItem.revenueCad = revenue;
          break;

          case "usa" :
            selectedItem.parent = item;
            selectedItem.qtySold = qtySold;
            selectedItem.revenueUsd = revenue;
          break
        }

      },

      // populating the custom search 
      setCustomSearchPage: () => {
        let customSearch = store.getState()['main']['custom_search'];

        store.dispatch(setCustomSearch(!customSearch));
        store.dispatch(setOpenTimer(false))

      },

      // Setting the frequency the custom search refreshes
      setTimerPage: () => {
        let timerOpt = store.getState()['main']['open_timer'];

        store.dispatch(setOpenTimer(!timerOpt))
      },

      // timer forn scheduled email reports
      addTimer: ({ timerName }) => {
        return app.database()
                  .ref(`${_user['uid']}/timers`)
                  .child(localStorage.getItem('user'))
                  .update({ [timerName] : {
                            timerName,
                            user: localStorage.getItem('user'),
                            weekday: '*',
                            month: '*',
                            date: '*',
                            hour: '*',
                            minutes: '*',
                            recipients: 'empty',
                            includes: 'empty',
                            emailSubject: 'empty',
                            emailMessage: 'empty',
                            setDsh: {
                              ecomm: {
                                active: false,
                                best_sellers: false
                              },
                              retail: {
                                active: false,
                                best_sellers: false
                              }
                            },
                            enabled: false,
                            override: { from: 'empty', 
                                        to: 'empty', 
                                        lyFrom: 'empty', 
                                        lyTo: 'empty',
                                        fixedRangeFrom: 'empty',
                                        fixedRangeTo: 'empty',
                                        lyFixedRangeFrom: 'empty',
                                        lyFixedRangeTo: 'empty' }
                  }})
      },

      // Setting the CRON time expression for the timer
      saveTimer: ({ timerName, data }) => {

        

        if(data[timerName]['minutes'] == '' || data[timerName]['minutes'] == ' ')
        {
          data[timerName]['minutes'] = '*'
        }

        if(data[timerName]['hour'] == '' || data[timerName]['hour'] == ' ')
        {
          data[timerName]['hour'] = '*'
        }

        if(data[timerName]['date'] == '' || data[timerName]['date'] == ' ')
        {
          data[timerName]['date'] = '*'
        }

        if(data[timerName]['override']['to'] == '' || data[timerName]['override']['to'] == ' ')
        {
          data[timerName]['override']['to'] = 'empty'
        }

        if(data[timerName]['override']['from'] == '' || data[timerName]['override']['from'] == ' ')
        {
          data[timerName]['override']['from'] = 'empty'
        }

        if(data[timerName]['override']['lyFrom'] == '' || data[timerName]['override']['lyFrom'] == ' ')
        {
          data[timerName]['override']['lyFrom'] = 'empty'
        }

        if(data[timerName]['override']['lyTo'] == '' || data[timerName]['override']['lyTo'] == ' ')
        {
          data[timerName]['override']['lyTo'] = 'empty'
        }

       

        if( data[timerName]['includes'] == '' || 
            data[timerName]['includes'] == ' ' || 
            data[timerName]['includes'].length === 0 ||
            data[timerName]['includes'].length == undefined )
        {
          data[timerName]['includes'] = 'empty'
        }

 

        data[timerName]['recipients'] = data[timerName]['recipients'] && data[timerName]['recipients'].length > 0 ? data[timerName]['recipients'] : 'empty';
        
        return app.database()
                  .ref(`${_user['uid']}/timers`)
                  .child(localStorage.getItem('user'))
                  .update({ [timerName]: {...data[timerName], timerName, user: localStorage.getItem('user')} })
                  .then(() => {
                    alert(`${timerName} has been saved!`)
                  })
    
      },

      // Removing the timers from the custom searches
      removeTimer: ({ timerName }) => {

        let del = prompt("Are you sure you want to delete this timer? type 'yes' or 'no'", "no");

        if(del === 'yes')
        {
          return app.database()
                  .ref(`${_user['uid']}/timers`)
                  .child(localStorage.getItem('user'))
                  .child(timerName)
                  .remove()
                  .then(() => {
                    alert(`${timerName} has been deleted!`)
                  })
        }

        return;

      },

      // Setting the best sellers count
      setBestSellerCount: ({ set, totalCount }) => {

    
        let currCount = store.getState()['main']['cust_best_seller_count'];


        if(totalCount > currCount)
        {
          store.dispatch(setBestSellerCount(totalCount))
        }

      },

      // Setting the home and beauty best sellers
      setHomeBeautyCount: ({ set, totalCount }) => {

        let currCount = store.getState()['main']['cust_best_seller_count'];
    

        if(totalCount > currCount)
        {
          store.dispatch(setHomeBeautyCount(totalCount))
        }

      }

    }

    // if there is a button clicked, it will return the actions object containing the name of the action button clicked
    if(btn)
    {
      try
      {
        return actions[btn](parameters);
      }
      catch (err)
      {
       
      }
    }

  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // This portion of the code is related to generating a token session for each instance of the app opened for sockets purposes
  // Please note that the socket is not used anymore.
  function registerToken (id)
  {

    // creates a hash for the unique sessions used for the sockets
    let crypto = require('crypto');
    let hash = crypto.createHash('md5').update(id).digest('hex');
   

    app.database()
       .ref(`${id}/tokens`)
       .child(localStorage.getItem('user'))
       .set(hash)
       .then(() => {

         token = hash;
         user = localStorage.getItem('user');

         store.dispatch(setUser(localStorage.getItem('user')));
         store.dispatch(setToken(hash));
        
         /*
         var connectionOptions =  {
            "force new connection" : true,
            "reconnection": true,
            "reconnectionDelay": 2000,                  //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
            "reconnectionDelayMax" : 60000,             //1 minute maximum delay between connections
            "reconnectionAttempts": "Infinity",         //to prevent dead clients, having the user to having to manually reconnect after a server restart.
            "timeout" : 10000,                           //before connect_error and connect_timeout are emitted.
            "transports" : ["websocket"]                //forces the transport to be only websocket. Server needs to be setup as well/
          }

         
         socket = openSocket(`${serverlink}?token=${token}&user=${user}`, {"transports" : ["websocket"], secure: true});

         
         socket.emit('subscribeEcommSalesReportsCa', {user, token});
         socket.emit('subscribeEcommSalesReportsUsa', {user, token});
         socket.emit('subscribeRetailSalesReportsCa', {user, token});
         socket.emit('subscribeRetailSalesReportsUsa', {user, token});
     
        
 
         socket.on('ecommSalesReportsCa', data => {
           console.log("Sales Report Updated!");
   
           store.dispatch(setecommCaSalesReport(data))
         });
 
         socket.on('ecommSalesReportsUsa', data => {
           
           store.dispatch(setecommUsaSalesReport(data))
         });
 
         socket.on('retailSalesReportsCa', data => {
           
           store.dispatch(setretailCaSalesReport(data))
         });
 
         socket.on('retailSalesReportsUsa', data => {
           
           store.dispatch(setretailUsaSalesReport(data))
         });
         */




         
        /**
        * 
        * Below will be the set of listeners on firebase retrieving fresh numbers and plugging them via redux actions
        * There will be multiple sets of listeners retrieving the values for both US and CA 
        * 
        * 
        */

        app.database()
          .ref(`${id}/tables`)
          .child('ecomm')
          .child('ca')
          .on('value', obj => {
            let dta = obj.val();
            // console.log('aaah:', dta)
           
            if(dta)
            {
              store.dispatch(setecommCaSalesReport(dta))
            }
            else
            {
              store.dispatch(setecommCaSalesReport([]))
            }
          })

        app.database()
          .ref(`${id}/tables`)
          .child('ecomm')
          .child('usa')
          .on('value', obj => {
            let dta = obj.val();
            console.log('dta: ', dta)
            if(dta)
            {
              store.dispatch(setecommUsaSalesReport(dta))
            }
            else
            {
              store.dispatch(setecommUsaSalesReport([]))
            }
           
          })

        app.database()
          .ref(`${id}/tables`)
          .child('retail')
          .child('ca')
          .on('value', obj => {
            let dta = obj.val();
         
            if(dta)
            {
              store.dispatch(setretailCaSalesReport(dta))
            }
            else
            {
              store.dispatch(setretailCaSalesReport([]))
            }
            
          })

        app.database()
          .ref(`${id}/tables`)
          .child('retail')
          .child('usa')
          .on('value', obj => {
            let dta = obj.val();
            if(dta)
            {
              store.dispatch(setretailUsaSalesReport(dta))
            }
            else
            {
              store.dispatch(setretailUsaSalesReport([]))
            }
            
          })

        // apparels ecommerce
        app.database()
          .ref(`${id}/best_sellers`)
          .child('apparels')
          .child('274')
          .on('value', obj => {
            let dta = obj.val();
            if(dta)
            {
              store.dispatch(setecommCaBestSellers(dta))
            }
            else
            {
              store.dispatch(setecommCaBestSellers([]))
            }
            
          })

        app.database()
          .ref(`${id}/best_sellers`)
          .child('apparels')
          .child('251')
          .on('value', obj => {
            let dta = obj.val();

           
            if(dta)
            {
              store.dispatch(setecommUsaBestSellers(dta))
            }
            else
            {
              store.dispatch(setecommUsaBestSellers([]))
            }
            
          })

        // best sellers home and beauty ecomm
        app.database()
          .ref(`${id}/best_sellers`)
          .child('home_and_beauty')
          .child('274')
          .on('value', obj => {
            let dta = obj.val();
           
            if(dta)
            {
              store.dispatch(setecommCaHomeBeauty(dta))
            }
            else
            {
              store.dispatch(setecommCaHomeBeauty([]))
            }
           
          })

        app.database()
          .ref(`${id}/best_sellers`)
          .child('home_and_beauty')
          .child('251')
          .on('value', obj => {
            let dta = obj.val();
            if(dta)
            {
              store.dispatch(setecommUsaHomeBeauty(dta))
            }
            else
            {
              store.dispatch(setecommUsaHomeBeauty([]))
            }
           
          })


        // retail best sellers apparel retail
        app.database()
          .ref(`${id}/best_sellers`)
          .child('apparels_retail')
          .child('canada')
          .on('value', obj => {
            let dta = obj.val();
           
            if(dta)
            {
              store.dispatch(setretailCaBestSellers(dta))
            }
            else
            {
              store.dispatch(setretailCaBestSellers([]))
            }
           
          })

        app.database()
          .ref(`${id}/best_sellers`)
          .child('apparels_retail')
          .child('usa')
          .on('value', obj => {
            let dta = obj.val();
           
            if(dta)
            {
              store.dispatch(setretailUsaBestSellers(dta))
            }
            else
            {
              store.dispatch(setretailUsaBestSellers([]))
            }
           
          })


        // retail best sellers home and beauty
        app.database()
          .ref(`${id}/best_sellers`)
          .child('home_and_beauty_retail')
          .child('canada')
          .on('value', obj => {
            let dta = obj.val();
            if(dta)
            {
              store.dispatch(setretailCaHomeBeauty(dta))
            }
            else
            {
              store.dispatch(setretailCaHomeBeauty([]))
            }
           
          })

        app.database()
          .ref(`${id}/best_sellers`)
          .child('home_and_beauty_retail')
          .child('usa')
          .on('value', obj => {
            let dta = obj.val();

           
            if(dta)
            {
              store.dispatch(setretailUsaHomeBeauty(dta))
            }
            else
            {
              store.dispatch(setretailUsaHomeBeauty([]))
            }
           
          })

         
         let salesReportInterval, customBuildInterval;

         //window.clearInterval(salesReportInterval);
         window.clearInterval(customBuildInterval);

        /*
         salesReportInterval = setInterval(() => 
                                  axios({
                                    method: 'POST',
                                    url: `${serverlink}/retrieveSalesReports`,
                                    //url: `${serverlink}`,
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': `${user}-${token}`
                                    },
                                    data: {
                                      route: 'retrieveSalesReports',
                                      category: ['ecomm-ca', 'ecomm-usa', 'retail-ca', 'retail-usa']
                                    }
                                  })
                                  .then(res => {

                                    console.log('sample data: ', res['data'])
                                    
                                    for (let salesReport in res['data'])
                                    {
                                      switch(salesReport)
                                      {

                                      case 'ecommca' :
                                          store.dispatch(setecommCaSalesReport(res['data'][salesReport]))
                                      break;
                                      
                                      case 'ecommusa' :
                                          store.dispatch(setecommUsaSalesReport(res['data'][salesReport]))
                                      break; 

                                      case 'retailca' :
                                          store.dispatch(setretailCaSalesReport(res['data'][salesReport]))
                                      break;

                                      case 'retailusa' :
                                          store.dispatch(setretailUsaSalesReport(res['data'][salesReport]))
                                      break;
                                      
                                      }
                                    }

                                    //console.log("Sales Reports Updated!")
                                  })
                                  .catch(err => {
                                    console.log("error getting best sellers: ", err);
                                  }),60000);
                                  */
        
         customBuildInterval = setInterval(() => 
                                  axios({
                                    method: 'POST',
                                    url: `${serverlink}/retrieveCustomBuild`,
                                    //url: `${serverlink}`,
                                    headers: {
                                      'Content-Type': 'application/json',
                                      'Authorization': `${user}-${token}`
                                    },
                                    data: {
                                      route: 'retrieveCustomBuild',
                                    }
                                  })
                                  .then(res => {
                                    
                                    let data = res['data'];
                                  
                                    store.dispatch(setCustomBuildEcomm(data['ecomm']))
                                    store.dispatch(setCustomBuildRetail(data['retail']))
                                    
                                    if(data['ecomm']['color_map'])
                                    {
                                      store.dispatch(setSchedColorMapEcomm(data['ecomm']['color_map']))
                                    }

                                    if(data['retail']['color_map'])
                                    {
                                      store.dispatch(setSchedColorMapRetail(data['retail']['color_map']))
                                    }
                                          

                                  })
                                  .catch(err => {
                                    console.log("error getting best sellers: ", err);
                                  }),60000);
          


          /*
          axios({
            method: 'POST',
            url: `${serverlink}/retrieveSalesReports`,
            //url: `${serverlink}`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${user}-${token}`
            },
            data: {
              route: 'retrieveSalesReports',
              category: ['ecomm-ca', 'ecomm-usa', 'retail-ca', 'retail-usa']
            }
          })
          .then(res => {
            
            console.log('sample data: ', res['data'])
            
            for (let salesReport in res['data'])
            {
              switch(salesReport)
              {
                
              case 'ecommca' :
                  store.dispatch(setecommCaSalesReport(res['data'][salesReport]))
              break;
              
              case 'ecommusa' :
                  store.dispatch(setecommUsaSalesReport(res['data'][salesReport]))
              break;
              
              case 'retailca' :
                  store.dispatch(setretailCaSalesReport(res['data'][salesReport]))
              break;

              case 'retailusa' :
                  store.dispatch(setretailUsaSalesReport(res['data'][salesReport]))
              break;
              
              }
            }
            

            //console.log("Sales Reports Updated!")
          })
          .catch(err => {
            console.log("error getting best sellers: ", err);
          }) 
          */
          


          /*
          axios({
            method: 'POST',
            url: `${serverlink}/retrieveBestSellers`,
            //url: `${serverlink}`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${user}-${token}`
            },
            data: {
              route: 'retrieveBestSellers',
              category: ['ecomm-ca', 'ecomm-usa', 'retail-ca', 'retail-usa']
            }
          })
          .then(res => {
            console.log("res bestSellers: ", res);

            for (let bestSellers in res['data'])
            {
              switch(bestSellers)
              {
               case 'ecommca' :
                  store.dispatch(setecommCaBestSellers(res['data'][bestSellers]))
               break;
               
               case 'ecommusa' :
                  store.dispatch(setecommUsaBestSellers(res['data'][bestSellers]))
               break;

               case 'retailca' :
                  store.dispatch(setretailCaBestSellers(res['data'][bestSellers]))
               break;

               case 'retailusa' :
                  store.dispatch(setretailUsaBestSellers(res['data'][bestSellers]))
               break;
              }
            }

            //console.log("Best Sellers Updated!")
          })
          .catch(err => {
            console.log("error getting best sellers: ", err);
          })
          
      

          axios({
            method: 'POST',
            url: `${serverlink}/retrieveHomeAndBeauty`,
            //url: `${serverlink}`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${user}-${token}`
            },
            data: {
              route: 'retrieveHomeAndBeauty',
              category: ['ecomm-ca', 'ecomm-usa', 'retail-ca', 'retail-usa']
            }
          })
          .then(res => {
            
            for (let bestSellers in res['data'])
            {
              switch(bestSellers)
              {
               case 'ecommca' :
                  store.dispatch(setecommCaHomeBeauty(res['data'][bestSellers]))
               break;
               
               case 'ecommusa' :
                  store.dispatch(setecommUsaHomeBeauty(res['data'][bestSellers]))
               break;

               case 'retailca' :
                  store.dispatch(setretailCaHomeBeauty(res['data'][bestSellers]))
               break;

               case 'retailusa' :
                  store.dispatch(setretailUsaHomeBeauty(res['data'][bestSellers]))
               break;
              }
            }

            //console.log("Home and Beauty Updated!")
          })
          .catch(err => {
            console.log("error getting best sellers: ", err);
          })
          */
          


          axios({
            method: 'POST',
            url: `${serverlink}/retrieveCustomBuild`,
            //url: `${serverlink}`,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${user}-${token}`
            },
            data: {
              route: 'retrieveCustomBuild'
            }
          })
          .then(res => {
            
            let data = res['data'];
           

            store.dispatch(setCustomBuildEcomm(data['ecomm']))
            store.dispatch(setCustomBuildRetail(data['retail']))

            if(data['ecomm']['color_map'])
            {
              store.dispatch(setSchedColorMapEcomm(data['ecomm']['color_map']))
            }

            if(data['retail']['color_map'])
            {
              store.dispatch(setSchedColorMapRetail(data['retail']['color_map']))
            }

          })
          .catch(err => {
            console.log("error getting best sellers: ", err);
          })

        
       })




    // retrieves the users custom tables built
    app.database()
       .ref(`${id}/customTable`)
       .child(localStorage.getItem('user'))
       .on("value", snapshot => {
         
        let snapVal = snapshot.val();

        let customTableList = [];
        let searchTags = {};

        for(let c in snapVal)
        {
          if(snapVal[c]['fromDate'])
          {
            snapVal[c]['fromDate'] = moment(snapVal[c]['fromDate']).format('MM/DD/YYYY')
          }

          if(snapVal[c]['toDate'])
          {
            snapVal[c]['toDate'] = moment(snapVal[c]['toDate']).format('MM/DD/YYYY')
          }

          if(snapVal[c]['lyFromDate'])
          {
            snapVal[c]['lyFromDate'] = moment(snapVal[c]['lyFromDate']).format('MM/DD/YYYY')
          }

          if(snapVal[c]['lyToDate'])
          {
            snapVal[c]['lyToDate'] = moment(snapVal[c]['lyToDate']).format('MM/DD/YYYY')
          }

          customTableList.push(snapVal[c]);

          if ( snapVal[c]['searchTag'] )
          {  
            if(searchTags[snapVal[c]['searchTag']])
            {
              searchTags[snapVal[c]['searchTag']] += 1;
            }
            else
            {
              searchTags[snapVal[c]['searchTag']] = 1;
            }
          }
          
        }


        store.dispatch(setSearchTags(Object.keys(searchTags)));
        store.dispatch(setCustomTable(customTableList));

        return;
        
       })


       // retrieve the custom build scheduled
       app.database()
          .ref(`${id}/timers`)
          .child(localStorage.getItem('user'))
          .on("value", snapshot => {
            
            let snapVal = snapshot.val();

            //console.log("timers: ", snapVal);


            store.dispatch(setTimers(snapVal))
          

            return;
            
          })

       
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // remove the token upon logout
  function removeToken (id)
  {
    app.database()
       .ref(`${id}/tokens`)
       .child(localStorage.getItem('user'))
       .remove()
       .then(() => 
        app.auth().signOut()
            .then(() => { 
              localStorage.removeItem('user')
              store.dispatch(setToken(null));                
              localStorage.removeItem('auth')
            })
            .then(() => window.location.replace("/"))
            .catch(function(error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;

              alert(errorMessage);
              // ...
            })
       )
  }



}


export default Controller;
