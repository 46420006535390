import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import Styled from "styled-components"
import { Redirect } from 'react-router'
import { GridLoader, SyncLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SyncIcon from '@material-ui/icons/Sync';
import ImageDisplay from '../parts/imageDisplay';
import RetailMtdTable from '../parts/retailMtdTable';
import RetailDailytable from '../parts/retailDailyTable';
import CustomTable from '../parts/customSearchTable';
import Controller from '../../controller';
import uniqid from 'uniqid';
import Moment from 'moment';
import { TabContent, 
         TabPane, 
         Nav, 
         NavItem, 
         NavLink, 
         Row, 
         Col,
         Tooltip } from 'reactstrap';
import classnames from 'classnames';

const controller = new Controller();


const PageContainer = Styled.div`
    //border: 1px solid blue;
    display: flex;
    flex-flow: column nowrap;
    align-items: ${props => props.align};
    width: 100%;
    height: 100%;
    background-color: ${props => props.bgColor};
    padding-top: 15%;
    padding-bottom: 5%;
    padding-left: ${props => props.padding};
    padding-right: ${props => props.padding};
    


    .inner-page-retail 
    {
        border-radius: 12px;
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    }




    .sync-icon-container-homebeauty, .sync-icon-container
    {
        //border: 1px solid red;
        width: 90%;
        display: flex;
        flex-flow: column nowrap
        justify-content: center;
        align-items: flex-end;
        margin-top: 25px;
        margin-bottom: 10px;
    }



    h4
    {
        width: 79%;
        text-align: left;
        margin-top: 35px;
    }


    .tab-display 
    {
        width: 100%;
        margin-top: 50px;
    }


    .tab-btn 
    {
        cursor: pointer;
    }

    #tab-container
    {
        display: none;
        margin-left: 5px;
    }


    h3
    {
        font-size: 1.2rem;
        text-align: left;
        width: 100%;
    }


    .sales-report-label
    {
        width: 100%;
        text-align: left;
        margin-top: 50px;
        font-size: 1.2rem;
        padding-left: 5%;
        padding-right: 5%;
    }

    #hidden-toggle-retail 
    {
        position: fixed;
        top: 60px;
        left: 0px;
        z-index: 900;
        transition transform .5s;
        //background-color: rgb(194, 194, 194);
        width: 100%;
        display: flex;
        justify-content: center
        border-bottom: 1px solid black
    }



    @media screen and (min-width: 600px)
    {
        padding-top: 5%;

        .tab-display 
        {
            margin-top: 0px;
        }
        
        h3
        {
            font-size: 1.5rem;
        }


        #hidden-toggle-retail  
        {
            position: fixed;
            top: -10px;
            left: 0px;
            z-index: 900;
            transform: translateY(${props => props.hiddenToggle});
            transition transform .5s;
            //background-color: rgb(194, 194, 194);
            width: 100%;
            display: flex;
            justify-content: center
        }

        #tab-container
        {
            display: flex;
        }

        .sales-report-label
        {
            width: 90%;
            font-size: 1.6rem;
            margin-top: 70px;
        }

        .sync-icon-container-homebeauty, .sync-icon-container
        {
            width: 80%;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            h3
            {
                margin: 0 0 0 45px;
            }
        }
    }


`;


const SingleContainer1 = Styled.div`
    /*
    -ms-overflow-style: none;
    scrollbar-width: none;
    */
    width: 85%;
    min-height: 300px;
    //border: 1px solid red;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 65px;
    padding-top: 2%;

    



    @media screen and (min-width: 600px)
    {
        flex-flow: row wrap;
        justify-content: ${props => props.flexVal};
        align-items: flex-start;
        padding-left: ${props => props.paddingLeft};
        height: ${props => props.heightVal};
        overflow-y: scroll;

        /*
        -ms-overflow-style: visible !important;
        scrollbar-width: visible !important;

        ::-webkit-scrollbar {
            display: visible !important;
        }
        */
    }



    /*
    ::-webkit-scrollbar {
        display: none;
    }
    */
    
`;

const SingleContainer2 = Styled.div`
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    min-height: 320px;
    //border: 1px solid red;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding: 3%;
    

    #btnMoreContainer
    {
        border: 1px solid blue;
        width: 100%;
        padding-left: 44.5%;
    }



    @media screen and (min-width: 600px)
    {
        justify-content: center;
        min-height: ${props => props.heightVal};
        width: 85%;

        /*
        -ms-overflow-style: visible !important;
        scrollbar-width: visible !important;

        ::-webkit-scrollbar {
            display: visible !important;
        }
        */
    }

    

    
    ::-webkit-scrollbar {
        display: none;
    }
    
    
`;


const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(1),
    },
    toggleContainer: {
        margin: theme.spacing(2, 0),
    },
}));



const mapStateToProps = (state) => {
  return {
    isAuth: state.main.is_auth,
    caRetailBestSellers: state.main.retail_ca_bestsellers,
    usaRetailBestSellers: state.main.retail_usa_bestsellers,
    caRetailSalesReports: state.main.retail_ca_salesreports,
    usaRetailSalesReports: state.main.retail_usa_salesreports,
    caRetailHomeBeauty: state.main.retail_ca_homebeauty,
    usaRetailHomeBeauty: state.main.retail_usa_homebeauty,
    customBuildRetail: state.main.custom_build_retail
  };
};





const Ecommerce = ({ isAuth, 
                     caRetailBestSellers, 
                     usaRetailBestSellers, 
                     caRetailSalesReports,
                     usaRetailSalesReports,
                     caRetailHomeBeauty,
                     usaRetailHomeBeauty,
                     customBuildRetail }) => {


let classes = useStyles();
let [state, setState] = useState({
    lastIndex: 10,
    activeTab: "ca",
    hiddenToggle: "-10px",
    caEcommBestSellers: {},
    usaEcommBestSellers: {},
    caEcommSalesReports: {},
    usaEcommSalesReports: {},
    tooltipOpenca: false,
    tooltipOpenusa: false,
    tooltipOpencaHb: false,
    tooltipOpenusaHb: false,
});


function toggleTooltip () 
{
    setState({ ...state, [`tooltipOpen${state.activeTab}`]: !state[`tooltipOpen${state.activeTab}`] });
}

function toggleTooltipHb () 
{
    setState({ ...state, [`tooltipOpen${state.activeTab}Hb`]: !state[`tooltipOpen${state.activeTab}Hb`] });
}

function toggle (tab) 
{  
    if(state.activeTab !== tab)
    {
        return setState({...state, activeTab: tab});
    };
}


function scrollfn () 
{
    if(window.scrollY > 150)
    {
        setState({
            ...state,
            hiddenToggle: "70px"
        })
    }
    else 
    {
        setState({
            ...state,
            hiddenToggle: "-10px"
        })
    }
}



/*
useEffect(() => {
    
    let date_today = new Date();
    date_today = `${date_today.getMonth()+1}/${date_today.getDate()}/${date_today.getFullYear()}`

    if (localStorage.getItem('user') != undefined)
    {
        
        (async () => {
            await controller.onClick({btn: "submitCustomSearch", parameters: { homepage: true, data: [{fromDate: date_today,
                                                                                    itemName: "",
                                                                                    label: "retailca",
                                                                                    position: "1",
                                                                                    searchTag: "1",
                                                                                    store: "retail,ca,249,43,252,19,258,35,266,39,260,31,255,23,282,63,256,111,349,348,259,47,267,55,262,59,263,51",
                                                                                    toDate: date_today,
                                                                                    total: false,
                                                                                    totalTitle: "totals",
                                                                                    type: "bestSellers",
                                                                                    uniqid: uniqid()}]}})

            await controller.onClick({btn: "submitCustomSearch", parameters: { homepage: true, data: [{fromDate: date_today,
                                                                                    itemName: "",
                                                                                    label: "retailusa",
                                                                                    position: "1",
                                                                                    searchTag: "1",
                                                                                    store: "retail,usa,253,81,348,265,85,73,125,248,89,251,217",
                                                                                    toDate: date_today,
                                                                                    total: false,
                                                                                    totalTitle: "totals",
                                                                                    type: "bestSellers",
                                                                                    uniqid: uniqid()}]}})
         })()
    }

    
    
},[isAuth])

*/
    


useEffect(() => 
{

    window.addEventListener("scroll", scrollfn);
    

    return () => window.removeEventListener("scroll", scrollfn);
});


useEffect(() => window.scrollTo(0,0),[state.activeTab])






    let custom_display_sales_reports = [];
    let custom_display_best_sellers = [];

    if(Object.keys(customBuildRetail).length > 0)
    {
        for (let t in customBuildRetail)
        {
            let key = 0;
            for (let r in customBuildRetail[t]['sales_reports'])
            {
                custom_display_sales_reports.push (
                    <SingleContainer2 heightVal={usaRetailSalesReports['today'] && Object.keys(usaRetailSalesReports['today']).length > 0 ? "350px" : "300px"}>
                        {
                          <>
                          <h3>{ r }</h3>
                          <p style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>Created By:  { customBuildRetail[t]['owner'] }</p>
                          <CustomTable key={key++} reports={customBuildRetail[t]['sales_reports'][r]} schedColorMapRetail={customBuildRetail[t]['color_map'] ? customBuildRetail[t]['color_map'] : {}} customBuild={true} set={'retail'} />
                          </>
                        }
                    </SingleContainer2>
                )
            }
        }
    }
    

    

    let dateToday = Moment(new Date()).format('MM/DD/YYYY');


    let display = <GridLoader />;

    if(localStorage.getItem('auth'))
    {

        display = <PageContainer align={"flex-start"} padding={"5%"} hiddenToggle={state.hiddenToggle} bgColor={"white"}>
                    <div id="hidden-toggle-retail">
                        <Nav style={{width:'100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center'}} tabs>
                            <NavItem style={{width: '100%', textAlign: 'center', backgroundColor: 'rgb(194, 194, 194)'}} className="tab-btn">
                                <NavLink
                                className={classnames({ active: state.activeTab === 'ca' })}
                                onClick={() => { toggle('ca'); }}
                                style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                >
                                Canada
                                </NavLink>
                            </NavItem>
                            <NavItem style={{width: '100%', textAlign: 'center', backgroundColor: 'rgb(194, 194, 194)'}} className="tab-btn">
                                <NavLink
                                className={classnames({ active: state.activeTab === 'usa' })}
                                onClick={() => { toggle('usa'); }}
                                style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                >
                                USA
                                </NavLink>
                            </NavItem>
                            <NavItem style={{width: '100%', textAlign: 'center', backgroundColor: 'rgb(194, 194, 194)'}} className="tab-btn">
                                <NavLink
                                className={classnames({ active: state.activeTab === 'custom_builds' })}
                                onClick={() => { toggle('custom_builds'); }}
                                style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                >
                                CUSTOM BUILDS
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <Nav id="tab-container" tabs>
                    <NavItem className="tab-btn">
                        <NavLink
                        className={classnames({ active: state.activeTab === 'ca' })}
                        onClick={() => { toggle('ca'); }}
                        style={{width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                        Canada
                        </NavLink>
                    </NavItem>
                    <NavItem className="tab-btn">
                        <NavLink
                        className={classnames({ active: state.activeTab === 'usa' })}
                        onClick={() => { toggle('usa'); }}
                        style={{width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                        USA
                        </NavLink>
                    </NavItem>
                    <NavItem className="tab-btn">
                        <NavLink
                        className={classnames({ active: state.activeTab === 'custom_builds' })}
                        onClick={() => { toggle('custom_builds'); }}
                        style={{width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                        Custom Builds
                        </NavLink>
                    </NavItem>
                    </Nav>
                    <TabContent className="tab-display" activeTab={state.activeTab}>
                    <TabPane className="tab-page zoomIn animated" tabId="ca">
                        <Row>
                            <Col>
                                <PageContainer className="inner-page-retail" align={"center"} padding={"0px"} bgColor={"rgb(251, 250, 248)" }>
                                    <h3 className="sales-report-label">Retail Canada Sales Reports</h3>
                                    <h4>This Period</h4>
                                    <SingleContainer2 heightVal={caRetailSalesReports && caRetailSalesReports['this_period'] && Object.keys(caRetailSalesReports['this_period']).length > 0 ? "350px" : "300px"}>
                                        {
                                            caRetailSalesReports && caRetailSalesReports['this_period'] && Object.keys(caRetailSalesReports['this_period']).length > 0  ?
                                            <RetailMtdTable source="ca" periodReports={caRetailSalesReports['this_period']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>
                                    <h4>Latest Update</h4>
                                    <SingleContainer2 heightVal={caRetailSalesReports && caRetailSalesReports['today'] && Object.keys(caRetailSalesReports['today']).length > 0 ? "350px" : "300px"}>
                                        {
                                            caRetailSalesReports && caRetailSalesReports['today'] && Object.keys(caRetailSalesReports['today']).length > 0  ?
                                            <RetailDailytable source="ca" dailyReports={caRetailSalesReports['today']} periodReports={caRetailSalesReports['this_period']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>
                                    
                                    <div style={{width: '100%', border: '1px solid black', marginBottom: "85px"}} />

                                    <div className="sync-icon-container"  >
                                        <h3>Retail Canada Best Sellers</h3>

                                        {/*<SyncIcon id="syncBtnRetailCa" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateBestSellers', parameters: {category: `retail-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpenca} target="syncBtnRetailCa" toggle={toggleTooltip} >
                                            Click to refresh and sync Best Sellers.
                                        </Tooltip>*/}
                                    </div>
                                    <SingleContainer1 flexVal={typeof caRetailBestSellers !== 'string' && caRetailBestSellers.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof caRetailBestSellers !== 'string' && caRetailBestSellers && caRetailBestSellers.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={typeof caRetailBestSellers !== 'string' && caRetailBestSellers && caRetailBestSellers.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof caRetailBestSellers == 'string' && caRetailBestSellers == 'empty' ?
                                            <SyncLoader /> :
                                            caRetailBestSellers && caRetailBestSellers.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={caRetailBestSellers} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
                                    <div className="sync-icon-container-homebeauty"  >
                                        <h3>Retail Canada Home And Beauty Best Sellers</h3>
                                    
                                       {/* <SyncIcon id="syncBtnRetailCaHB" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateHomeBeauty', parameters: {category: `retail-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpencaHb} target="syncBtnRetailCaHB" toggle={toggleTooltipHb}>
                                            Click to refresh and sync Home and Beauty.
                                        </Tooltip> */}
                                    </div>
                                    <SingleContainer1 flexVal={typeof caRetailHomeBeauty !== 'string' && caRetailHomeBeauty.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof caRetailHomeBeauty !== 'string' && caRetailHomeBeauty.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={typeof caRetailHomeBeauty !== 'string' && caRetailHomeBeauty.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof caRetailHomeBeauty == 'string' && caRetailHomeBeauty == 'empty' ?
                                            <SyncLoader /> :
                                            caRetailHomeBeauty && caRetailHomeBeauty.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={caRetailHomeBeauty} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
                                    
                                </PageContainer>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="tab-page zoomIn animated" tabId="usa">
                        <Row>
                            <Col>
                                <PageContainer className="inner-page-retail" align={"center"} padding={"0px"} bgColor={"rgb(251, 250, 248)"}>
                                    <h3 className="sales-report-label">Retail USA Sales Reports</h3>
                                    <h4>This Period</h4>
                                    <SingleContainer2 heightVal={usaRetailSalesReports && usaRetailSalesReports['this_period'] && Object.keys(usaRetailSalesReports['this_period']).length > 0 ? "350px" : "300px"}>
                                        {
                                            usaRetailSalesReports && usaRetailSalesReports['this_period'] && Object.keys(usaRetailSalesReports['this_period']).length > 0  ?
                                            <RetailMtdTable source="usa" periodReports={usaRetailSalesReports['this_period']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>
                                    <h4>Latest Update</h4>
                                    <SingleContainer2 heightVal={ usaRetailSalesReports && usaRetailSalesReports['today'] && Object.keys(usaRetailSalesReports['today']).length > 0 ? "350px" : "300px"}>
                                        {
                                            usaRetailSalesReports && usaRetailSalesReports['today'] && Object.keys(usaRetailSalesReports['today']).length > 0  ?
                                            <RetailDailytable source="usa" dailyReports={usaRetailSalesReports['today']} periodReports={usaRetailSalesReports['this_period']} />
                                            : <SyncLoader />
                                        }
                                    </SingleContainer2>
                                    
                                    <div style={{width: '100%', border: '1px solid black', marginBottom: '85px'}} />

                                    <div className="sync-icon-container">
                                        <h3>Retail USA Best Sellers</h3>

                                        {/*<SyncIcon id="syncBtnRetailUsa" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateBestSellers', parameters: {category: `retail-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpenusa} target="syncBtnRetailUsa" toggle={toggleTooltip} >
                                            Click to refresh and sync Best Sellers.
                                    </Tooltip>*/}
                                    </div>
                                    <SingleContainer1 flexVal={typeof usaRetailBestSellers !== 'string' && usaRetailBestSellers.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof usaRetailBestSellers !== 'string' && usaRetailBestSellers.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={ typeof usaRetailBestSellers !== 'string' && usaRetailBestSellers.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof usaRetailBestSellers == 'string' && usaRetailBestSellers == 'empty' ?
                                            <SyncLoader /> :
                                            usaRetailBestSellers && usaRetailBestSellers.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={usaRetailBestSellers} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
                                    <div className="sync-icon-container-homebeauty"  >
                                        <h3>Retail USA Home And Beauty Best Sellers</h3>
                                    
                                       {/* <SyncIcon id="syncBtnRetailUsaHB" style={{width: '2.5rem', height: '2.5rem', cursor: 'pointer'}} onClick={() => controller.onClick({btn: 'updateHomeBeauty', parameters: {category: `retail-${state.activeTab}`}})}/>
                                        <Tooltip placement="right" isOpen={state.tooltipOpenusaHb} target="syncBtnRetailUsaHB" toggle={toggleTooltipHb}>
                                            Click to refresh and sync Home and Beauty.
                                        </Tooltip>*/}
                                    </div>
                                    <SingleContainer1 flexVal={typeof usaRetailHomeBeauty !== 'string' && usaRetailHomeBeauty.length > 0 ? "flex-start" : "center"} 
                                                      heightVal={typeof usaRetailHomeBeauty !== 'string' && usaRetailHomeBeauty.length > 0 ? "800px" : "300px"} 
                                                      paddingLeft={typeof usaRetailHomeBeauty !== 'string' && usaRetailHomeBeauty.length > 0 ? "5%" : "1%"}>
                                        {
                                            typeof usaRetailHomeBeauty == 'string' && usaRetailHomeBeauty == 'empty' ?
                                            <SyncLoader /> :
                                            usaRetailHomeBeauty && usaRetailHomeBeauty.length > 0 ?
                                            <ImageDisplay customSearch={false} bestSellers={usaRetailHomeBeauty} /> :
                                            <h2>Nothing to display at the moment. Try again later.</h2>
                                        }
                                    </SingleContainer1>
                                    
                                </PageContainer>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane className="tab-page zoomIn animated" tabId="custom_builds">
                        <Row>
                            <Col>
                                <PageContainer className="inner-page-retail" align={"center"} padding={"0px"} bgColor={"rgb(251, 250, 248)"}>
                                    { custom_display_sales_reports }
                                </PageContainer>
                            </Col>
                        </Row>
                    </TabPane>
                    </TabContent>
                </PageContainer>

    }
    else
    {
        return <Redirect to='/' />
    }


  return display;
}

export default connect(mapStateToProps, null)(Ecommerce)

