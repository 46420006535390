import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BounceLoader } from 'react-spinners';
import TextField from '@material-ui/core/TextField';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Controller from '../../controller';


const controller = new Controller();


const useStyles = makeStyles(theme => ({
  card: {
    width: '220px',
    margin: '1% 0 1% 2.5%'
  },
  media: {
    height: 420,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function MediaCard({ item, sourceCountry, customSearch, section, set }) {
  const classes = useStyles();
  const [state, setState] = useState({ imageLink: "loading", 
                                       item: '',
                                       parentId: '', 
                                       qtySold: '', 
                                       revenue: '', 
                                       loading: true, 
                                       insertImageLink: '',
                                       update: false });

  //carousel
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);


  let slides = [];

  //console.log('MediaCard item: ', item);


  //console.log("customSearch: ", customSearch);

  useEffect(() => {
        if(item)
        {
            setState({
              parentId: item.parentId,
              item: item.parent,
              qtySold: item.qtySold,
              revenue: (sourceCountry === "ca" ? item.revenueCad : item.revenueUsd),
              imageLink: item['images'],
              loading: false
            })
        }
  },[item]);


  function insertImage()
  {
    if(state.insertImageLink)
    {
      controller.onClick({btn: 'insertImage', parameters: {parentId: state.parentId, item: state.item, link: state.insertImageLink, section, set}})
      if(item)
      {
          setState({
            ...state,
            imageLink: item['images'],
            insertImageLink: ''
          })
      }
    }
    
  }

  function updateValues()
  {
    if(state.update)
    {
      controller.onClick({btn: 'updateValues', parameters: {parentId: state.parentId, item: state.item, section, set, qtySold: state.qtySold, revenue: state.revenue, sourceCountry}})
      if(item)
      {
          setState({
            ...state,
            item: item.parent,
            qtySold: item.qtySold,
            revenue: (sourceCountry === "ca" ? item.revenueCad : item.revenueUsd),
            update: false
          })
      }
    }
    
  }


  function toggleUpdate()
  {
    setState({
      ...state,
      update: !state.update
    })
  }



  function handleChange(e)
  {
    let st = e['target'].getAttribute('name');
    let val = e['target']['value']

    setState({
      ...state,
      [st]: val
    })
  }


  function insertImageText(e)
  {
    let val = e.target.value;

    setState({
      ...state,
      insertImageLink: val
    })
  }


  return (
    <Card className={classes.card}>
        {
          customSearch && section?
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <TextField
              className={classes.textField}
              label="Insert image Link"
              margin="normal"
              onChange={insertImageText}
              value={state.insertImageLink}
            /> 
            <div style={{display: 'flex', alignItems: 'flex-end', paddingBottom: '5px', paddingRight: '3.5px'}}>
              <InsertLinkIcon onClick={insertImage} style={{width: '40px', height: '40px', cursor: 'pointer'}} />
            </div>
          </div>:
          ''
        }
        {
          state.loading ?
          <div style={{height: '225px', width: '100%', paddingTop: '30px', display:'flex', flexFlow: 'column nowrap', justifyContent:'space-around', alignItems:'center'}}>
            <BounceLoader />
            <h6>retrieving images...</h6>
          </div> :
          <Carousel 
            showThumbs={false}
            onChange={(index) => 
            {
              if(customSearch)
              {
                controller.onClick({ btn: "setCarouselIndex", parameters: { item: state.item, index: index} })
              }
            }}
          >
            { 
              state.imageLink ?
              state.imageLink.map((img, i) => 
                    <div key={i}>
                        <img src={img} />
                    </div>)
              : ''
            
            }
          </Carousel>
        }
        <CardContent>
          <Typography gutterBottom  component="h5">
            {
              state.update ?
              <input name="item" style={{width: '100%', padding: '5px'}} 
                                  type="text" 
                                  value={ state.item } 
                                  onChange={handleChange} />
              : <a href={'https://ca.oakandfort.com/search?keywords='+state.item} target="_blank">{ state.item }</a>
            }
          </Typography>
          <Typography gutterBottom  component="h6">
            Quantity Sold: {
              state.update ?
              <input name="qtySold" style={{width: '100%', padding: '5px'}} 
                                  type="text" 
                                  value={ state.qtySold } 
                                  onChange={handleChange} />
              : state.qtySold
            }
          </Typography>
          <Typography gutterBottom  component="h6">
            Revenue: 
            {
              state.update ?
              <input name="revenue" style={{width: '100%', padding: '5px'}} 
                            type="text" 
                            value={ state.revenue } 
                            onChange={handleChange} />
              :" " + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(state.revenue)
            }
            
          </Typography>
        </CardContent>

        {
          customSearch ?
          <CardActions style={{display: 'flex', justifyContent: 'flex-end'}}>
            {
              state.update ?
              <Button onClick={updateValues} size="small" color="primary">
                Save
              </Button> : 
              <Button onClick={toggleUpdate} size="small" color="primary">
                Update Values
              </Button>
            }
          </CardActions>
          : ''
        }
       
    </Card>
  );
}

