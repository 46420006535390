import React, { useEffect } from 'react';
import logo from './logo.svg';
import { setecommCaBestSellers, 
         setecommUsaBestSellers,
         setretailCaBestSellers,
         setretailUsaBestSellers,
         setecommCaSalesReport, 
         setecommUsaSalesReport, 
         setretailCaSalesReport, 
         setretailUsaSalesReport,
         setCustomSearchResponse } from "./redux/actions/index";
import './App.css';
import Styled from 'styled-components';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Authenticate from './components/pages/authenticate';
import Retail from './components/pages/retail';
import Navigation from './components/parts/navigation';
import Ecommerce from './components/pages/ecommerce';
import CustomSearch from './components/pages/customSearch';
import Timer from './components/pages/timer';
import Inbox from './components/pages/inbox';


const Container = Styled.div`
    //border: 1px solid green;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 50px;
    ${props => props.customSearch ? 'height: 80vh; overflow-y: hidden;' : ''}
`;


const mapStateToProps = state => {
  return {
    user: state.main.user,
    token: state.main.token,
    customSearch: state.main.custom_search
  };
};




function noScroll() {
  window.scrollTo(0, 0);
}


function App({ customSearch }) {

  //console.log("customSearch in app: ", customSearch)

  return (
      <Container customSearch={customSearch}>
        <Navigation/>

        <Switch history={createBrowserHistory()} >
            <Route exact path='/' render={({history}) => <Authenticate history={history} />} />
            <Route exact path='/ecommerce' render={({history}) => <Ecommerce history={history} />} />
            <Route exact path='/retail' render={({history}) => <Retail history={history} />} />
        </Switch>

        <CustomSearch/>
        <Timer />
      </Container>
  );
}

export default connect(mapStateToProps, null)(App);


//<Route exact path='/home' render={({history}) => <Home history={history} />} />