import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    display: "flex",
    justifyContent: "space-between",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const colCa = [
  { id: "Store", label: "Store", minWidth: 120 },
  { id: "Goal", label: "Goal (CAD)", minWidth: 120 },
  { id: "Actual", label: "Actual (CAD)", minWidth: 120 },
  { id: "Diff", label: "Diff $", minWidth: 120 },
  { id: "DiffP", label: "Diff %", minWidth: 120 },
  { id: "Ly", label: "LY", minWidth: 120 },
  { id: "LyP", label: "LY Diff %", minWidth: 120 },
];

const colUsa = [
  { id: "Store", label: "Store", minWidth: 120 },
  { id: "Goal", label: "Goal (USD)", minWidth: 120 },
  { id: "ActualC", label: "Actual (CAD)", minWidth: 120 },
  { id: "ActualU", label: "Actual (USD)", minWidth: 120 },
  { id: "Diff", label: "Diff $ (USD)", minWidth: 120 },
  { id: "DiffP", label: "Diff % (USD)", minWidth: 120 },
  { id: "Ly", label: "LY (USD)", minWidth: 120 },
  { id: "LyP", label: "LY Diff % (USD)", minWidth: 120 },
];

function createDataCa(...parameters) {
  let response;

  response = {
    Store: parameters[0],
    Goal: parameters[1],
    Actual: parameters[2],
    Diff: parameters[3],
    DiffP: parameters[4],
    Ly: parameters[5],
    LyP: parameters[6],
  };

  return response;
}

function createDataUsa(...parameters) {
  let response;

  response = {
    Store: parameters[0],
    Goal: parameters[1],
    ActualC: parameters[2],
    ActualU: parameters[3],
    Diff: parameters[4],
    DiffP: parameters[5],
    Ly: parameters[6],
    LyP: parameters[7],
  };

  return response;
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableWrapper: {
    eight: "auto",
    overflow: "auto",
  },
});

export default function StickyHeadTable({ periodReports, source }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(35);
  const monthlyReports = periodReports;

  console.log("period reports: ", periodReports);

  let rows = [],
    columns;

  //return '';

  // LOCATION GROUPING
  switch (source) {
    case "ca":
      columns = colCa;
      let bcStore = [
        "Richmond",
        "Metrotown",
        "Gastown",
        "Oakridge",
        "Granville Street",
        "Park Royal",
        "McArthur Glen Outlet",
        "Guildford Town Centre",
        "Coquitlam Centre",
      ];

      let abStore = ["Southgate", "Chinook", "West Edmonton Mall"];

      let onStore = [
        "Toronto Eaton Centre",
        "Yorkdale",
        "Markville",
        "Sherway Gardens",
        "Shops at Don Mills",
        "Queen Street",
        "Fairview Mall",
        "Vaughan Mills",
        "Square One",
        "Polo Park",
      ];

      let mbStore = ["Polo Park"];

      let filteredBC = bcStore.filter((bcs) => monthlyReports[bcs]);

      if (filteredBC.length > 0) {
        rows = [
          ...rows,
          ...filteredBC.map((store, i) =>
            createDataCa(
              store,
              monthlyReports[store][0]["goal_cad"],
              monthlyReports[store][0]["cad"],
              monthlyReports[store][0]["difference_cad"],
              monthlyReports[store][0]["difference_cad_percent"],
              monthlyReports[store][0]["ly_cad"],
              monthlyReports[store][0]["ly_cad"] > 0
                ? monthlyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100
            )
          ),
        ];

        rows.push(createDataCa("", "", "", "", "", "", "", ""));
        rows.push(
          createDataCa(
            "BC TOTAL",
            filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredBC
                .map((s) => parseInt(monthlyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredBC
                  .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredBC
                  .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            ),
            filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredBC
                .map((s) => parseInt(monthlyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredBC
                  .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredBC
                  .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            )
          )
        );

        rows.push(createDataCa("", "", "", "", "", "", "", ""));
        rows.push(createDataCa("", "", "", "", "", "", "", ""));
      }

      let filteredAB = abStore.filter((bcs) => monthlyReports[bcs]);

      if (filteredAB.length > 0) {
        rows = [
          ...rows,
          ...filteredAB.map((store, i) =>
            createDataCa(
              store,
              monthlyReports[store][0]["goal_cad"],
              monthlyReports[store][0]["cad"],
              monthlyReports[store][0]["difference_cad"],
              monthlyReports[store][0]["difference_cad_percent"],
              monthlyReports[store][0]["ly_cad"],
              monthlyReports[store][0]["ly_cad"] > 0
                ? monthlyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100
            )
          ),
        ];

        rows.push(createDataCa("", "", "", "", "", "", "", ""));
        rows.push(
          createDataCa(
            "AB TOTAL",
            filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredAB
                .map((s) => parseInt(monthlyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredAB
                  .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredAB
                  .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            ),
            filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredAB
                .map((s) => parseInt(monthlyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredAB
                  .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredAB
                  .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            )
          )
        );

        rows.push(createDataCa("", "", "", "", "", "", "", ""));
        rows.push(createDataCa("", "", "", "", "", "", "", ""));
      }

      let filteredON = onStore.filter((bcs) => monthlyReports[bcs]);

      if (filteredON.length > 0) {
        rows = [
          ...rows,
          ...filteredON.map((store, i) =>
            createDataCa(
              store,
              monthlyReports[store][0]["goal_cad"],
              monthlyReports[store][0]["cad"],
              monthlyReports[store][0]["difference_cad"],
              monthlyReports[store][0]["difference_cad_percent"],
              monthlyReports[store][0]["ly_cad"],
              monthlyReports[store][0]["ly_cad"] > 0
                ? monthlyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100
            )
          ),
        ];

        rows.push(createDataCa("", "", "", "", "", "", "", ""));
        rows.push(
          createDataCa(
            "ON TOTAL",
            filteredON
              .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredON
              .map((s) => parseInt(monthlyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredON
              .map((s) => parseInt(monthlyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredON
                .map((s) => parseInt(monthlyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredON
                  .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredON
                  .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            ),
            filteredON
              .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredON
                .map((s) => parseInt(monthlyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredON
                  .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredON
                  .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            )
          )
        );

        rows.push(createDataCa("", "", "", "", "", "", "", ""));
        rows.push(createDataCa("", "", "", "", "", "", "", ""));
      }

      let totalGoal =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(monthlyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalCad =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(monthlyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalDiffCad =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(monthlyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalLyCad =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(monthlyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalDiffP = Math.round(((totalCad - totalGoal) / totalGoal) * 100);

      let totalLyTyDiffP = Math.round(
        ((totalCad - totalLyCad) / totalLyCad) * 100
      );

      rows.push(
        createDataCa(
          "TOTAL",
          totalGoal,
          totalCad,
          totalDiffCad,
          totalDiffP,
          totalLyCad,
          totalLyTyDiffP
        )
      );
      break;

    case "usa":
      columns = colUsa;

      let west = [
        "Hayes Street",
        "Century City",
        "Valley Fair",
        "Soma",
        "Bellevue Square",
      ];

      let east = ["Soho", "Wicker Park", "Roosevelt", "Prudential Oak Refined"];

      let FilteredWest = west.filter((ws) => monthlyReports[ws]);

      if (FilteredWest.length > 0) {
        rows = [
          ...rows,
          ...FilteredWest.map((store, i) => {
            return createDataUsa(
              store,
              monthlyReports[store][0]["goal_usd"],
              monthlyReports[store][0]["cad"],
              monthlyReports[store][0]["usd"],
              monthlyReports[store][0]["difference_usd"],
              monthlyReports[store][0]["difference_usd_percent"],
              monthlyReports[store][0]["ly_usd"],
              monthlyReports[store][0]["ly_usd"] > 0
                ? monthlyReports[store][0]["ty_ly_usd_diff_percent"]
                : 100
            );
          }),
        ];

        rows.push(createDataUsa("", "", "", "", "", "", "", ""));

        rows.push(
          createDataUsa(
            "WEST COAST TOTAL",
            FilteredWest.map((s) =>
              parseInt(monthlyReports[s][0]["goal_usd"])
            ).reduce((acc, sv) => acc + sv),
            FilteredWest.map((s) =>
              parseInt(monthlyReports[s][0]["cad"])
            ).reduce((acc, sv) => acc + sv),
            FilteredWest.map((s) =>
              parseInt(monthlyReports[s][0]["usd"])
            ).reduce((acc, sv) => acc + sv),
            FilteredWest.map((s) =>
              parseInt(monthlyReports[s][0]["difference_usd"])
            ).reduce((acc, sv) => acc + sv),
            Math.round(
              ((FilteredWest.map((s) =>
                parseInt(monthlyReports[s][0]["usd"])
              ).reduce((acc, sv) => acc + sv) -
                FilteredWest.map((s) =>
                  parseInt(monthlyReports[s][0]["goal_usd"])
                ).reduce((acc, sv) => acc + sv)) /
                FilteredWest.map((s) =>
                  parseInt(monthlyReports[s][0]["goal_usd"])
                ).reduce((acc, sv) => acc + sv)) *
                100
            ),
            FilteredWest.map((s) =>
              parseInt(monthlyReports[s][0]["ly_usd"])
            ).reduce((acc, sv) => acc + sv),
            Math.round(
              ((FilteredWest.map((s) =>
                parseInt(monthlyReports[s][0]["usd"])
              ).reduce((acc, sv) => acc + sv) -
                FilteredWest.map((s) =>
                  parseInt(monthlyReports[s][0]["ly_usd"])
                ).reduce((acc, sv) => acc + sv)) /
                FilteredWest.map((s) =>
                  parseInt(monthlyReports[s][0]["ly_usd"])
                ).reduce((acc, sv) => acc + sv)) *
                100
            )
          )
        );

        rows.push(createDataUsa("", "", "", "", "", "", "", ""));
        rows.push(createDataUsa("", "", "", "", "", "", "", ""));
      }

      let FilteredEast = east.filter((es) => monthlyReports[es]);

      if (FilteredEast.length > 0) {
        rows = [
          ...rows,
          ...FilteredEast.map((store, i) => {
            return createDataUsa(
              store,
              monthlyReports[store][0]["goal_usd"],
              monthlyReports[store][0]["cad"],
              monthlyReports[store][0]["usd"],
              monthlyReports[store][0]["difference_usd"],
              monthlyReports[store][0]["difference_usd_percent"],
              monthlyReports[store][0]["ly_usd"],
              monthlyReports[store][0]["ly_usd"] > 0
                ? monthlyReports[store][0]["ty_ly_usd_diff_percent"]
                : 100
            );
          }),
        ];

        rows.push(createDataUsa("", "", "", "", "", "", "", ""));

        rows.push(
          createDataUsa(
            "EAST COAST TOTAL",
            FilteredEast.map((s) =>
              parseInt(monthlyReports[s][0]["goal_usd"])
            ).reduce((acc, sv) => acc + sv),
            FilteredEast.map((s) =>
              parseInt(monthlyReports[s][0]["cad"])
            ).reduce((acc, sv) => acc + sv),
            FilteredEast.map((s) =>
              parseInt(monthlyReports[s][0]["usd"])
            ).reduce((acc, sv) => acc + sv),
            FilteredEast.map((s) =>
              parseInt(monthlyReports[s][0]["difference_usd"])
            ).reduce((acc, sv) => acc + sv),
            Math.round(
              ((FilteredEast.map((s) =>
                parseInt(monthlyReports[s][0]["usd"])
              ).reduce((acc, sv) => acc + sv) -
                FilteredEast.map((s) =>
                  parseInt(monthlyReports[s][0]["goal_usd"])
                ).reduce((acc, sv) => acc + sv)) /
                FilteredEast.map((s) =>
                  parseInt(monthlyReports[s][0]["goal_usd"])
                ).reduce((acc, sv) => acc + sv)) *
                100
            ),
            FilteredEast.map((s) =>
              parseInt(monthlyReports[s][0]["ly_usd"])
            ).reduce((acc, sv) => acc + sv),
            Math.round(
              ((FilteredEast.map((s) =>
                parseInt(monthlyReports[s][0]["usd"])
              ).reduce((acc, sv) => acc + sv) -
                FilteredEast.map((s) =>
                  parseInt(monthlyReports[s][0]["ly_usd"])
                ).reduce((acc, sv) => acc + sv)) /
                FilteredEast.map((s) =>
                  parseInt(monthlyReports[s][0]["ly_usd"])
                ).reduce((acc, sv) => acc + sv)) *
                100
            )
          )
        );

        rows.push(createDataUsa("", "", "", "", "", "", "", ""));
        rows.push(createDataUsa("", "", "", "", "", "", "", ""));
      }

      /*
        let stores = Object.keys(monthlyReports);

        if(stores.length > 0)
        {
          rows = [...rows, ...stores.map((s, i) => createData( s, 
                                                               monthlyReports[s]['goal'], 
                                                               monthlyReports[s]['cad'], 
                                                               monthlyReports[s]['usd'],
                                                               monthlyReports[s]['differenceUsd'],
                                                               monthlyReports[s]['percentUsd'], 
                                                               monthlyReports[s]['lyUsd'], 
                                                               monthlyReports[s]['lyUsd'] > 0 ? 
                                                               monthlyReports[s]['tyLyPercentDiffUsd'] : 100))];

          rows.push(createData('','','','','','','',''));
          rows.push(createData('TOTAL',
                               stores.map(s => parseInt(monthlyReports[s]['goal'])).reduce((acc,sv) => acc+sv), 
                               stores.map(s => parseInt(monthlyReports[s]['cad'])).reduce((acc,sv) => acc+sv),
                               stores.map(s => parseInt(monthlyReports[s]['usd'])).reduce((acc,sv) => acc+sv),
                               stores.map(s => parseInt(monthlyReports[s]['differenceUsd'])).reduce((acc,sv) => acc+sv),
                               Math.round(((stores.map(s => parseInt(monthlyReports[s]['usd'])).reduce((acc,sv) => acc+sv) - stores.map(s => parseInt(monthlyReports[s]['goal'])).reduce((acc,sv) => acc+sv)) /  
                                            stores.map(s => parseInt(monthlyReports[s]['goal'])).reduce((acc,sv) => acc+sv))*100),
                               stores.map(s => parseInt(monthlyReports[s]['lyUsd'])).reduce((acc,sv) => acc+sv),
                               Math.round(((stores.map(s => parseInt(monthlyReports[s]['usd'])).reduce((acc,sv) => acc+sv) - stores.map(s => parseInt(monthlyReports[s]['lyUsd'])).reduce((acc,sv) => acc+sv)) /  
                                            stores.map(s => parseInt(monthlyReports[s]['lyUsd'])).reduce((acc,sv) => acc+sv))*100),
                              ));
        }
        */

      break;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      let sign = "";
                      let dollar = "";
                      let percent = "";
                      if (
                        (value && column.id === "Actual") ||
                        (value && column.id === "ActualU")
                      ) {
                        if (parseInt(row[column.id]) > parseInt(row["Goal"])) {
                          sign = (
                            <i
                              style={{ color: "green", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-up"
                            />
                          );
                        } else {
                          sign = (
                            <i
                              style={{ color: "red", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-down"
                            />
                          );
                        }
                      }

                      if (
                        (value && column.id === "Goal") ||
                        (value && column.id === "Actual") ||
                        (value && column.id === "ActualC") ||
                        (value && column.id === "ActualU") ||
                        (value && column.id === "Ly")
                      ) {
                        value = new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(value);
                      }

                      if (value && column.id === "DiffP") {
                        percent = "%";

                        if (parseInt(row["Goal"]) <= 0) {
                          value = 100;
                        }

                        if (parseInt(value) >= 0) {
                          sign = (
                            <i
                              style={{ color: "green", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-up"
                            />
                          );
                        } else {
                          sign = (
                            <i
                              style={{ color: "red", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-down"
                            />
                          );
                        }
                      }

                      if (value && column.id === "LyP") {
                        percent = "%";

                        if (parseInt(value) >= 0) {
                          sign = (
                            <i
                              style={{ color: "green", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-up"
                            />
                          );
                        } else {
                          sign = (
                            <i
                              style={{ color: "red", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-down"
                            />
                          );
                        }
                      }

                      if (value && column.id === "Diff") {
                        if (parseInt(row[column.id]) >= 0) {
                          dollar = "";
                        } else {
                          dollar = "-";
                        }

                        value = new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(value);
                      }

                      return (
                        <TableCell
                          style={{
                            color:
                              column.id === "Diff"
                                ? dollar === "-"
                                  ? "red"
                                  : "green"
                                : "black",
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {sign}
                          {column.format && typeof value === "number"
                            ? value
                            : value}
                          {percent}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
