//import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { IS_AUTH,
         SET_ECOMM_CA_BESTSELLERS,
         SET_ECOMM_USA_BESTSELLERS,
         SET_RETAIL_CA_BESTSELLER,
         SET_RETAIL_USA_BESTSELLER,
         SET_ECOMM_CA_HOMEBEAUTY,
         SET_ECOMM_USA_HOMEBEAUTY,
         SET_RETAIL_CA_HOMEBEAUTY,
         SET_RETAIL_USA_HOMEBEAUTY,
         SET_ECOMM_CA_SALESREPORT,
         SET_ECOMM_USA_SALESREPORT,
         SET_RETAIL_CA_SALESREPORT,
         SET_RETAIL_USA_SALESREPORT, 
         SET_USER, 
         SET_TOKEN,
         CUSTOM_SEARCH,
         CUSTOM_SEARCH_RESPONSE,
         CUSTOM_SEARCH_MESSAGE,
         CUSTOM_TABLE,
         USER_INBOX,
         SET_CAROUSEL_INDEX,
         EMAIL_SENT,
         LOGIN_PROMPT,
         OPEN_TIMER, 
         SEARCH_TAGS, 
         TIMERS,
         CUST_BEST_SELLER_COUNT,
         CUST_HOME_BEAUTY_COUNT,
         SET_ROW_BG_COLORS,
         SET_EMAIL_MESSAGE,
         SET_CUSTOM_BUILD_ECOMM,
         SET_CUSTOM_BUILD_RETAIL,
         SCHED_COLOR_MAP_RETAIL,
         SCHED_COLOR_MAP_ECOMM } from "../constants/action-types";



export const setCustomTable = param => ({type: CUSTOM_TABLE, payload: param});
export const setIsAuth = param => ({type: IS_AUTH, payload: param});
export const setUser = param => ({type: SET_USER, payload: param});
export const setToken = param => ({type: SET_TOKEN, payload: param});
export const setecommCaBestSellers = param => ({type: SET_ECOMM_CA_BESTSELLERS, payload: param});
export const setecommUsaBestSellers = param => ({type: SET_ECOMM_USA_BESTSELLERS, payload: param});
export const setretailCaBestSellers = param => ({type: SET_RETAIL_CA_BESTSELLER, payload: param});
export const setretailUsaBestSellers = param => ({type: SET_RETAIL_USA_BESTSELLER, payload: param});
export const setecommCaHomeBeauty = param => ({type: SET_ECOMM_CA_HOMEBEAUTY, payload: param});
export const setecommUsaHomeBeauty = param => ({type: SET_ECOMM_USA_HOMEBEAUTY, payload: param});
export const setretailCaHomeBeauty = param => ({type: SET_RETAIL_CA_HOMEBEAUTY, payload: param});
export const setretailUsaHomeBeauty = param => ({type: SET_RETAIL_USA_HOMEBEAUTY, payload: param});
export const setecommCaSalesReport = param => ({type: SET_ECOMM_CA_SALESREPORT, payload: param});
export const setecommUsaSalesReport = param => ({type: SET_ECOMM_USA_SALESREPORT, payload: param});
export const setretailCaSalesReport = param => ({type: SET_RETAIL_CA_SALESREPORT, payload: param});
export const setretailUsaSalesReport = param => ({type: SET_RETAIL_USA_SALESREPORT, payload: param});
export const setCustomSearch = param => ({type: CUSTOM_SEARCH, payload: param});
export const setCustomSearchResponse = param => ({type: CUSTOM_SEARCH_RESPONSE, payload: param});
export const setCustomSearchMessage = param => ({type: CUSTOM_SEARCH_MESSAGE, payload: param});
export const setUserInbox = param => ({type: USER_INBOX, payload: param});
export const setCarouselImage = param => ({type: SET_CAROUSEL_INDEX, payload: param});
export const setEmailSent = param => ({type: EMAIL_SENT, payload: param});
export const setLoginPrompt = param => ({type: LOGIN_PROMPT, payload: param});
export const setOpenTimer = param => ({type: OPEN_TIMER, payload: param});
export const setSearchTags = param => ({type: SEARCH_TAGS, payload: param});
export const setTimers = param => ({type: TIMERS, payload: param});
export const setBestSellerCount = param => ({type: CUST_BEST_SELLER_COUNT, payload: param});
export const setHomeBeautyCount = param => ({type: CUST_HOME_BEAUTY_COUNT, payload: param});
export const setRowBgColors = param => ({type: SET_ROW_BG_COLORS, payload: param});
export const setEmailMessage = param => ({type: SET_EMAIL_MESSAGE, payload: param});
export const setCustomBuildEcomm = param => ({type: SET_CUSTOM_BUILD_ECOMM, payload: param});
export const setCustomBuildRetail = param => ({type: SET_CUSTOM_BUILD_RETAIL, payload: param});
export const setSchedColorMapRetail = param => ({type: SCHED_COLOR_MAP_RETAIL, payload: param});
export const setSchedColorMapEcomm = param => ({type: SCHED_COLOR_MAP_ECOMM, payload: param});
