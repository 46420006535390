import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    display: "flex",
    justifyContent: "space-between",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const colCa = [
  { id: "d", label: "Date", minWidth: 120 },
  { id: "Store", label: "Store", minWidth: 120 },
  { id: "Goal", label: "Goal (CAD)", minWidth: 120 },
  { id: "Actual", label: "Actual (CAD)", minWidth: 120 },
  { id: "Diff", label: "Diff $", minWidth: 120 },
  { id: "DiffP", label: "Diff %", minWidth: 120 },
  { id: "Ly", label: "LY", minWidth: 120 },
  { id: "LyP", label: "LY Diff %", minWidth: 120 },
  { id: "prjC", label: "$ to PTD Goal (CAD)", minWidth: 120 },
];

const colUsa = [
  { id: "d", label: "Date", minWidth: 120 },
  { id: "Store", label: "Store", minWidth: 120 },
  { id: "Goal", label: "Goal (USD)", minWidth: 120 },
  { id: "ActualC", label: "Actual (CAD)", minWidth: 120 },
  { id: "ActualU", label: "Actual (USD)", minWidth: 120 },
  { id: "Diff", label: "Diff $ (USD)", minWidth: 120 },
  { id: "DiffP", label: "Diff % (USD)", minWidth: 120 },
  { id: "Ly", label: "LY (USD)", minWidth: 120 },
  { id: "LyP", label: "LY Diff % (USD)", minWidth: 120 },
  { id: "prjC", label: "$ to PTD Goal (USD)", minWidth: 120 },
];

function createData(...parameters) {
  let response;

  if (parameters.length > 9) {
    response = {
      d: `${parameters[9]}`,
      Store: parameters[0],
      Goal: parameters[1],
      ActualC: parameters[2],
      ActualU: parameters[3],
      Diff: parameters[4],
      DiffP: parameters[5],
      Ly: parameters[6],
      LyP: parameters[7],
      prjC: parameters[8],
    };
  } else {
    response = {
      d: `${parameters[8]}`,
      Store: parameters[0],
      Goal: parameters[1],
      Actual: parameters[2],
      Diff: parameters[3],
      DiffP: parameters[4],
      Ly: parameters[5],
      LyP: parameters[6],
      prjC: parameters[7],
    };
  }

  return response;
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableWrapper: {
    eight: "auto",
    overflow: "auto",
  },
});

export default function StickyHeadTable({
  dailyReports,
  periodReports,
  source,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(35);

  let rows = [],
    columns;
  let monthlyReports = periodReports;
  let dateNow = new Date();
  let days = dateNow.getDate();
  let lastDayOfMonth = new Date(
    dateNow.getFullYear(),
    dateNow.getMonth() + 1,
    0
  );
  let remainingDays = lastDayOfMonth.getDate() - days + 1;

  switch (source) {
    case "ca":
      columns = colCa;

      let bcStore = [
        "Richmond",
        "Metrotown",
        "Gastown",
        "Oakridge",
        "Granville Street",
        "Park Royal",
        "McArthur Glen Outlet",
        "Guildford Town Centre",
        "Coquitlam Centre",
      ];

      let abStore = ["Southgate", "Chinook", "West Edmonton Mall"];

      let onStore = [
        "Toronto Eaton Centre",
        "Yorkdale",
        "Markville",
        "Sherway Gardens",
        "Shops at Don Mills",
        "Queen Street",
        "Fairview Mall",
        "Vaughan Mills",
        "Square One",
        "Polo Park",
      ];

      let mbStore = ["Polo Park"];

      let filteredBC = bcStore.filter((bcs) => dailyReports[bcs]);

      if (filteredBC.length > 0) {
        rows = [
          ...rows,
          ...filteredBC.map((store, i) => {
            //console.log('daily table: ', dailyReports[store][0])

            return createData(
              store,
              dailyReports[store][0]["goal_cad"],
              dailyReports[store][0]["cad"],
              dailyReports[store][0]["difference_cad"],
              dailyReports[store][0]["difference_cad_percent"],
              dailyReports[store][0]["ly_cad"],
              dailyReports[store][0]["ly_cad"] > 0
                ? dailyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100,
              Math.round(
                Math.abs(
                  monthlyReports[store][0]["difference_cad"] / remainingDays
                )
              ),
              dailyReports[store][0]["trandate"]
            );
          }),
        ];

        rows.push(createData("", "", "", "", "", "", "", "", ""));

        rows.push(
          createData(
            "BC TOTAL",
            filteredBC
              .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredBC
              .map((s) => parseInt(dailyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredBC
              .map((s) => parseInt(dailyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredBC
                .map((s) => parseInt(dailyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredBC
                  .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredBC
                  .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            ),
            filteredBC
              .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv),
            isFinite(
              Math.round(
                ((filteredBC
                  .map((s) => parseInt(dailyReports[s][0]["cad"]))
                  .reduce((acc, sv) => acc + sv) -
                  filteredBC
                    .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                    .reduce((acc, sv) => acc + sv)) /
                  filteredBC
                    .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                    .reduce((acc, sv) => acc + sv)) *
                  100
              )
            )
              ? Math.round(
                  ((filteredBC
                    .map((s) => parseInt(dailyReports[s][0]["cad"]))
                    .reduce((acc, sv) => acc + sv) -
                    filteredBC
                      .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                      .reduce((acc, sv) => acc + sv)) /
                    filteredBC
                      .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                      .reduce((acc, sv) => acc + sv)) *
                    100
                )
              : 0,
            Math.round(
              Math.abs(
                filteredBC
                  .map((s) =>
                    parseInt(monthlyReports[s][0]["difference_cad"]) < 0
                      ? parseInt(monthlyReports[s][0]["difference_cad"])
                      : 0
                  )
                  .reduce((acc, sv) => acc + sv)
              ) / remainingDays
            ),
            ""
          )
        );

        rows.push(createData("", "", "", "", "", "", "", "", ""));
        rows.push(createData("", "", "", "", "", "", "", "", ""));
      }

      let filteredAB = abStore.filter((bcs) => dailyReports[bcs]);

      if (filteredAB.length > 0) {
        rows = [
          ...rows,
          ...filteredAB.map((store, i) => {
            return createData(
              store,
              dailyReports[store][0]["goal_cad"],
              dailyReports[store][0]["cad"],
              dailyReports[store][0]["difference_cad"],
              dailyReports[store][0]["difference_cad_percent"],
              dailyReports[store][0]["ly_cad"],
              dailyReports[store][0]["ly_cad"] > 0
                ? dailyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100,
              Math.round(
                Math.abs(
                  monthlyReports[store][0]["difference_cad"] / remainingDays
                )
              ),
              dailyReports[store][0]["trandate"]
            );
          }),
        ];

        rows.push(createData("", "", "", "", "", "", "", "", ""));

        rows.push(
          createData(
            "AB TOTAL",
            filteredAB
              .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredAB
              .map((s) => parseInt(dailyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredAB
              .map((s) => parseInt(dailyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredAB
                .map((s) => parseInt(dailyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredAB
                  .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredAB
                  .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            ),
            filteredAB
              .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv),
            isFinite(
              Math.round(
                ((filteredAB
                  .map((s) => parseInt(dailyReports[s][0]["cad"]))
                  .reduce((acc, sv) => acc + sv) -
                  filteredAB
                    .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                    .reduce((acc, sv) => acc + sv)) /
                  filteredAB
                    .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                    .reduce((acc, sv) => acc + sv)) *
                  100
              )
            )
              ? Math.round(
                  ((filteredAB
                    .map((s) => parseInt(dailyReports[s][0]["cad"]))
                    .reduce((acc, sv) => acc + sv) -
                    filteredAB
                      .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                      .reduce((acc, sv) => acc + sv)) /
                    filteredAB
                      .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                      .reduce((acc, sv) => acc + sv)) *
                    100
                )
              : 0,
            Math.round(
              Math.abs(
                filteredAB
                  .map((s) =>
                    parseInt(monthlyReports[s][0]["difference_cad"]) < 0
                      ? parseInt(monthlyReports[s][0]["difference_cad"])
                      : 0
                  )
                  .reduce((acc, sv) => acc + sv)
              ) / remainingDays
            ),
            ""
          )
        );

        rows.push(createData("", "", "", "", "", "", "", "", ""));
        rows.push(createData("", "", "", "", "", "", "", "", ""));
      }

      let filteredON = onStore.filter((bcs) => dailyReports[bcs]);

      if (filteredON.length > 0) {
        rows = [
          ...rows,
          ...filteredON.map((store, i) => {
            return createData(
              store,
              dailyReports[store][0]["goal_cad"],
              dailyReports[store][0]["cad"],
              dailyReports[store][0]["difference_cad"],
              dailyReports[store][0]["difference_cad_percent"],
              dailyReports[store][0]["ly_cad"],
              dailyReports[store][0]["ly_cad"] > 0
                ? dailyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100,
              Math.round(
                Math.abs(
                  monthlyReports[store][0]["difference_cad"] / remainingDays
                )
              ),
              dailyReports[store][0]["trandate"]
            );
          }),
        ];

        rows.push(createData("", "", "", "", "", "", "", "", ""));
        rows.push(
          createData(
            "ON TOTAL",
            filteredON
              .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredON
              .map((s) => parseInt(dailyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv),
            filteredON
              .map((s) => parseInt(dailyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv),
            Math.round(
              ((filteredON
                .map((s) => parseInt(dailyReports[s][0]["cad"]))
                .reduce((acc, sv) => acc + sv) -
                filteredON
                  .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) /
                filteredON
                  .map((s) => parseInt(dailyReports[s][0]["goal_cad"]))
                  .reduce((acc, sv) => acc + sv)) *
                100
            ),
            filteredON
              .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv),
            isFinite(
              Math.round(
                ((filteredON
                  .map((s) => parseInt(dailyReports[s][0]["cad"]))
                  .reduce((acc, sv) => acc + sv) -
                  filteredON
                    .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                    .reduce((acc, sv) => acc + sv)) /
                  filteredON
                    .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                    .reduce((acc, sv) => acc + sv)) *
                  100
              )
            )
              ? Math.round(
                  ((filteredON
                    .map((s) => parseInt(dailyReports[s][0]["cad"]))
                    .reduce((acc, sv) => acc + sv) -
                    filteredON
                      .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                      .reduce((acc, sv) => acc + sv)) /
                    filteredON
                      .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
                      .reduce((acc, sv) => acc + sv)) *
                    100
                )
              : 0,
            Math.round(
              Math.abs(
                filteredON
                  .map((s) =>
                    parseInt(monthlyReports[s][0]["difference_cad"]) < 0
                      ? parseInt(monthlyReports[s][0]["difference_cad"])
                      : 0
                  )
                  .reduce((acc, sv) => acc + sv)
              ) / remainingDays
            ),
            ""
          )
        );

        rows.push(createData("", "", "", "", "", "", "", "", ""));
        rows.push(createData("", "", "", "", "", "", "", "", ""));
      }

      let totalGoal =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(dailyReports[s][0]["goal_usd"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(dailyReports[s][0]["goal_usd"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(dailyReports[s][0]["goal_usd"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalCad =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(dailyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(dailyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(dailyReports[s][0]["cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalDiffCad =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(dailyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(dailyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(dailyReports[s][0]["difference_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalLyCad =
        (filteredBC.length > 0
          ? filteredBC
              .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredAB.length > 0
          ? filteredAB
              .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0) +
        (filteredON.length > 0
          ? filteredON
              .map((s) => parseInt(dailyReports[s][0]["ly_cad"]))
              .reduce((acc, sv) => acc + sv)
          : 0);

      let totalprjct = Math.round(
        (filteredBC.length > 0
          ? Math.round(
              Math.abs(
                filteredBC
                  .map((s) =>
                    parseInt(monthlyReports[s][0]["difference_cad"]) < 0
                      ? parseInt(monthlyReports[s][0]["difference_cad"])
                      : 0
                  )
                  .reduce((acc, sv) => acc + sv)
              ) / remainingDays
            )
          : 0) +
          (filteredAB.length > 0
            ? Math.round(
                Math.abs(
                  filteredAB
                    .map((s) =>
                      parseInt(monthlyReports[s][0]["difference_cad"]) < 0
                        ? parseInt(monthlyReports[s][0]["difference_cad"])
                        : 0
                    )
                    .reduce((acc, sv) => acc + sv)
                ) / remainingDays
              )
            : 0) +
          (filteredON.length > 0
            ? Math.round(
                Math.abs(
                  filteredON
                    .map((s) =>
                      parseInt(monthlyReports[s][0]["difference_cad"]) < 0
                        ? parseInt(monthlyReports[s][0]["difference_cad"])
                        : 0
                    )
                    .reduce((acc, sv) => acc + sv)
                ) / remainingDays
              )
            : 0)
      );

      let totalDiffP = Math.round(((totalCad - totalGoal) / totalGoal) * 100);

      let totalLyTyDiffP = Math.round(
        ((totalCad - totalLyCad) / totalLyCad) * 100
      );

      rows.push(
        createData(
          "TOTAL",
          totalGoal,
          totalCad,
          totalDiffCad,
          totalDiffP,
          totalLyCad,
          isFinite(totalLyTyDiffP) ? totalLyTyDiffP : 0,
          totalprjct,
          ""
        )
      );

      break;

    case "usa":
      columns = colUsa;

      let west = [
        "Hayes Street",
        "Century City",
        "Valley Fair",
        "Soma",
        "Bellevue Square",
      ];

      let east = ["Soho", "Wicker Park", "Roosevelt", "Prudential Oak Refined"];

      let FilteredWest = west.filter((ws) => dailyReports[ws]);

      if (FilteredWest.length > 0) {
        rows = [
          ...rows,
          ...FilteredWest.map((store, i) => {
            return createData(
              store,
              dailyReports[store][0]["goal_usd"],
              dailyReports[store][0]["cad"],
              dailyReports[store][0]["usd"],
              dailyReports[store][0]["difference_usd"],
              dailyReports[store][0]["difference_usd_percent"],
              dailyReports[store][0]["ly_usd"],
              dailyReports[store][0]["ly_usd"] > 0
                ? dailyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100,
              Math.round(
                Math.abs(
                  monthlyReports[store][0]["difference_usd"] / remainingDays
                )
              ),
              dailyReports[store][0]["trandate"]
            );
          }),
        ];

        rows.push(createData("", "", "", "", "", "", "", "", "", ""));

        rows.push(
          createData(
            "WEST COAST TOTAL",
            FilteredWest.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["goal_usd"]) : 0
            ).reduce((acc, sv) => acc + sv),
            FilteredWest.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["cad"]) : 0
            ).reduce((acc, sv) => acc + sv),
            FilteredWest.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
            ).reduce((acc, sv) => acc + sv),
            FilteredWest.map((s) =>
              dailyReports[s]
                ? parseInt(dailyReports[s][0]["difference_usd"])
                : 0
            ).reduce((acc, sv) => acc + sv),
            Math.round(
              ((FilteredWest.map((s) =>
                dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
              ).reduce((acc, sv) => acc + sv) -
                FilteredWest.map((s) =>
                  dailyReports[s] ? parseInt(dailyReports[s][0]["goal_usd"]) : 0
                ).reduce((acc, sv) => acc + sv)) /
                FilteredWest.map((s) =>
                  dailyReports[s] ? parseInt(dailyReports[s][0]["goal_usd"]) : 0
                ).reduce((acc, sv) => acc + sv)) *
                100
            ),
            FilteredWest.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["ly_usd"]) : 0
            ).reduce((acc, sv) => acc + sv),
            isFinite(
              Math.round(
                ((FilteredWest.map((s) =>
                  dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
                ).reduce((acc, sv) => acc + sv) -
                  FilteredWest.map((s) =>
                    dailyReports[s] ? parseInt(dailyReports[s][0]["ly_usd"]) : 0
                  ).reduce((acc, sv) => acc + sv)) /
                  FilteredWest.map((s) =>
                    dailyReports[s] ? parseInt(dailyReports[s][0]["ly_usd"]) : 0
                  ).reduce((acc, sv) => acc + sv)) *
                  100
              )
            )
              ? Math.round(
                  ((FilteredWest.map((s) =>
                    dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
                  ).reduce((acc, sv) => acc + sv) -
                    FilteredWest.map((s) =>
                      dailyReports[s]
                        ? parseInt(dailyReports[s][0]["ly_usd"])
                        : 0
                    ).reduce((acc, sv) => acc + sv)) /
                    FilteredWest.map((s) =>
                      dailyReports[s]
                        ? parseInt(dailyReports[s][0]["ly_usd"])
                        : 0
                    ).reduce((acc, sv) => acc + sv)) *
                    100
                )
              : 0,
            Math.round(
              Math.abs(
                FilteredWest.map((s) =>
                  monthlyReports[s]
                    ? parseInt(monthlyReports[s]["difference_usd"]) < 0
                      ? parseInt(monthlyReports[s]["difference_usd"])
                      : 0
                    : 0
                ).reduce((acc, sv) => acc + sv)
              ) / remainingDays
            ),
            ""
          )
        );

        rows.push(createData("", "", "", "", "", "", "", "", "", ""));
        rows.push(createData("", "", "", "", "", "", "", "", "", ""));
      }

      let FilteredEast = east.filter((es) => dailyReports[es]);

      if (FilteredEast.length > 0) {
        rows = [
          ...rows,
          ...FilteredEast.map((store, i) => {
            return createData(
              store,
              dailyReports[store][0]["goal_usd"],
              dailyReports[store][0]["cad"],
              dailyReports[store][0]["usd"],
              dailyReports[store][0]["difference_usd"],
              dailyReports[store][0]["difference_usd_percent"],
              dailyReports[store][0]["ly_usd"],
              dailyReports[store][0]["ly_usd"] > 0
                ? dailyReports[store][0]["ty_ly_cad_diff_percent"]
                : 100,
              Math.round(
                Math.abs(
                  monthlyReports[store][0]["difference_usd"] / remainingDays
                )
              ),
              dailyReports[store][0]["trandate"]
            );
          }),
        ];

        rows.push(createData("", "", "", "", "", "", "", "", "", ""));

        rows.push(
          createData(
            "EAST COAST TOTAL",
            FilteredEast.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["goal_usd"]) : 0
            ).reduce((acc, sv) => acc + sv),
            FilteredEast.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["cad"]) : 0
            ).reduce((acc, sv) => acc + sv),
            FilteredEast.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
            ).reduce((acc, sv) => acc + sv),
            FilteredEast.map((s) =>
              dailyReports[s]
                ? parseInt(dailyReports[s][0]["difference_usd"])
                : 0
            ).reduce((acc, sv) => acc + sv),
            Math.round(
              ((FilteredEast.map((s) =>
                dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
              ).reduce((acc, sv) => acc + sv) -
                FilteredEast.map((s) =>
                  dailyReports[s] ? parseInt(dailyReports[s][0]["goal_usd"]) : 0
                ).reduce((acc, sv) => acc + sv)) /
                FilteredEast.map((s) =>
                  dailyReports[s] ? parseInt(dailyReports[s][0]["goal_usd"]) : 0
                ).reduce((acc, sv) => acc + sv)) *
                100
            ),
            FilteredEast.map((s) =>
              dailyReports[s] ? parseInt(dailyReports[s][0]["ly_usd"]) : 0
            ).reduce((acc, sv) => acc + sv),
            isFinite(
              Math.round(
                ((FilteredEast.map((s) =>
                  dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
                ).reduce((acc, sv) => acc + sv) -
                  FilteredEast.map((s) =>
                    dailyReports[s] ? parseInt(dailyReports[s][0]["ly_usd"]) : 0
                  ).reduce((acc, sv) => acc + sv)) /
                  FilteredEast.map((s) =>
                    dailyReports[s] ? parseInt(dailyReports[s][0]["ly_usd"]) : 0
                  ).reduce((acc, sv) => acc + sv)) *
                  100
              )
            )
              ? Math.round(
                  ((FilteredEast.map((s) =>
                    dailyReports[s] ? parseInt(dailyReports[s][0]["usd"]) : 0
                  ).reduce((acc, sv) => acc + sv) -
                    FilteredEast.map((s) =>
                      dailyReports[s]
                        ? parseInt(dailyReports[s][0]["ly_usd"])
                        : 0
                    ).reduce((acc, sv) => acc + sv)) /
                    FilteredEast.map((s) =>
                      dailyReports[s]
                        ? parseInt(dailyReports[s][0]["ly_usd"])
                        : 0
                    ).reduce((acc, sv) => acc + sv)) *
                    100
                )
              : 0,
            Math.round(
              Math.abs(
                FilteredEast.map((s) =>
                  monthlyReports[s]
                    ? parseInt(monthlyReports[s]["difference_usd"]) < 0
                      ? parseInt(monthlyReports[s]["difference_usd"])
                      : 0
                    : 0
                ).reduce((acc, sv) => acc + sv)
              ) / remainingDays
            ),
            ""
          )
        );

        rows.push(createData("", "", "", "", "", "", "", "", "", ""));
        rows.push(createData("", "", "", "", "", "", "", "", "", ""));
      }

      let totalGoalUsa =
        (FilteredWest.length > 0
          ? FilteredWest.map((s) =>
              parseInt(dailyReports[s][0]["goal_usd"])
            ).reduce((acc, sv) => acc + sv)
          : 0) +
        (FilteredEast.length > 0
          ? FilteredEast.map((s) =>
              parseInt(dailyReports[s][0]["goal_usd"])
            ).reduce((acc, sv) => acc + sv)
          : 0);

      let totalCadUsa =
        (FilteredWest.length > 0
          ? FilteredWest.map((s) => parseInt(dailyReports[s][0]["cad"])).reduce(
              (acc, sv) => acc + sv
            )
          : 0) +
        (FilteredEast.length > 0
          ? FilteredEast.map((s) => parseInt(dailyReports[s][0]["cad"])).reduce(
              (acc, sv) => acc + sv
            )
          : 0);

      let totalUsdUsa =
        (FilteredWest.length > 0
          ? FilteredWest.map((s) => parseInt(dailyReports[s][0]["usd"])).reduce(
              (acc, sv) => acc + sv
            )
          : 0) +
        (FilteredEast.length > 0
          ? FilteredEast.map((s) => parseInt(dailyReports[s][0]["usd"])).reduce(
              (acc, sv) => acc + sv
            )
          : 0);

      let totalDiffUsdUsa =
        (FilteredWest.length > 0
          ? FilteredWest.map((s) =>
              parseInt(dailyReports[s][0]["difference_usd"])
            ).reduce((acc, sv) => acc + sv)
          : 0) +
        (FilteredEast.length > 0
          ? FilteredEast.map((s) =>
              parseInt(dailyReports[s][0]["difference_usd"])
            ).reduce((acc, sv) => acc + sv)
          : 0);

      let totalLyUsdUsa =
        (FilteredWest.length > 0
          ? FilteredWest.map((s) =>
              parseInt(dailyReports[s][0]["ly_usd"])
            ).reduce((acc, sv) => acc + sv)
          : 0) +
        (FilteredEast.length > 0
          ? FilteredEast.map((s) =>
              parseInt(dailyReports[s][0]["ly_usd"])
            ).reduce((acc, sv) => acc + sv)
          : 0);

      let totalprjctUsa = Math.round(
        (FilteredWest.length > 0
          ? Math.round(
              Math.abs(
                FilteredWest.map((s) =>
                  dailyReports[s]
                    ? parseInt(monthlyReports[s][0]["difference_usd"]) < 0
                      ? parseInt(monthlyReports[s][0]["difference_usd"])
                      : 0
                    : 0
                ).reduce((acc, sv) => acc + sv)
              ) / remainingDays
            )
          : 0) +
          (FilteredEast.length > 0
            ? Math.round(
                Math.abs(
                  FilteredEast.map((s) =>
                    dailyReports[s]
                      ? parseInt(monthlyReports[s][0]["difference_usd"]) < 0
                        ? parseInt(monthlyReports[s][0]["difference_usd"])
                        : 0
                      : 0
                  ).reduce((acc, sv) => acc + sv)
                ) / remainingDays
              )
            : 0)
      );

      let totalDiffPUsa = Math.round(
        ((totalUsdUsa - totalGoalUsa) / totalGoalUsa) * 100
      );

      let totalLyTyDiffPUsa = Math.round(
        ((totalUsdUsa - totalLyUsdUsa) / totalLyUsdUsa) * 100
      );

      rows.push(
        createData(
          "TOTAL",
          totalGoalUsa,
          totalCadUsa,
          totalUsdUsa,
          totalDiffUsdUsa,
          totalDiffPUsa,
          totalLyUsdUsa,
          isFinite(totalLyTyDiffPUsa) ? totalLyTyDiffPUsa : 0,
          totalprjctUsa,
          ""
        )
      );

      /*
        let stores = Object.keys(dailyReports);

        if(stores.length > 0)
        {
          rows = [...rows, ...stores.map((s, i) => createData( s, 
                                                               dailyReports[s][0]['goal'], 
                                                               dailyReports[s][0]['cad'], 
                                                               dailyReports[s][0]['usd'],
                                                               dailyReports[s][0]['differenceUsd'],
                                                               dailyReports[s][0]['percentUsd'], 
                                                               dailyReports[s][0]['lyUsd'], 
                                                               dailyReports[s][0]['lyUsd'] > 0 ? 
                                                               dailyReports[s][0]['tyLyPercentDiffUsd'] : 100,
                                                               monthlyReports[s]['differenceCad'] < 0 ? 
                                                               Math.round(Math.abs(monthlyReports[s]['differenceCad'] / remainingDays )) : 0))];

          rows.push(createData('','','','','','','',''));
          rows.push(createData('TOTAL',
                               stores.map(s => parseInt(dailyReports[s][0]['goal'])).reduce((acc,sv) => acc+sv), 
                               stores.map(s => parseInt(dailyReports[s][0]['cad'])).reduce((acc,sv) => acc+sv),
                               stores.map(s => parseInt(dailyReports[s][0]['usd'])).reduce((acc,sv) => acc+sv),
                               stores.map(s => parseInt(dailyReports[s][0]['differenceUsd'])).reduce((acc,sv) => acc+sv),
                               Math.round(((stores.map(s => parseInt(dailyReports[s][0]['usd'])).reduce((acc,sv) => acc+sv) - stores.map(s => parseInt(dailyReports[s][0]['goal'])).reduce((acc,sv) => acc+sv)) /  
                                            stores.map(s => parseInt(dailyReports[s][0]['goal'])).reduce((acc,sv) => acc+sv))*100),
                               stores.map(s => parseInt(dailyReports[s][0]['lyUsd'])).reduce((acc,sv) => acc+sv),
                               Math.round(((stores.map(s => parseInt(dailyReports[s][0]['usd'])).reduce((acc,sv) => acc+sv) - stores.map(s => parseInt(dailyReports[s][0]['lyUsd'])).reduce((acc,sv) => acc+sv)) /  
                                            stores.map(s => parseInt(dailyReports[s][0]['lyUsd'])).reduce((acc,sv) => acc+sv))*100),
                               Math.round(Math.abs(stores.map(s => parseInt(monthlyReports[s]['differenceCad']) < 0 ? parseInt(monthlyReports[s]['differenceCad']) : 0).reduce((acc,sv) => acc+sv))/remainingDays)
                              ));
        }
        */

      break;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      let sign = "";
                      let dollar = "";
                      let percent = "";
                      if (
                        (value && column.id === "Actual") ||
                        (value && column.id === "ActualU")
                      ) {
                        if (parseInt(row[column.id]) > parseInt(row["Goal"])) {
                          sign = (
                            <i
                              style={{ color: "green", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-up"
                            />
                          );
                        } else {
                          sign = (
                            <i
                              style={{ color: "red", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-down"
                            />
                          );
                        }
                      }

                      if (
                        (value && column.id === "Goal") ||
                        (value && column.id === "Actual") ||
                        (value && column.id === "ActualC") ||
                        (value && column.id === "ActualU") ||
                        (value && column.id === "Ly")
                      ) {
                        value = new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(value);
                      }

                      if (value && column.id === "DiffP") {
                        percent = "%";

                        if (parseInt(row["Goal"]) <= 0) {
                          value = 100;
                        }

                        if (parseInt(value) >= 0) {
                          sign = (
                            <i
                              style={{ color: "green", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-up"
                            />
                          );
                        } else {
                          sign = (
                            <i
                              style={{ color: "red", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-down"
                            />
                          );
                        }
                      }

                      if (value && column.id === "LyP") {
                        percent = "%";

                        if (parseInt(row[column.id]) >= 0) {
                          sign = (
                            <i
                              style={{ color: "green", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-up"
                            />
                          );
                        } else {
                          sign = (
                            <i
                              style={{ color: "red", marginRight: "12px" }}
                              className="fas fa-2x fa-caret-down"
                            />
                          );
                        }
                      }

                      if (
                        (value && column.id === "Diff") ||
                        (value && column.id === "prjC")
                      ) {
                        if (parseInt(row[column.id]) >= 0) {
                          dollar = "";
                        } else {
                          //value = value.toString().replace("-","");
                          dollar = "-";
                        }

                        value = new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(value);
                      }

                      return (
                        <TableCell
                          style={{
                            color:
                              column.id === "Diff"
                                ? dollar === "-"
                                  ? "red"
                                  : "green"
                                : "black",
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {sign}
                          {column.format && typeof value === "number"
                            ? value
                            : value}
                          {percent}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
