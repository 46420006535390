import { combineReducers } from 'redux'
import main from './main';


let rootReducer = combineReducers({
  main
})


export default rootReducer;
