import React, { useEffect, useState } from "react"
import { Redirect } from 'react-router'
import { connect } from 'react-redux';
import Styled from "styled-components"

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment";



const PageContainer = Styled.div`
    border: 1px solid blue;
    display: flex;
    flex-flow: column nowrap;
    align-items: ${props => props.align};
    width: 100%;
    height: 100%;
    background-color: ${props => props.bgColor};
    padding-top: 6%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;


`;


const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "rgb(52,58,64,1)",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
        },
    },
    }))(TableRow);


function createData({uniqid, sent, sender, subject}) {
    return { 
        uniqid,
        sent: moment(sent).format('MM/DD/YYYY'),
        sender,
        subject
    };
}


const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    tableWrapper: {
      maxHeight: 440,
      overflow: 'auto',
    },
  });




const mapStateToProps = (state) => {
    return {
      isAuth: state.main.is_auth,
      inbox: state.main.user_inbox
    };
  };



const columns = [
    { id: 'uniqid', label: '', minWidth: 100 },
    { id: 'sent', label: 'Sent', minWidth: 20 },
    { id: 'sender', label: 'From', minWidth: 100 },
    { id: 'subject', label: 'Subject', minWidth: 100 },
    { id: 'filler1', label: '', minWidth: 100 },
    { id: 'filler2', label: '', minWidth: 100 },
    { id: 'action', label: '', minWidth: 200, align: 'right' },
  ];



const Inbox = ({ inbox }) => {

        const classes = useStyles();
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);
        const [rows, setRows] = React.useState([]);
        const [modal, setModal] = useState(false);
        const [inboxData, setInboxData] = useState({});


        const toggle = (uniqid) => {

            let imgDisplay = [];
            let tableDisplay = [];
        
            if(!modal)
            {
                alert(uniqid)
                /*
                if(Object.keys(customSearchResponse['best_sellers']).length > 0)
                {
                  let key = 0;
                  for(let set in customSearchResponse['best_sellers'])
                  {
                    positions['best_sellers'].push({[set]: key});
                    imgDisplay.push(
                      <React.Fragment>
                        <h2 style={{marginTop: '25px', paddingLeft: '30px'}}>{set}</h2>
                        <SingleContainer1 key={key++}>
                          <ImageDisplay bestSellers={customSearchResponse['best_sellers'][set]} />
                        </SingleContainer1>
                      </React.Fragment>
                    );
                  }
                }
          
                if(Object.keys(customSearchResponse['home_and_beauty']).length > 0)
                {
                  let key = 0;
                  for(let set in customSearchResponse['home_and_beauty'])
                  {
                    positions['home_and_beauty'].push({[set]: key});
                    imgDisplay.push(
                      <React.Fragment>
                        <h2 style={{marginTop: '25px', paddingLeft: '30px'}}>{set}</h2>
                        <SingleContainer1 key={key++}>
                          <ImageDisplay bestSellers={customSearchResponse['home_and_beauty'][set]} />
                        </SingleContainer1>
                      </React.Fragment>
                    );
                  }
                }
          
                if(Object.keys(customSearchResponse['sales_reports']).length > 0)
                {
                  let key = 0;
                  for(let set in customSearchResponse['sales_reports'])
                  {
                   positions['sales_reports'].push({[set]: key});
                   tableDisplay.push(
                      <React.Fragment>
                        <h2 style={{marginTop: '25px', paddingLeft: '30px'}}>{set}</h2>
                        <Table key={key++} reports={customSearchResponse['sales_reports'][set]}/>
                      </React.Fragment>
                    );
                  }
                }
                */
            }
            

            setModal(!modal)
        };


        const handleChangePage = (event, newPage) => {
            setPage(newPage);
          };
        
        const handleChangeRowsPerPage = event => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          };
  


        useEffect(() => {
       

            let display = [];
            let inboxData = {};

            for(let report in inbox)
            {
                inboxData[report.uniqid] = inbox[report];
                display.push(createData(inbox[report]))
            }

            setRows(display)
            setInboxData(inboxData);
        },[inbox])

        let display

        
        if(localStorage.getItem('auth'))
        {
  
            display = <PageContainer>
                          <Modal isOpen={modal} toggle={toggle} >
                                <ModalHeader toggle={toggle}>Reports</ModalHeader>
                                <ModalBody>
                                
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggle}>Close</Button>
                                </ModalFooter>
                          </Modal>
                          <Paper className={classes.root}>
                            <div className={classes.tableWrapper}>
                                <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                    {columns.map(column => (
                                        <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        >
                                        {column.label}
                                        </StyledTableCell>
                                    ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map(column => {
                                            let value = row[column.id];

                                            if(column.id == "action")
                                            {
                                                return <TableCell key={column.id} align={column.align}>
                                                          <div style={{width: '250px', display: 'flex', justifyContent: 'space-between'}}>
                                                            <Button onClick={() => toggle(row['uniqid'])} style={{width: '100px'}} outline color="success">open</Button>
                                                            <Button style={{width: '100px'}} outline color="danger">delete</Button>
                                                          </div>
                                                        </TableCell> 
                                                    
                                            }

                                            if(column.id == "uniqid")
                                            {
                                                value = '';
                                            }

                                            return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                            );
                                        })}
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                'aria-label': 'previous page',
                                }}
                                nextIconButtonProps={{
                                'aria-label': 'next page',
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                          </Paper>
                      </PageContainer>
        }
        else
        {
            return <Redirect to='/' />
        }

   

  return display;
}

export default connect(mapStateToProps, null)(Inbox);

