import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import { CSVLink, CSVDownload } from "react-csv";




const mapStateToProps = (state) => {
  return {
    bgColors: state.main.set_row_bg_colors,
  };
};



const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const col = [
  { id: 'Store', label: 'Store', minWidth: 120 },
  { id: 'Goal', label: 'Goal CAD', minWidth: 120 },
  { id: 'Actual', label: 'Actual CAD', minWidth: 160 },
  { id: 'Diff', label: 'Diff $ CAD', minWidth: 120 },
  { id: 'DiffP', label: 'Diff % CAD', minWidth: 120 },
  { id: 'Ly', label: 'LY CAD', minWidth: 120 },
  { id: 'LyP', label: 'LY Diff % CAD', minWidth: 120 },
  { id: 'SecGoal', label: 'Goal USD', minWidth: 120 },
  { id: 'ActualUsd', label: 'Actual USD', minWidth: 160 },
  { id: 'DiffU', label: 'Diff $ USD', minWidth: 120 },
  { id: 'DiffPU', label: 'Diff % USD', minWidth: 120 },
  { id: 'LyU', label: 'LY USD', minWidth: 120 },
  { id: 'LyPU', label: 'LY Diff % USD', minWidth: 120 },
];

const item_cost_col = [
  { id: 'date', label: 'Date', minWidth: 120 },
  { id: 'location', label: 'Location', minWidth: 120 },
  { id: 'qty_sold', label: 'Qty Sold', minWidth: 120 },
  { id: 'cad_goal', label: 'Goal CAD', minWidth: 120 },
  { id: 'usd_goal', label: 'Goal USD', minWidth: 160 },
  { id: 'cad_total', label: 'Total CAD', minWidth: 120 },
  { id: 'usd_total', label: 'Total USD', minWidth: 160 },
  { id: 'landed_cost', label: 'Landed Cost', minWidth: 120 },
  { id: 'ly_date', label: 'LY Date', minWidth: 120 },
  { id: 'ly_qty_sold', label: 'LY Qty Sold', minWidth: 120 },
  { id: 'ly_cad_goal', label: 'LY Goal CAD', minWidth: 120 },
  { id: 'ly_usd_goal', label: 'LY Goal USD', minWidth: 160 },
  { id: 'ly_cad_total', label: 'LY Total CAD', minWidth: 120 },
  { id: 'ly_usd_total', label: 'LY Total USD', minWidth: 160 },
  { id: 'ly_landed_cost', label: 'LY Landed Cost', minWidth: 120 },
  { id: 'gross_profit_cad', label: 'Gross Profit CAD', minWidth: 120 },
  { id: 'avg_cost_per_item_cad', label: 'AVG Cost Per Item CAD', minWidth: 120 },
  { id: 'avg_price_per_item_cad', label: 'AVG Price Per Item CAD', minWidth: 120 },
  { id: 'ly_gross_profit_cad', label: 'LY Gross Profit CAD', minWidth: 120 },
  { id: 'percent_from_landed_cad', label: 'Diff From Landed CAD', minWidth: 120 },
  { id: 'ly_percent_from_landed_cad', label: 'LY Diff From Landed CAD', minWidth: 120 },
  { id: 'difference_cad_percent', label: 'Diff From Goal CAD', minWidth: 160 },
  { id: 'difference_usd_percent', label: 'Diff From Goal USD', minWidth: 120 },
  { id: 'ty_ly_cad_diff_percent', label: 'TY LY Diff CAD', minWidth: 120 },
  { id: 'ty_ly_usd_diff_percent', label: 'TY LY Diff USD', minWidth: 120 },
];





function createData({parameters, section}) {
  
  let response;

 

  if(section== 'item_cost_search')
  {

    response = {  date: parameters[0], 
                  location: parameters[1], 
                  qty_sold: parameters[2], 
                  cad_goal: parameters[3],
                  usd_goal: parameters[4], 
                  cad_total: parameters[5],
                  usd_total: parameters[6], 
                  landed_cost: parameters[7],
                  ly_date: parameters[8],
                  ly_qty_sold: parameters[9],
                  ly_cad_goal: parameters[10],
                  ly_usd_goal: parameters[11], 
                  ly_cad_total: parameters[12],
                  ly_usd_total: parameters[13], 
                  ly_landed_cost: parameters[14],
                  gross_profit_cad: parameters[15],
                  avg_cost_per_item_cad: parameters[16],
                  avg_price_per_item_cad: parameters[17],
                  ly_gross_profit_cad: parameters[18],
                  percent_from_landed_cad: parameters[19],
                  ly_percent_from_landed_cad: parameters[20],
                  difference_cad_percent: parameters[21],
                  difference_usd_percent: parameters[22],
                  ty_ly_cad_diff_percent: parameters[23],
                  ty_ly_usd_diff_percent: parameters[24] };

    
  }
  else
  {
    response = { Store: parameters[0], 
                  Goal: parameters[1], 
                  Actual: parameters[2],
                  ActualUsd: parameters[3],
                  Diff: parameters[4],
                  DiffU: parameters[5],  
                  DiffP: parameters[6],
                  DiffPU: parameters[7],
                  Ly: parameters[8], 
                  LyU: parameters[9],
                  LyPU: parameters[10], 
                  LyP: parameters[11],
                  SecGoal: parameters[12] };
  }
    

  return response;
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    eight: 'auto',
    overflow: 'auto',
  },
});

function StickyHeadTable({ reports, source, bgColors, set, schedColorMapEcomm, schedColorMapRetail, customBuild, section }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const colors = customBuild && set === 'ecomm' ? schedColorMapEcomm :  
                 customBuild && set === 'retail' ? schedColorMapRetail : bgColors;

 // console.log("reports: ", reports)
 // console.log("colors: ", colors);
  //console.log("bgColors: ", bgColors);

  let rows = [], columns; 

  if(section && section == "item_cost_search")
  {
    columns = item_cost_col;
    rows = [...rows, ...reports.map((reps, i) => {
  
      return createData( {parameters: [reps['date'], 
                                      reps['location'], 
                                      reps['qty_sold'], 
                                      reps['goal_cad'], 
                                      reps['goal_cad'],
                                      reps['cad'], 
                                      reps['usd'],
                                      reps['landed_cost'],
                                      reps['ly_date'],  
                                      reps['ly_qty_sold'],
                                      reps['ly_goal_cad'],
                                      reps['ly_goal_usd'],
                                      reps['ly_cad'], 
                                      reps['ly_usd'],
                                      reps['ly_landed_cost'],
                                      reps['gross_profit_cad'],
                                      reps['avg_cost_per_item_cad'],
                                      reps['avg_price_per_item_cad'],
                                      reps['ly_gross_profit_cad'],
                                      reps['percent_from_landed_cad'],
                                      reps['ly_percent_from_landed_cad'],
                                      reps['difference_cad_percent'],
                                      reps['difference_usd_percent'],
                                      reps['ty_ly_cad_diff_percent'],
                                      reps['ty_ly_usd_diff_percent']], 
                         section: 'item_cost_search'} )
  
    })];

  }
  else
  {
    columns = col;
    rows = [...rows, ...reports.map((reps, i) => {
  
      return createData( {parameters: [reps['locationName'] === "WH Burbidge" ? "Ecom Canada" : reps['locationName'], 
                         reps['goal_cad'], 
                         reps['cad'],
                         reps['usd'], 
                         reps['difference_cad'],
                         reps['difference_usd'], 
                         reps['difference_cad_percent'],
                         reps['difference_usd_percent'], 
                         reps['ly_cad'],
                         reps['ly_usd'], 
                         reps['ly_cad'] > 0 ? 
                         reps['ty_ly_cad_diff_percent'] : 100,
                         reps['ly_usd'] > 0 ? 
                         reps['ty_ly_usd_diff_percent'] : 100,
                         reps['goal_usd']] })
  
    })];
  }


  
  //columns = reports[0].link === "usa" ? col2 : col;
 


  let storeMap = {}
  let linkMap = {}

  for(let s of reports)
  {
    storeMap[s['locationName']] = s.tag
  }

  for(let s of reports)
  {
    linkMap[s['locationName']] = s.link
  }
  
  
  let flag;

  let csv_data = reports;
  if (section == "item_cost_search")
  {
    csv_data = reports.map(d => { 
   
      let new_data = {
        date: d['date'],
        pvp_location: d['pvp_location'],
        location: d['location'],
        qty_sold: d['qty_sold'],
        goal_cad: d['goal_cad'],
        goal_usd: d['goal_usd'],
        total_cad: d['cad'],
        total_usd: d['usd'],
        landed_cost: d['landed_cost'],
        ly_date: d['ly_date'],
        ly_qty_sold: d['ly_qty_sold'],
        ly_goal_cad: d['ly_goal_cad'],
        ly_goal_usd: d['ly_goal_usd'],
        ly_cad: d['ly_cad'],
        ly_usd: d['ly_usd'],
        ly_landed_cost: d['ly_landed_cost'],
        gross_profit_cad: d['gross_profit_cad'],
        avg_cost_per_item_cad: d['avg_cost_per_item_cad'],
        avg_price_per_item_cad: d['avg_price_per_item_cad'],
        ly_gross_profit_cad: d['ly_gross_profit_cad'],
        percent_from_landed_cad: d['percent_from_landed_cad'],
        ly_percent_from_landed_cad: d['ly_percent_from_landed_cad'],
        difference_cad_percent: d['difference_cad_percent'],
        difference_usd_percent: d['difference_usd_percent'],
        ty_ly_cad_diff_percent: d['ty_ly_cad_diff_percent'],
        ty_ly_usd_diff_percent: d['ty_ly_usd_diff_percent'] 
      }
      
      return new_data; 
    })
  }

  

  return (
    <Paper className={classes.root}>
      <CSVLink data={csv_data}  filename={"custom_search.csv"}>Download CSV</CSVLink>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              //console.log("row is custom search: ", row)
              flag = linkMap[row['Store']]
              return (
                <TableRow hover style={{backgroundColor: colors[storeMap[row['Store']]]}} role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(column => {
                    let value = row[column.id];
                    let sign = '';
                    let dollar = '';
                    let percent = '';
                    if( value && column.id === "Actual" || 
                        value && column.id === "ActualUsd" )
                    {
                        
                      //if(column.id === "Actual" && flag === "ca")
                      //{
                        if(column.id === "Actual" && parseInt(row[column.id]) > parseInt(row['Goal']))
                        {
                          sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                        }
                        else
                        {
                          sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                        }
                      ///}
                      //else if(column.id === "ActualUsd" && flag === "usa")
                      //{
                        if(column.id === "ActualUsd" && parseInt(row[column.id]) > parseInt(row['SecGoal']))
                        {
                          sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                        }
                        else
                        {
                          sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                        }
                      //}
                      
                    }

                    if( value && column.id === "Goal" || 
                        value && column.id === "SecGoal" || 
                        value && column.id === "Actual" ||
                        value && column.id === "ActualUsd" ||
                        value && column.id === "Ly" || 
                        value && column.id === "cad_total" ||
                        value && column.id === "usd_total" || 
                        value && column.id === "ly_cad_total" ||
                        value && column.id === "ly_usd_total" || 
                        value && column.id === "cad_goal" || 
                        value && column.id === "usd_goal" || 
                        value && column.id === "ly_cad_goal" || 
                        value && column.id === "ly_usd_goal" || 
                        value && column.id === "landed_cost" || 
                        value && column.id === "ly_landed_cost" ||
                        value && column.id === "gross_profit_cad" || 
                        value && column.id === "ly_gross_profit_cad" || 
                        value && column.id === "avg_cost_per_item_cad" || 
                        value && column.id === "avg_price_per_item_cad" || 
                        value && column.id === "LyU" )
                    {
                      value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                    }

                    if( value && column.id === "DiffP" || 
                        value && column.id === "DiffPU" ||
                        value && column.id === "LyP" || 
                        value && column.id === "LyPU" ||
                        value && column.id === "percent_from_landed_cad" || 
                        value && column.id === "ly_percent_from_landed_cad" ||
                        value && column.id === "difference_cad_percent" || 
                        value && column.id === "difference_usd_percent" ||
                        value && column.id === "ty_ly_cad_diff_percent" ||  
                        value && column.id === "ty_ly_usd_diff_percent" )
                    {
                      percent = "%";

                      //if(flag === "ca" && column.id === "DiffP")
                      //{
                        if(parseInt(row[column.id]) >= 0)
                        {
                          sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                        }
                        else 
                        {
                          sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                        }
                      //}
                      //else if (flag === "ca" && column.id === "LyP")
                      //{
                        if(parseInt(row[column.id]) >= 0)
                        {
                          sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                        }
                        else 
                        {
                          sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                        }
                      //}
                     // else if (flag === "usa" && column.id === "DiffPU")
                      //{
                        if(parseInt(row[column.id]) >= 0)
                        {
                          sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                        }
                        else 
                        {
                          sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                        }
                      //}
                     // else if (flag === "usa" && column.id === "LyPU")
                      //{
                        if(parseInt(row[column.id]) >= 0)
                        {
                          sign = <i style={{color: 'green', marginRight: '12px'}} className="fas fa-2x fa-caret-up" />;
                        }
                        else 
                        {
                          sign = <i style={{color: 'red', marginRight: '12px'}} className="fas fa-2x fa-caret-down" />;
                        }
                      //}
                      
                    }

                    if( value && column.id === "Diff" ||
                        value && column.id === "DiffU")
                    {
                      
                      if(parseInt(row[column.id]) >= 0) {
                        dollar = "";
                      }
                      else{
                        dollar = "-";
                      }

                      value = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                      
                    }

                    //let color = (column.id === "Diff" && flag === "ca" || column.id === "DiffU" && flag === "usa") ? dollar === "-" ?  "red" : "green" : "black";

                    let color = (column.id === "Diff"  || column.id === "DiffU") ? dollar === "-" ?  "red" : "green" : "black";
                    
                    return (
                      <TableCell style={{color}} key={column.id} align={column.align}>
                        {sign}{column.format && typeof value === 'number' ? value : value}{percent}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
  
  
}


export default connect(mapStateToProps, null)(StickyHeadTable)