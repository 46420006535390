import { IS_AUTH,
         SET_ECOMM_CA_BESTSELLERS,
         SET_ECOMM_USA_BESTSELLERS,
         SET_RETAIL_CA_BESTSELLER,
         SET_RETAIL_USA_BESTSELLER,
         SET_ECOMM_CA_SALESREPORT,
         SET_ECOMM_USA_SALESREPORT,
         SET_RETAIL_CA_SALESREPORT,
         SET_RETAIL_USA_SALESREPORT,
         SET_USER,
         SET_TOKEN,
         CUSTOM_SEARCH,
         CUSTOM_SEARCH_RESPONSE,
         CUSTOM_SEARCH_MESSAGE,
         CUSTOM_TABLE,
         USER_INBOX,
         SET_ECOMM_CA_HOMEBEAUTY,
         SET_ECOMM_USA_HOMEBEAUTY,
         SET_RETAIL_CA_HOMEBEAUTY,
         SET_RETAIL_USA_HOMEBEAUTY,
         SET_CAROUSEL_INDEX,
         EMAIL_SENT,
         LOGIN_PROMPT,
         OPEN_TIMER,
         SEARCH_TAGS, 
         TIMERS,
         CUST_BEST_SELLER_COUNT,
         CUST_HOME_BEAUTY_COUNT,
         SET_ROW_BG_COLORS,
         SET_EMAIL_MESSAGE,
         SET_CUSTOM_BUILD_ECOMM,
         SET_CUSTOM_BUILD_RETAIL,
         SCHED_COLOR_MAP_RETAIL,
         SCHED_COLOR_MAP_ECOMM } from "../constants/action-types";




const initialState = {
  is_auth: false,
  ecomm_ca_bestsellers: "empty",
  ecomm_usa_bestsellers: "empty",
  retail_ca_bestsellers: "empty",
  retail_usa_bestsellers: "empty",
  ecomm_ca_homebeauty: "empty",
  ecomm_usa_homebeauty: "empty",
  retail_ca_homebeauty: "empty",
  retail_usa_homebeauty: "empty",
  ecomm_ca_salesreports: {},
  ecomm_usa_salesreports: {},
  retail_ca_salesreports: {},
  retail_usa_salesreports: {},
  custom_table: [],
  search_tags: [],
  user: '',
  token: '',
  custom_search: false,
  custom_search_response: false,
  custom_search_message: '',
  user_inbox: {},
  carousel_index: {},
  email_sent: false,
  email_sent_message: '',
  login_prompt: '',
  open_timer: false,
  timers: {},
  cust_best_seller_count: 10,
  cust_home_beauty_count: 10,
  set_row_bg_colors: {},
  custom_build_ecomm: {},
  custom_build_retail: {},
  sched_color_map_retail: {},
  sched_color_map_ecomm: {}
};






const main = (state = initialState, action) => {

  switch (action.type) {

    case IS_AUTH:
        return { ...state, is_auth: action.payload };
    break;

    case SET_ECOMM_CA_BESTSELLERS:
        return { ...state, ecomm_ca_bestsellers: action.payload };
    break;

    case SET_ECOMM_USA_BESTSELLERS:
        return { ...state, ecomm_usa_bestsellers: action.payload };
    break;

    case SET_RETAIL_CA_BESTSELLER:
        return { ...state, retail_ca_bestsellers: action.payload };
    break;

    case SET_RETAIL_USA_BESTSELLER:
        return { ...state, retail_usa_bestsellers: action.payload };
    break;

    case SET_ECOMM_CA_HOMEBEAUTY:
        return { ...state, ecomm_ca_homebeauty: action.payload };
    break;

    case SET_ECOMM_USA_HOMEBEAUTY:
        return { ...state, ecomm_usa_homebeauty: action.payload };
    break;

    case SET_RETAIL_CA_HOMEBEAUTY:
        return { ...state, retail_ca_homebeauty: action.payload };
    break;

    case SET_RETAIL_USA_HOMEBEAUTY:
        return { ...state, retail_usa_homebeauty: action.payload };
    break;

    case SET_ECOMM_CA_SALESREPORT:
        return { ...state, ecomm_ca_salesreports: action.payload };
    break;

    case SET_ECOMM_USA_SALESREPORT:
        return { ...state, ecomm_usa_salesreports: action.payload };
    break;

    case SET_RETAIL_CA_SALESREPORT:
        return { ...state, retail_ca_salesreports: action.payload };
    break;

    case SET_RETAIL_USA_SALESREPORT:
        return { ...state, retail_usa_salesreports: action.payload };
    break;

    case SET_USER:
        return { ...state, user: action.payload };
    break;

    case SET_TOKEN:
        return { ...state, token: action.payload };
    break;

    case CUSTOM_SEARCH:
        return { ...state, custom_search: action.payload };
    break;

    case CUSTOM_SEARCH_RESPONSE:
        return { ...state, custom_search_response: action.payload };
    break;

    case CUSTOM_SEARCH_MESSAGE:
        return { ...state, custom_search_message: action.payload };
    break;

    case CUSTOM_TABLE:
        return { ...state, custom_table: action.payload };
    break;

    case USER_INBOX:
        return { ...state, user_inbox: action.payload };
    break;

    case SET_CAROUSEL_INDEX:
        return { ...state, carousel_index: action.payload };
    break;

    case EMAIL_SENT:
        return { ...state, email_sent: action.payload };
    break;

    case LOGIN_PROMPT:
        return { ...state, login_prompt: action.payload };
    break;

    case OPEN_TIMER:
        return { ...state, open_timer: action.payload };
    break;

    case SEARCH_TAGS:
        return { ...state, search_tags: action.payload };
    break;

    case TIMERS:
        return { ...state, timers: action.payload };
    break;

    case CUST_BEST_SELLER_COUNT:
        return { ...state, cust_best_seller_count: action.payload };
    break;

    case CUST_HOME_BEAUTY_COUNT:
        return { ...state, cust_home_beauty_count: action.payload };
    break;

    case SET_ROW_BG_COLORS:
        return { ...state, set_row_bg_colors: action.payload };
    break;

    case SET_EMAIL_MESSAGE:
        return { ...state, email_sent_message: action.payload };
    break;

    case SET_CUSTOM_BUILD_ECOMM:
        return { ...state, custom_build_ecomm: action.payload };
    break;

    case SET_CUSTOM_BUILD_RETAIL:
        return { ...state, custom_build_retail: action.payload };
    break;

    case SCHED_COLOR_MAP_RETAIL:
        return { ...state, sched_color_map_retail: action.payload };
    break;

    case SCHED_COLOR_MAP_ECOMM:
        return { ...state, sched_color_map_ecomm: action.payload };
    break;

    default:
      return state;
    break;
  }
};
//------


export default main
