import React, { useState } from 'react';
import Styled from 'styled-components'; 
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import { setCustomSearch } from '../../redux/actions/index';
import { Collapse,
         Alert,
         Navbar,
         NavbarToggler,
         NavbarBrand,
         Nav,
         NavItem,
         NavLink,
         UncontrolledDropdown,
         DropdownToggle,
         DropdownMenu,
         DropdownItem } from 'reactstrap';
import MainLogo from '../../assets/Oak+Fort-logo-thin-white.svg';
import Controller from '../../controller';


const controller = new Controller();


const Container = Styled.div`
    //border: 1px solid red;
    width: 100%;
    position: fixed;
    top: 0;
    height: auto;
    z-index: 1000;
    ${props => props.customSearch ? 'box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);' : ''}
    
`;


const mapStateToProps = state => {
  return {
    isAuth: state.main.is_auth,
    customSearch: state.main.custom_search,
    loginPrompt: state.main.login_prompt,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    setCustomSearch: param => dispatch(setCustomSearch(param))
  };
};
  


const Navigation = ({ isAuth, customSearch, setCustomSearch, loginPrompt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);



  return (
    <Container customSearch={customSearch}>
      {
        loginPrompt ?
        <Alert style={{position: 'fixed', top: '60px', width: '100%', textAlign: 'center'}} color={loginPrompt === "Logging you in..." ? "info" : loginPrompt === "Success!" ? "success" : "danger" }>
          { loginPrompt }
        </Alert> : ''
      }
      <Navbar style={{minHeight: '60px'}} color="dark" dark expand="md">
        {  isAuth ?
          <img id="nav-logo" src={MainLogo} onClick={() => window.location.replace("/")} style={{width: '200px', height: '35px', cursor: 'pointer'}} /> :
          ''
        }
        {
        isAuth ?
        <React.Fragment>
        <NavbarToggler onClick={toggle} />
        
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/ecommerce">E-Commerce</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/retail">Retail</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                More
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => setCustomSearch(true)}>
                  Custom Search
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={(event) => controller.onClick({event, btn: "logout"})}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse> 
        </React.Fragment> :
        ''
        }
        
      </Navbar>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);