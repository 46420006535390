import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(52,58,64,1)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);



const colCa = [
  { id: 'location', label: 'Location', minWidth: 120 },
  { id: 'orders', label: 'Orders', minWidth: 120 },
  { id: 'amount', label: 'Amount', minWidth: 120 },
];


function createData(...parameters) {
  
  let response = { location: parameters[0], 
                  orders: parameters[1], 
                  amount: parameters[2] };
  
  return response;
}


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    eight: 'auto',
    overflow: 'auto',
  },
});

export default function StickyHeadTable({ storePickupOrders, source }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(102);
  let rows, columns; 
  
  //console.log('storePickupOrders: ', storePickupOrders)

  columns = colCa;
  rows = storePickupOrders.map((report, i) => createData(report[0], 
                                                        report[1],  
                                                      `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(report[2])}`));

  rows.push(createData('','','','','','','',''));

  rows.push(createData( 'TOTAL',
                        storePickupOrders.map(r => parseInt(r['1'])).reduce((acc,sv) => acc+sv), 
                        `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(storePickupOrders.map(r => parseInt(r['2'])).reduce((acc,sv) => acc+sv))}`,
                        ));

     

  
    
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={i}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}